import _ from 'lodash'

const initialState = {
  confirmConfig: {},
  callback: null,
  callbackParams: {}
}

// Vars
let newState = null

const confirmReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'confirm_handle_open':
      newState = {
        ..._.cloneDeep(state),
        confirmConfig: {
          ...action.config,
          open: true,
        },
        callback: action.callback,
        callbackParams: action.callbackParams
      }

      return newState
    case 'confirm_handle_close':
      newState = {
        ...initialState,
      }

      return newState
    case 'confirm_handle_confirm':
      newState = {
        ...initialState,
      }

      setTimeout(() => {
        state.callback(...state.callbackParams)
      }, 10)

      return newState
    default:
      return state
  }
}

export default confirmReducer
