import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client';

import './Config'

import App from './containers/App'

import store from './stores'

import {} from './actions'

import './index.css'

import 'material-design-icons/iconfont/material-icons.css'
import 'typeface-roboto/index.css'

const target = document.querySelector('#root')

const NewProvider = ({ store }) => {
  return (
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  )
}

NewProvider.propTypes = {
  store: PropTypes.object.isRequired
}

const root = createRoot(target)

root.render(<NewProvider store={store} />)
