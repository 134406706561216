import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { blockActions } from '../../actions'

import bg from '../../assets/img/visiotest.jpg'
import { clsx } from '../../utils'

const styles = theme => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    height: 1000,
    marginTop: -50,
    overflow: 'hidden'
  },
  imgBg: {
    maxWidth: 1080,
    width: 1080
  },
  checkboxContainer: {
    position: 'absolute',
    width: 1080,
    height: 910,
    top: 0,
    left: '50%',
    marginLeft: -540
  },
  checkbox: {
    position: 'absolute',
    width: 15,
    display: 'inline-block',
    height: 15
  },
  'checkbox-0': {
    left: 110,
    top: 191
  },
  'checkbox-1': {
    left: 110,
    top: 222
  },
  groupV: {
    position: 'absolute',
    width: 30,
    height: 210,
    left: 1030
  },
  'group-v-0': {
    top: 50,
  },
  'group-v-1': {
    top: 421,
  },
  'group-v-2': {
    top: 663,
  },
  'checkbox-in-group-v-0': {
    top: 0,
    left: 0,
  },
  'checkbox-in-group-v-1': {
    top: 51,
    left: 0,
  },
  'checkbox-in-group-v-2': {
    top: 78,
    left: 0,
  },
  'checkbox-in-group-v-3': {
    top: 104,
    left: 0,
  },
  'checkbox-in-group-v-4': {
    top: 131,
    left: 0,
  },
  'checkbox-in-group-v-5': {
    top: 158,
    left: 0,
  },
  'checkbox-in-group-v-6': {
    top: 185,
    left: 0,
  },
  groupH: {
    position: 'absolute',
    top: 310,
    width: 760,
    left: 200,
    height: 30
  },
  'checkbox-in-group-h-0': {
    left: 0,
  },
  'checkbox-in-group-h-1': {
    left: 20,
  },
  'checkbox-in-group-h-2': {
    left: 55,
  },
  'checkbox-in-group-h-3': {
    left: 75,
  },
  'checkbox-in-group-h-4': {
    left: 110,
  },
  'checkbox-in-group-h-5': {
    left: 130,
  },
  'checkbox-in-group-h-6': {
    left: 165,
  },
  'checkbox-in-group-h-7': {
    left: 185,
  },
  'checkbox-in-group-h-8': {
    left: 219,
  },
  'checkbox-in-group-h-9': {
    left: 239,
  },
  'checkbox-in-group-h-10': {
    left: 274,
  },
  'checkbox-in-group-h-11': {
    left: 294,
  },
  'checkbox-in-group-h-12': {
    left: 380,
  },
  'checkbox-in-group-h-13': {
    left: 452,
  },
  'checkbox-in-group-h-14': {
    left: 605,
  },
  'checkbox-in-group-h-15': {
    left: 674,
  },
  groupH2: {
    position: 'absolute',
    top: 350,
    width: 760,
    left: 200,
    height: 30
  },
  'checkbox-in-group-h2-0': {
    left: 0,
  },
  'checkbox-in-group-h2-1': {
    left: 20,
  },
  'checkbox-in-group-h2-2': {
    left: 55,
  },
  'checkbox-in-group-h2-3': {
    left: 75,
  },
  'checkbox-in-group-h2-4': {
    left: 110,
  },
  'checkbox-in-group-h2-5': {
    left: 130,
  },
  'checkbox-in-group-h2-6': {
    left: 165,
  },
  'checkbox-in-group-h2-7': {
    left: 185,
  },
  'checkbox-in-group-h2-8': {
    left: 219,
  },
  'checkbox-in-group-h2-9': {
    left: 239,
  },
  'checkbox-in-group-h2-10': {
    left: 274,
  },
  'checkbox-in-group-h2-11': {
    left: 294,
  },
  groupH3: {
    position: 'absolute',
    top: 395,
    width: 760,
    left: 200,
    height: 30
  },
  'checkbox-in-group-h3-0': {
    left: 0,
  },
  'checkbox-in-group-h3-1': {
    left: 20,
  },
  'checkbox-in-group-h3-2': {
    left: 55,
  },
  'checkbox-in-group-h3-3': {
    left: 75,
  },
  groupH4: {
    position: 'absolute',
    top: 928,
    width: 653,
    left: 283,
    height: 30
  },
  'checkbox-in-group-h4-0': {
    left: 0,
  },
  'checkbox-in-group-h4-1': {
    left: 21,
  },
  'checkbox-in-group-h4-2': {
    left: 43,
  },
  'checkbox-in-group-h4-3': {
    left: 64,
  },
  'checkbox-in-group-h4-4': {
    left: 87,
  },
  'checkbox-in-group-h4-5': {
    left: 108,
  },
  'checkbox-in-group-h4-6': {
    left: 131,
  },
  'checkbox-in-group-h4-7': {
    left: 152,
  },
  'checkbox-in-group-h4-8': {
    left: 174,
  },
  'checkbox-in-group-h4-9': {
    left: 196,
  },
  'checkbox-in-group-h4-10': {
    left: 217,
  },
  'checkbox-in-group-h4-11': {
    left: 238,
  },
  'checkbox-in-group-h4-12': {
    left: 314
  },
  'checkbox-in-group-h4-13': {
    left: 337
  },
  'checkbox-in-group-h4-14': {
    left: 360
  },
  'checkbox-in-group-h4-15': {
    left: 383
  },
  'checkbox-in-group-h4-16': {
    left: 406
  },
  'checkbox-in-group-h4-17': {
    left: 438
  },
  'checkbox-in-group-h4-18': {
    left: 508
  },
  'checkbox-in-group-h4-19': {
    left: 540
  },
  'checkbox-in-group-h4-20': {
    left: 563
  },
  'checkbox-in-group-h4-21': {
    left: 586
  },
  'checkbox-in-group-h4-22': {
    left: 609
  },
  'checkbox-in-group-h4-23': {
    left: 632
  },
  group: {
    position: 'absolute',
    width: 390,
    height: 210
  },
  'group-0': {
    top: 50,
    left: 170
  },
  'group-1': {
    top: 50,
    left: 570
  },
  'group-2': {
    top: 422,
    left: 170
  },
  'group-3': {
    top: 422,
    left: 570
  },
  'group-4': {
    top: 665,
    left: 170
  },
  'group-5': {
    top: 665,
    left: 570
  },
  'checkbox-in-group-0': {
    left: 77,
    top: 35
  },
  'checkbox-in-group-1': {
    left: 142,
    top: 35
  },
  'checkbox-in-group-2': {
    left: 164,
    top: 35
  },
  'checkbox-in-group-3': {
    left: 186,
    top: 35
  },
  'checkbox-in-group-4': {
    left: 208,
    top: 35
  },
  'checkbox-in-group-5': {
    left: 230,
    top: 35
  },
  'checkbox-in-group-6': {
    left: 252,
    top: 35
  },
  'checkbox-in-group-7': {
    left: 322,
    top: 35
  },
  'checkbox-in-group-8': {
    left: 115,
    top: 93
  },
  'checkbox-in-group-9': {
    left: 202,
    top: 93
  },
  'checkbox-in-group-10': {
    left: 288,
    top: 93
  },
  'checkbox-in-group-11': {
    left: 52,
    top: 180
  },
  'checkbox-in-group-12': {
    left: 56,
    top: 152
  },
  'checkbox-in-group-13': {
    left: 166,
    top: 131
  },
  'checkbox-in-group-14': {
    left: 121,
    top: 152
  },
  'checkbox-in-group-15': {
    left: 76,
    top: 131
  },
  'checkbox-in-group-16': {
    left: 184,
    top: 152
  },
  'checkbox-in-group-17': {
    left: 190,
    top: 180
  },
  'checkbox-in-group-18': {
    left: 280,
    top: 170
  },
})

class VisioTest extends React.Component {
  state = {
    value: {}
  }

  componentDidMount = () => {
    const {
      block,
      blockIndex
    } = this.props

    const {
      value,
      name,
      fieldPath
    } = block

    this.props.onAddToPathMapping(name, [
      ...(fieldPath || []),
      blockIndex
    ])

    this.setState({
      value
    })
  }

  componentDidUpdate = prevProps => {
    const {
      lastUpdate,
      onChange,
      blockIndex,
      block
    } = this.props

    const {
      lastUpdate: prevLastUpdate
    } = prevProps

    if (prevLastUpdate !== lastUpdate) {
      const {
        value
      } = block

      this.setState({
        value
      })

      return onChange(value, [blockIndex])
    }
  }

  onChange = (event, index) => {
    const { onChange, blockIndex } = this.props

    const { value = {} } = this.state

    value[index] = event.target.checked

    this.setState({
      value
    })

    return onChange(value, [blockIndex])
  }

  getInputField = (inputConfig) => {
    const {
      disabled,
      className,
      index,
      key
    } = inputConfig

    const {
      value = {}
    } = this.state

    return (
      <input
        type='checkbox'
        disabled={disabled}
        className={clsx(className)}
        name={'checkbox-' + index}
        onChange={(event) => this.onChange(event, index)}
        checked={value[index] || false}
        key={key} />
    )
  }

  render() {
    const {
      classes,
      readonly
    } = this.props

    const hGroups = []

    for (let i = 0; i < 1; i++) {
      const tmpHgroup = []

      for (let u = 0; u < 16; u++) {
        tmpHgroup.push(u)
      }

      hGroups.push(tmpHgroup)
    }

    const h2Groups = []

    for (let i = 0; i < 1; i++) {
      const tmpHgroup = []

      for (let u = 0; u < 12; u++) {
        tmpHgroup.push(u)
      }

      h2Groups.push(tmpHgroup)
    }

    const h3Groups = []

    for (let i = 0; i < 1; i++) {
      const tmpHgroup = []

      for (let u = 0; u < 4; u++) {
        tmpHgroup.push(u)
      }

      h3Groups.push(tmpHgroup)
    }

    const h4Groups = []

    for (let i = 0; i < 1; i++) {
      const tmpHgroup = []

      for (let u = 0; u < 24; u++) {
        tmpHgroup.push(u)
      }

      h4Groups.push(tmpHgroup)
    }

    const vGroups = []

    for (let i = 0; i < 3; i++) {
      const tmpVgroup = []

      for (let u = 0; u < 7; u++) {
        tmpVgroup.push(u)
      }

      vGroups.push(tmpVgroup)
    }

    const groups = []

    for (let i = 0; i < 6; i++) {
      const tmpGroup = []

      for (let u = 0; u < 19; u++) {
        tmpGroup.push(u)
      }

      groups.push(tmpGroup)
    }

    let counter = 1

    return (
      <div className={classes.root}>
        <img className={classes.imgBg} alt='Visio test' src={bg} />
        <div className={classes.checkboxContainer}>
          {this.getInputField({
            disabled: readonly,
            className: [
              classes.checkbox,
              classes['checkbox-0']
            ],
            index: 0
          })}
          {this.getInputField({
            disabled: readonly,
            className: [
              classes.checkbox,
              classes['checkbox-1']
            ],
            index: 1
          })}
          {groups.map((group, groupIndex) => {
            return (
              <div className={classes.group + ' ' + classes['group-' + groupIndex]} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
          {vGroups.map((group, groupIndex) => {
            return (
              <div className={classes.groupV + ' ' + classes['group-v-' + groupIndex]} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-v-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
          {hGroups.map((group, groupIndex) => {
            return (
              <div className={classes.groupH} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-h-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
          {h4Groups.map((group, groupIndex) => {
            return (
              <div className={classes.groupH4} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-h4-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
          {h2Groups.map((group, groupIndex) => {
            return (
              <div className={classes.groupH2} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-h2-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
          {h3Groups.map((group, groupIndex) => {
            return (
              <div className={classes.groupH3} key={groupIndex}>
                {group.map(index => {
                  counter++

                  const tmpCounter = counter

                  return this.getInputField({
                    disabled: readonly,
                    className: [
                      classes.checkbox,
                      classes['checkbox-in-group-h3-' + index]
                    ],
                    index: tmpCounter,
                    key: groupIndex + '-' + index
                  })
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

VisioTest.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.blockReducer })

const mapDispatchToProps = dispatch => ({
  onAddToPathMapping: (fieldName, fieldPath) => {
    dispatch(blockActions.addToPathMapping(fieldName, fieldPath))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VisioTest)))