export const removeDuplicateCharacters = (str) => {
  return str.split('').filter((item, pos, self) => {
      return self.indexOf(item) === pos;
  }).join('')
}

export const countCharsFrom = (array, char, from, to) => {
  return array.filter((item, index) => {
    return index >= from && index <= to && item === char
  }).length
}

export const eqToOneOf = (str, ...values) => {
  return values.indexOf(str) > -1
}
