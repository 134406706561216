import store from '../stores'
import _ from 'lodash'

import {
  listPageAjaxActions
} from '../actions'

const initialState = {
  listPageAjaxConfig: null,
  listPageAjaxsAjax: null,
  listPagination: {},
  requestPagination: {},
  listPageAjaxReload: false,
  lastPageType: null,
  listPageParams: {}
}

// Vars
let newState = null

const listPageAjaxReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'auth_action_logout':
      return _.cloneDeep(initialState)
    case 'list_page_ajax_action_load_page':
      newState = _.cloneDeep(state)

      setTimeout(() => {
        return store.dispatch(listPageAjaxActions.loadPageCallback(action.callback))
      }, 200)

      return newState
    case 'list_page_ajax_action_list_page_page_get':
      newState = action.pageType !== state.lastPageType ? {
        ...initialState,
        lastPageType: action.pageType,
        listPageParams: {},
        listPageAjaxConfig: null
      } : {
        ..._.cloneDeep(state),
        listPageAjaxConfig: null
      }

      return newState
    case 'list_page_ajax_action_list_page_page':
      newState = {
        ..._.cloneDeep(state),
        listPageAjaxConfig: action.data.data
      }

      return newState
    case 'list_page_ajax_action_list_page_pages_get':
      newState = {
        ...(action.update ? state : initialState),
        lastPageType: state.lastPageType,
        listPageParams: action.params
      }

      return newState
    case 'list_page_ajax_action_list_page':
      newState = {
        ..._.cloneDeep(state),
        exitForm: false,
        formContent: action.data ? action.data.data : null,
        lastPageType: action.pageType || {}
      }

      if (newState.formContent) {
        newState.formContent.fields.forEach((field) => {
          if (field.values) {
            field.values.forEach((fieldValue => {
              return fieldValue.id = _.uniqueId('field_id')
            }))
          }
        })
      }

      return newState
    default:
      return state
  }
}

export default listPageAjaxReducer
