const initialState = {
  updating: false,
  from409: true,
  updatePercentages: {
    up: 0,
    down: 0
  }
}

// Vars
// let newState = null

const updateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'update_action_update_percentages':
      return {
        ...state,
        from409: false
      }
    case 'update_action_set_percentages':
      return {
        ...state,
        updatePercentages: action.percentages
      }
    case 'update_action_update':
      return {
        ...state,
        updating: true,
        from409: true
      }
    case 'update_action_end_update':
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

export default updateReducer
