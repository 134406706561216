import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Route, Switch, withRouter } from 'react-router-dom'

// Routers
import Login from '../containers/Login'
import Recovery from '../containers/Recovery'

const styles = theme => ({})

class AuthRoutes extends React.Component {
  render() {
    const { appConfig } = this.props

    return (
      <Switch>
        <Route path={appConfig.appPrefix + '/auth/recovery'} component={Recovery} />
        <Route component={Login} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AuthRoutes)))
