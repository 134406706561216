import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AsyncSelectField from './AsyncSelect'
import AsyncCreatableSelectField from './AsyncCreatableSelect'
import CreatableSelectField from './CreatableSelect'
import Select from './Select'

import { } from '../../../actions'

const styles = theme => ({})

class SelectField extends React.Component {
  render() {
    const { field } = this.props

    const selects = {
      async: AsyncSelectField,
      asyncCreatable: AsyncCreatableSelectField,
      creatable: CreatableSelectField,
      select: Select
    }

    const isAsync = typeof field.options === 'string'

    const isCreatable = field.creatable

    const tmpSelect = isAsync ? (isCreatable ? 'asyncCreatable' : 'async') : isCreatable ? 'creatable' : 'select'

    if (!selects[tmpSelect]) {
      return null
    }

    const TmpSelect = selects[tmpSelect]

    return <TmpSelect {...this.props} />
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectField)))
