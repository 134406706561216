import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import MUITableRow from '@material-ui/core/TableRow'
import TableRow from './TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableActions from './TableActions'
import _ from 'lodash'
import TableFilter from './TableFilter'
import { getPaginationConfig } from '../../utils/pagination'

import {
  appActions,
  listPageAjaxActions
} from '../../actions'

const styles = theme => ({
  title: {
    padding: '0 1rem'
  },
  tableCellTitle: {
    fontSize: '1rem',
    maxWidth: 200,
    width: 200,
    whiteSpace: 'nowrap'
  },
  tableCellAuto: {
    maxWidth: 'none',
    width: 'auto'
  },
  tableCell: {
    fontWeight: 700
  },
  tableCellCheckbox: {
    maxWidth: 70,
    width: 70
  },
  tableHead: {
    backgroundColor: theme.palette.background.title
  },
  pagination: {
    paddingRight: '0 !important',
    minWidth: 400,
  },
  paginationToolbar: {
    paddingLeft: 0
  },
  paginationSelect: {
    minWidth: 30
  }
})

class TableAjaxComponent extends React.Component {
  state = {
    selectedElements: {},
    selectAllPageElements: false,
    changeTimeout: null
  }

  onChange = (type, params) => {
    const {
      onChange,
      paginationConfig: propsPaginationConfig
    } = this.props

    const paginationConfig = getPaginationConfig(propsPaginationConfig, true)

    const { changeTimeout } = this.state

    if (changeTimeout) {
      clearTimeout(changeTimeout)
    }

    this.setState({
      changeTimeout: setTimeout(() => {
        onChange(type, {
          ...paginationConfig,
          ...params
        })
      }, 300)
    })
  }

  handleChangePage = (event, page) => {
    return this.onChange('page', {
      page: page + 1
    })
  }

  handleChangeRowsPerPage = event => {
    return this.onChange('rowsPerPage', {
      pageSize: event.target.value
    })
  }

  createSortHandler = sort => {
    return this.onChange('sort', {
      sort
    })
  }

  onFilterChange = (element, value = {}) => {
    return this.onChange('filter', {
      filter: {
        field: element.field,
        value: _.isObject(value) ? value.value : value
      }
    })
  }

  selectAllPageElements = () => {
    const { tableBody, onSelectElement } = this.props

    const { selectAllPageElements } = this.state

    if (selectAllPageElements) {
      return this.setState({
        selectAllPageElements: false,
        selectedElements: {}
      }, () => {
        onSelectElement(this.state.selectedElements)
      })
    }

    const selectedElements = {}

    tableBody.forEach(element => {
      selectedElements[element.id] = true
    })

    this.props.onStartLoading()

    this.setState({
      selectAllPageElements: true,
      selectedElements
    }, () => {
      this.props.onStopLoading()

      onSelectElement(this.state.selectedElements, true)
    })
  }

  selectElement = (event, element) => {
    const { onSelectElement } = this.props

    const { checked } = event.target

    const { selectedElements } = this.state

    if (checked) {
      if (!selectedElements[element.id]) {
        selectedElements[element.id] = true
      }
    } else {
      if (selectedElements[element.id]) {
        selectedElements[element.id] = false
      }
    }

    return this.setState({
      selectedElements,
      selectAllPageElements: false
    }, () => {
      onSelectElement(this.state.selectedElements)
    })
  }

  render() {
    const {
      classes,
      tableHead = [],
      tableHeadActions,
      labels,
      tableBody,
      tableActions,
      onClickAction,
      activeBulk = true,
      requestPagination = {},
      paginationConfig: propsPaginationConfig,
      sort = {},
      filters = {}
    } = this.props

    const paginationConfig = getPaginationConfig(propsPaginationConfig)

    const { selectAllPageElements, selectedElements } = this.state

    let footerSize = tableHead.length || {}

    if (tableActions && tableActions.length) {
      footerSize++
    }

    if (activeBulk) {
      footerSize++
    }

    const paginationLoadingSize = footerSize > 2 ? footerSize - 2 : 1

    const paginationSize = footerSize > 2 ? 2 : 1

    const completed = (100 / requestPagination.pageCount) * requestPagination.page

    const haveFilters = (tableHead || []).filter(element => {
      return !!element.filter
    }).length > 0

    return (
      <Table className={classes.table} aria-labelledby='tableTitle'>
        <TableHead>
          <MUITableRow className={classes.tableHead}>
            {activeBulk ? (
              <TableCell className={classes.tableCellTitle + ' ' + classes.tableCellCheckbox} padding='checkbox'>
              </TableCell>
            ) : null}
            {tableHead.map((element, index) => {
              const activedSort = sort.field === element.field

              const sortDirection = sort.direction === 'asc' ? 'desc' : 'asc'

              const onSort = () => {
                if (element.notSortable) {
                  return
                }

                this.createSortHandler(element.field)
              }

              return (
                <TableCell sortDirection={index === 0 ? 'asc' : false} key={index} className={classes.tableCellTitle + (!element.filter ? ' ' + classes.tableCellAuto : '')} padding='checkbox'>
                  <TableSortLabel
                    active={activedSort}
                    direction={sortDirection}
                    onClick={onSort} >
                    {element.label}
                  </TableSortLabel>
                </TableCell>
              )
            })}
            {(tableActions && tableActions.length) || (tableHeadActions && tableHeadActions.length) ? (
              <TableCell className={classes.tableCellTitle} padding='checkbox'>
                {(tableHeadActions && tableHeadActions.length) ? (
                  <TableActions actions={tableHeadActions} item={{}} itemId={null} onClickAction={onClickAction} />
                ) : null}
              </TableCell>
            ) : null}
          </MUITableRow>
          {haveFilters ? (
            <MUITableRow>
              {activeBulk ? (
                <TableCell className={classes.tableCellTitle + ' ' + classes.tableCellCheckbox} padding='checkbox'>
                  <Checkbox checked={selectAllPageElements} onChange={this.selectAllPageElements} />
                </TableCell>
              ) : null}
              {tableHead.map((element, index) => {
                if (!element.filter) {
                  return (
                    <TableCell key={index} padding='checkbox'>
                    </TableCell>
                  )
                }

                const filterValue = filters[element.field] || null

                return (
                  <TableCell key={index} padding='checkbox'>
                    <TableFilter filterValue={filterValue} element={element} onChange={this.onFilterChange} />
                  </TableCell>
                )
              })}
              {tableActions && tableActions.length ? (
                <TableCell className={classes.tableCellTitle} padding='checkbox'>
                </TableCell>
              ) : null}
            </MUITableRow>
          ) : null}
        </TableHead>
        <TableBody>
          {tableBody.length === 0 ? (
            <MUITableRow hover role='checkbox' tabIndex={-1} selected={false} aria-checked='false'>
              <TableCell colSpan={tableHead.length + (activeBulk ? 2 : 1)} className={classes.tableCellCheckbox} padding='checkbox'>
                {(labels || {}).noResults}
              </TableCell>
            </MUITableRow>
          ) : tableBody.map(element => {
            const isChecked = selectedElements[element.id] || false

            return (
              <TableRow key={element.id} {...{
                element,
                onChange: (event) => {
                  return this.selectElement(event, element)
                },
                activeBulk,
                isChecked,
                rowClasses: classes,
                tableHead,
                tableActions,
                onClickAction
              }} />
            )
          })}
        </TableBody>
        <TableFooter>
          <MUITableRow>
            <TableCell colSpan={paginationLoadingSize}>
              {completed === 100 || !requestPagination.page ? null : (
                <LinearProgress variant='determinate' value={completed} />
              )}
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 150]}
              count={paginationConfig.rowCount || 0}
              rowsPerPage={parseInt(paginationConfig.pageSize) || 0}
              page={paginationConfig.page - 1}
              colSpan={paginationSize}
              classes={{
                root: classes.pagination,
                toolbar: classes.paginationToolbar,
                select: classes.paginationSelect
              }}
              labelRowsPerPage='Elementi per pagina'
              SelectProps={{
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </MUITableRow>
        </TableFooter>
      </Table>
    )
  }
}

TableAjaxComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.appReducer,
  ...state.listPageAjaxReducer,
  ...state.paginationConfigReducer
})

const mapDispatchToProps = dispatch => ({
  onUpdatePagination: (paginationConfig, pageType) => {
    dispatch(listPageAjaxActions.updatePagination(paginationConfig, pageType))
  },
  onSetFilteredItems: (data) => {
    dispatch(appActions.setFilteredItems(data))
  },
  onStartLoading: () => {
    dispatch(appActions.startLoading())
  },
  onStopLoading: () => {
    dispatch(appActions.stopLoading())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableAjaxComponent)))
