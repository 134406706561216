import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ModalActions from './ModalActions'
import BaseAction from './BaseAction'
import TabActions from './TabActions'
import moment from 'moment'

import {
  messageActions,
  printActions,
  appActions
} from '../../actions'

const styles = theme => ({
  paperRoot: {
    margin: '1rem'
  },
  root: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
})

class PageActions extends React.Component {
  state = {
    openModal: false,
    periodFrom: moment().subtract(1, 'years').format(window.appConfig.dateFormat),
    periodTo: moment().format(window.appConfig.dateFormat)
  }

  handleClick = (action, parentAction, actionParams = {}) => {
    let onFiltered = action.onFiltered || false

    if (parentAction) {
      onFiltered = parentAction.onFiltered
    }

    if (!this.canHandle() && !action.alwaysOn && !onFiltered) {
      return this.props.onShowMessage(this.props.errorMessage)
    }

    const { selectedElements, filteredItems, inlineForm } = this.props

    const { periodFrom, periodTo, openModal } = this.state

    let params = {
      ...actionParams,
      id: filteredItems && onFiltered ? filteredItems.map(item => item.id) : Object.keys(selectedElements),
    }

    switch (action.type) {
      case 'print':
        if (openModal) {
          params.period = {
            from: periodFrom,
            to: periodTo
          }
        }

        Object.values(inlineForm).forEach(formParams => {
          params = {
            ...params,
            ...formParams
          }
        })

        if (onFiltered) {
          return this.props.onGetFilteredItems((callbackAction, callbackParams) => {
            const { selectedElements, filteredItems } = this.props

            return this.props.onPrintPost(callbackAction, {
              ...callbackParams,
              id: filteredItems ? filteredItems.map(item => item.id) : Object.keys(selectedElements)
            })
          }, [action.action, params])
        }

        return this.props.onPrintPost(action.action, params)
      default:
        return this.setState({
          openModal: true,
          currentModalAction: {
            ...action,
            onFiltered: onFiltered
          }
        })
    }
  }

  canHandle = () => {
    const { selectedElements } = this.props

    return Object.entries(selectedElements).filter(([key, value]) => {
      return value
    }).length > 0
  }

  render() {
    const {
      classes,
      actions,
      selectedElements,
      activeElements,
      onUpdateActiveElement,
      actionsAsTab,
      pageType,
      pageTypes,
      isTop,
      fromAjaxPage
    } = this.props

    const {
      openModal,
      currentModalAction,
      periodFrom,
      periodTo
    } = this.state

    const canHandle = this.canHandle()

    if (actionsAsTab) {
      return (
        <TabActions {...{
          actions
        }} />
      )
    }

    return (
      <Paper className={classes.paperRoot}>
        <div className={classes.root}>
          {currentModalAction ? (
            <ModalActions {...{
              currentModalAction,
              openModal,
              periodFrom,
              periodTo,
              fromAjaxPage,
              onClose: () => {
                this.setState({
                  openModal: false
                })
              },
              onClick: this.handleClick,
              parent: this
            }} />
          ) : ''}
          {actions.map((action, index) => {
            const enabled = action.alwaysOn || canHandle || activeElements[action.name]

            return (
              <BaseAction action={action} isTop={isTop} key={index} index={index} enabled={enabled} onClick={this.handleClick} {...{
                pageType,
                pageTypes,
                fromAjaxPage
              }} selectedElements={selectedElements} onUpdateActiveElement={onUpdateActiveElement} />
            )
          })}
        </div>
      </Paper>
    )
  }
}

PageActions.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.inlineFormReducer })

const mapDispatchToProps = dispatch => ({
  onShowMessage: (message) => {
    dispatch(messageActions.showMessage(message))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
  onGetFilteredItems: (callback, callbackParams) => {
    dispatch(appActions.getFilteredItems(callback, callbackParams))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PageActions)))
