import _ from 'lodash'

import {} from '../actions'

const initialState = {
  inlineForm: {}
}

// Vars
let newState

const inlineFormReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'inline_form_reset_params':
      return {
        ...state,
        inlineForm: {}
      }
    case 'inline_form_add_value_to_field':
      newState = _.cloneDeep(state)

      if (action.value === null) {
        _.unset(newState.inlineForm, [action.index, action.fieldName])
      } else {
        _.set(newState.inlineForm, [action.index, action.fieldName], (action.value || {}).value || action.value)
      }

      return newState
    default:
      return state
  }
}

export default inlineFormReducer
