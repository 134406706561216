import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '../Icon'

import {
} from '../../actions'

const styles = theme => ({})

class IconSet extends React.Component {
  render() {
    const { iconSetClasses, icon, far } = this.props

    if (icon) {
      return (
        <Icon far={far} className={iconSetClasses.icon}>{icon}</Icon>
      )
    }

    return ''
  }
}

IconSet.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(IconSet)))
