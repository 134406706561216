import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    zIndex: 99999
  },
  icon: {
    fontSize: 50,
    animation: 'rotate infinite 1s linear'
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(-359deg)'
    }
  }
})

class Loading extends React.Component {
  render() {
    const { classes, appConfig, forceLoading } = this.props

    if ((!appConfig || !appConfig.openLoading) && !forceLoading) {
      return ''
    }

    return (
      <div className={classes.root}>
        <Icon className={classes.icon + ' rotate-animation'}>loop</Icon>
      </div>
    )
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Loading)))
