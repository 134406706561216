import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'

import {} from '../../actions'

const styles = theme => ({
  button: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    transition: 'opacity .3s ease-in-out'
  },
  label: {
    marginLeft: '.5rem'
  },
  disabled: {
    opacity: '.5'
  },
})

class Default extends React.Component {
  render() {
    const { classes, onClick, action, enabled } = this.props

    const { style, icon } = action

    return (
      <Button variant='contained' disabled={!enabled} className={classes.button + (!enabled ? ' ' + classes.disabled : '')} style={style || {}} onClick={() => onClick(action)}>
        {icon ? (
          <Icon>{icon}</Icon>
        ) : null}
        <span className={classes.label}>{action.label}</span>
      </Button>
    )
  }
}

Default.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Default)))
