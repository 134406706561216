import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import {
  confirmActions
} from '../../actions'

const styles = theme => ({
  root: {
    minWidth: 350
  }
})

class Confirm extends React.Component {
  render() {
    const { confirmConfig, classes } = this.props

    if (!confirmConfig.open) {
      return ''
    }

    return (
      <Dialog open={confirmConfig.open} onClose={this.props.onHandleClose} aria-labelledby='confirm-dialog-title'>
        <DialogTitle id='confirm-dialog-title'>{confirmConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.root} dangerouslySetInnerHTML={{ __html: confirmConfig.message }}></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHandleClose} color='primary'>
            {confirmConfig.ko}
          </Button>
          <Button onClick={this.props.onHandleConfirm} color='primary' variant='contained' autoFocus>
            {confirmConfig.ok}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Confirm.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.confirmReducer })

const mapDispatchToProps = dispatch => ({
  onHandleClose: () => {
    dispatch(confirmActions.handleClose())
  },
  onHandleConfirm: () => {
    dispatch(confirmActions.handleConfirm())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Confirm)))
