import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { validators } from './Validators'
import { Calendar } from 'react-date-range'
import { it } from 'react-date-range/dist/locale'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { isValidDate } from '../../utils'
import moment from 'moment'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import './Date.css'

import {} from '../../actions'

const styles = theme => ({
  helperText: {
    position: 'absolute',
    top: '100%',
    marginTop: '2px',
  },
  inputContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    margin: 0,
  },
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  inputField: {
    padding: '.75rem'
  },
  inputHighlighted: {
    '& fieldset': {
      borderWidth: 3,
      borderColor: theme.palette.common.black + '!important',
    }
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 11px) !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  labelShrink: {
    transform: 'translate(14px, -8px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  calendarContainer: {
    display: 'none',
    position: 'absolute',
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    top: '100%',
    marginTop: -8,
  },
  'calendar-tl': {
    top: 'auto',
    bottom: '100%',
    left: 0,
  },
  'calendar-tr': {
    top: 'auto',
    bottom: '100%',
    right: 50,
  },
  'calendar-bl': {},
  'calendar-br': {
    right: 50,
  },
  calendarContainerIsOpen: {
    display: 'block'
  },
  input: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  inputReadOnly: {
    backgroundColor: theme.palette.grey[50],
    fontWeight: 600,
    color: theme.palette.grey['A400']
  },
  calendarButton: {
    padding: 8
  }
})

class DateField extends React.Component {
  _isMounted = false

  state = {
    open: false,
    currentDate: new Date(),
    calendarRef: null,
    stringValue: '',
    position: 'bl',
  }

  componentDidMount() {
    const { appConfig, field } = this.props

    this._isMounted = true

    const { value } = field

    const { dateFormat } = appConfig

    const state = {
      stringValue: value || ''
    }

    if (value) {
      state.currentDate = moment(value, dateFormat).toDate()
    }

    this.setState(state)

    document.addEventListener('click', this.handleClickOutside)

    return validators.mount(this)
  }

  componentWillUnmount() {
    this._isMounted = false

    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { calendarRef } = this.state

    if (!calendarRef.contains(event.target) && this._isMounted) {
      this.setState({
        open: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { value: prevValue } = prevProps.field

    prevValue = prevValue || ''

    const { field, onChange, appConfig } = this.props

    let { value = '' } = field

    const { stringValue } = this.state

    const { stringValue: preStringValue } = prevState

    const fromState = stringValue !== preStringValue

    const currentDate = moment(value, appConfig.dateFormat)

    const currentStringValue = currentDate.isValid() ? value : ''

    value = value || ''

    if (!fromState && (value !== prevValue || value !== stringValue)) {
      this.setState({
        stringValue: currentStringValue,
        currentDate: currentDate.isValid() ? currentDate.toDate() : null
      }, () => {
        onChange({type: 'string'}, field, currentStringValue)
      })
    }

    return validators.update(this, prevProps)
  }

  onChange = (tmpValue) => {
    const { field, onChange, appConfig } = this.props

    const { dateFormat } = appConfig

    const formatTestRegex = new RegExp('^$|^([0-3])$|(^(0)[1-9]$|^[1-2][0-9]$|^(3)[0-1]$)$|(^((0)[1-9]|[1-2][0-9]|(3)[0-1])(/)$)|(^(((0)[1-9]|[1-2][0-9]|(3)[0-1])(/))([0-1]|(0)[1-9]|(1)[0-2])$)|(^((((0)[1-9]|[1-2][0-9]|(3)[0-1])(/))([0-1]|(0)[1-9]|(1)[0-2]))((/){1}([0-9]{0,4}){0,1})$)', 'ig')

    const formatRegex = new RegExp('^([0-9]{2})((/)([0-9]{2})){0,1}$', 'ig')

    let value = tmpValue

    if (!formatTestRegex.test(value)) {
      value = ''
    }

    if (formatRegex.test(value)) {
      value = value.replace(formatRegex, '$1/$2/').replace(/\/\//, '/')
    }

    const state = {
      stringValue: value
    }

    const tmpDate = moment(value, dateFormat)

    if (value && tmpDate.isValid() && isValidDate(value)) {
      state.currentDate = tmpDate.toDate()

      state.open = false

      onChange({type: 'string', directChange: true}, field, value)
    } else if (!tmpValue) {
      onChange({type: 'string', directChange: true}, field, '')
    }

    return this.setState(state)
  }

  toggleCalendar = (show = false) => {
    const { readonly } = this.props

    const { calendarRef } = this.state

    const { innerWidth: windowWidth, innerHeight: windowHeight } = window

    const { top: refTop, left: refLeft } = calendarRef.getBoundingClientRect()

    const position = ['b', 'l']

    if (windowHeight - refTop < 350) {
      position[0] = 't'
    }

    if (windowWidth - refLeft < 400) {
      position[1] = 'r'
    }

    return this.setState({
      open: readonly ? false : (show || !this.state.open),
      position: position.join('')
    })
  }

  setRef = (node) => {
    const { calendarRef } = this.state

    if (!calendarRef) {
      this.setState({
        calendarRef: ReactDOM.findDOMNode(node)
      })
    }
  }

  render() {
    const { classes, field, appConfig, readonly } = this.props

    const { dateFormat } = appConfig

    const { open, currentDate, stringValue, position } = this.state

    if (field.range) {
      return ''
    }

    const minMaxProps = {}

    if (field.minDate) {
      minMaxProps.minDate = moment(field.minDate, dateFormat).toDate()
    }

    if (field.maxDate) {
      minMaxProps.maxDate = moment(field.maxDate, dateFormat).toDate()
    }

    const disabledDates = field.disabledDates || []

    return (
      <div className={classes.root} ref={this.setRef}>
        <div className={classes.inputContainer}>
          <TextField
            variant='outlined'
            classes={{
              root: [
                classes.input,
                field.fieldStyle ? classes['input' + field.fieldStyle] : ''
              ].filter(item => !(!item)).join(' '),
            }}
            value={stringValue}
            onFocus={() => this.toggleCalendar(true)}
            onBlur={this.onBlur}
            InputProps={{
              maxLength: 10,
              classes: {
                input: classes.inputField + (readonly ? ' ' + classes.inputReadOnly : ''),
              },
              readOnly: readonly
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                shrink: classes.labelShrink,
              }
            }}
            label={field.label}
            onChange={(event) => this.onChange(event.target.value)}
            error={field.error ? true : false}
            FormHelperTextProps={{
              className: classes.helperText
            }}
            helperText={field.error} />
          <IconButton onClick={() => this.toggleCalendar()} disabled={readonly} className={classes.calendarButton}>
            <Icon className={classes.icon}>event</Icon>
          </IconButton>
        </div>
        <div className={classes.calendarContainer + (open ? ' ' + classes.calendarContainerIsOpen : '') + ' ' + classes['calendar-' + position]}>
          <Calendar
            date={currentDate}
            disabledDates={disabledDates.map(disabledDate => {
              return moment(disabledDate, dateFormat).toDate()
            }) || []}
            {...minMaxProps}
            locale={it}
            onChange={(date) => {
              return this.onChange(moment(date).format(dateFormat))
            }} />
        </div>
      </div>
    )
  }
}

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DateField)))
