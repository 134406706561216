import store from '../stores'
import { ajaxCall } from '../utils'

const { appConfig } = store.getState().appReducer

export const logActions = {
  loadLogDataAjax: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.log.loadData.path,
      },
      success: (res) => {
        store.dispatch(logActions.loadLogData(res.data.data))
      },
      error: (err) => {
        console.log(err)
      },
      silently: true,
      activeLoading: false
    })

    return {
      type: 'log_load_data_ajax'
    }
  },
  loadLogData: (logData) => ({
    type: 'log_load_data',
    logData
  })
}
