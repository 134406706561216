import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MUICheckbox from '@material-ui/core/Checkbox'

const styles = theme => ({
  root: {
    fontWeight: 400,
    fontSize: '.75rem',
    textTransform: 'uppercase'
  },
  labelRoot: {
    marginLeft: 0
  },
  checkbox: {
    padding: 0
  }
})

class Checkbox extends React.Component {
  state = {
    currentValue: false
  }

  onChange = () => {
    const { onChange, fieldPath } = this.props

    return this.setState({
      currentValue: !this.state.currentValue
    }, () => {
      return onChange(this.state.currentValue, fieldPath)
    })
  }

  componentDidUpdate = () => {
    const { value } = this.props.cell

    const { currentValue } = this.state

    if (currentValue !== value) {
      this.setState({
        currentValue: value
      })
    }
  }

  render() {
    const { classes, cell, isActive, readonly } = this.props

    const { label, tableControl } = cell

    const { currentValue } = this.state

    const disabled = !isActive ? !tableControl ? true : false : false

    return (
      <FormControlLabel
        classes={{
          root: classes.labelRoot,
          label: classes.root,
        }}
        control={
          <MUICheckbox
            checked={currentValue ? true : false}
            onChange={cell.readonly ? () => {} : this.onChange}
            disabled={readonly || disabled}
            color='primary'
            value=''
            classes={{
              root: classes.checkbox
            }} />
        }
        label={label} />
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Checkbox)))
