import { merluzzi } from './theories/merluzzi'
import { mpb } from './theories/mpb'
import { note } from './theories/note'

export const audiometricCalculator = (chart, type) => {
  const types = {
    merluzzi,
    mpb,
    note
  }

  try {
    return types[type](chart)
  } catch (err) {
    console.log(err)
  }
}
