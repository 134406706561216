import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    margin: '-12px 0 !important',
  },
  input: {
    padding: '13px 14px'
  }
})

class String extends React.Component {
  state = {
    focused: false,
    value: ''
  }

  componentDidMount() {
    const { value = '' } = this.props.field

    this.setState({
      value
    })
  }

  render() {
    const { field, index, onChange, classes } = this.props

    const { value } = this.state

    return (
      <TextField
        value={value}
        fullWidth
        variant='outlined'
        label={field.label}
        multiline={field.multiline || false}
        rows={field.multiline ? 3 : null}
        className={classes.root}
        InputProps={{
          classes: {
            input: classes.input,
          }
        }}
        onChange={(e) => {
          const tmpValue = e.target.value || ''

          this.setState({
            value: tmpValue
          }, () => {
            onChange(tmpValue, index, field.name)
          })
        }}
        />
    )
  }
}

String.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(String)))
