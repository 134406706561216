import React from 'react'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import _ from 'lodash'

const navigationPromptFactory = () => {
  const initialState = {
    currentLocation: null,
    targetLocation: null,
    isOpen: false
  }

  class NavigationPrompt extends React.Component {
    static defaultProps = {
      when: true
    }

    state = initialState

    componentDidMount() {
      this.block(this.props)
      window.addEventListener('beforeunload', this.onBeforeUnload)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        when: nextWhen,
        history: nextHistory,
        whiteListedPathnames: nextWhiteListedPaths
      } = nextProps
      const { when, history, whiteListedPathnames } = this.props
      if (
        when !== nextWhen ||
        !_.isEqual(nextHistory.location, history.location) ||
        !_.isEqual(whiteListedPathnames, nextWhiteListedPaths)
      ) {
        this.unblock()
        this.block(nextProps)
      }
    }

    componentWillUnmount() {
      this.unblock()
      window.removeEventListener('beforeunload', this.onBeforeUnload)
    }

    onBeforeUnload = e => {
      const { when } = this.props

      // we can't override an onBeforeUnload dialog
      // eslint-disable-next-line
      // https://stackoverflow.com/questions/276660/how-can-i-override-the-onbeforeunload-dialog-and-replace-it-with-my-own

      if (when) {
        // support for custom message is no longer there
        // https://www.chromestatus.com/feature/5349061406228480
        // eslint-disable-next-line
        // https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup

        // setting e.returnValue = 'false' to show prompt, reference below
        //https://github.com/electron/electron/issues/2481
        e.returnValue = 'false'
      }
    }

    block = props => {
      const {
        history,
        when,
        whiteListedPathnames = [],
        searchQueryCheck = false
      } = props
      this.unblock = history.block(targetLocation => {
        const hasPathnameChanged =
        history.location.pathname !== targetLocation.pathname
        const hasSearchQueryChanged =
        history.location.search !== targetLocation.search
        const hasUrlChanged = searchQueryCheck
        ? hasPathnameChanged || hasSearchQueryChanged
        : hasPathnameChanged
        const isTargetWhiteListed = whiteListedPathnames.includes(
          targetLocation.pathname
        )
        const hasChanged =
        when && hasUrlChanged && !isTargetWhiteListed
        if (hasChanged) {
          this.setState({
            currentLocation: history.location,
            targetLocation,
            isOpen: true
          })
        }
        return !hasChanged
      })
    }

    onConfirm = () => {
      const { history } = this.props
      const { currentLocation, targetLocation } = this.state
      this.unblock()
      // replacing current location and then pushing navigates to the target otherwise not
      // this is needed when the user tries to change the url manually
      history.replace(currentLocation)
      history.push(targetLocation)
      this.setState(initialState)
    }

    onCancel = () => {
      const { currentLocation } = this.state
      this.setState(initialState)
      // Replacing the current location in case the user tried to change the url manually
      this.unblock()
      this.props.history.replace(currentLocation)
      this.block(this.props)
    }

    render() {
      const { message } = this.props

      return (
        <Dialog
          open={this.state.isOpen}
          onClose={this.onCancel} >
          <DialogTitle>Attenzione</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel} color='primary' autoFocus>
              Annulla
            </Button>
            <Button onClick={this.onConfirm} color='primary'>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  return withRouter(NavigationPrompt)
}

export default navigationPromptFactory()
