import { config } from './config'

export const note = (chart) => {
  const dannoBiologico = config.note

  const dBDX = chart.series[0]

  const dBSX = chart.series[1]

  const hertzs = [
    125,
    250,
    500,
    750,
    1000,
    1500,
    2000,
    3000,
    4000,
    6000,
    8000,
    11000
  ]

  let tTad = 0

  let tTas = 0

  // Calcola il danno biologico all'orecchio destro
  dBDX.forEach((item, index) => {
    if (item > 0) {
      tTad = tTad + dannoBiologico[hertzs[index]][item]
    }
  })

  // Calcola il danno biologico all'orecchio sinistro
  dBSX.forEach((item, index) => {
    if (item > 0) {
      tTas = tTas + dannoBiologico[hertzs[index]][item]
    }
  })

  // Di default si assume che non ci sia un danno biologico
  let resultString = 'NORMALE'

  // Nel caso di deficit unilaterale il danno biologico e' il 12 per cento del deficit
  if (tTad > 0) {
    resultString = 'DEFICIT UDITIVO UNILATERALE DX: ' + (Math.round(tTad * 0.12 * 100) / 100) + ' %'
  }

  if (tTas > 0) {
    resultString = 'DEFICIT UDITIVO UNILATERALE SX: ' + (Math.round(tTas * 0.12 * 100) / 100) + ' %'
  }

  let maxdB = 0

  let mindB = 0

  // Nel caso di deficit uditivo bilaterale il deficit si calcola con la seguente formula
  if (tTad > 0 && tTas > 0) {
    if (tTad > tTas) {
      maxdB = tTad
      mindB = tTas
    } else {
      maxdB = tTas
      mindB = tTad
    }

    let danno = Math.round((((4 * mindB) + maxdB) / 5) * 0.5 * 100) / 100

    resultString = 'DEFICIT UDITIVO BILATERALE: ' + danno + ' %'
  }

  return resultString
}
