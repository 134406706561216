import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../actions'

const styles = theme => ({
  root: {
    display: 'none'
  }
})

class CollapseEnd extends React.Component {
  render() {
    const { classes, field } = this.props

    return (
      <div className={classes.root} id={field.id}></div>
    )
  }
}

CollapseEnd.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CollapseEnd)))
