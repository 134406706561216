import store from '../stores'
import { ajaxCall } from '../utils'

import {
  appActions,
  messageActions
} from './index'

const { appConfig } = store.getState().appReducer

export const updateActions = {
  update: () => {
    store.dispatch(appActions.stopCheckUpdates())

    ajaxCall({
      config: {
        url: appConfig.actions.updates.syncAll.path,
      },
      success: () => {
        return store.dispatch(updateActions.updatePercentages())
      },
      error: (err) => {
        if (err.response.status === 409) {
          return setTimeout(() => {
            store.dispatch(updateActions.update())
          }, appConfig.actions.updates.updateProgress.timeout)
        }
      },
      silently: true,
      activeLoading: false
    })

    return {
      type: 'update_action_update'
    }
  },
  updatePercentages: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.updates.updateProgress.path,
      },
      success: (res) => {
        const { done, up, down, withError } = res.data.data

        if (withError) {
          const errorMessage = {
            message: withError,
            type: 'warning',
            autoHide: false
          }

          setTimeout(() => {
            return store.dispatch(messageActions.showMessage(errorMessage))
          }, 200)
        }

        const percentages = done ? {
          up: 0,
          down: 0
        } : {
          up,
          down
        }

        store.dispatch(updateActions.setPercentages(percentages))

        if (done) {
          store.dispatch(updateActions.endUpdate())

          return store.dispatch(appActions.checkUpdates())
        }

        appConfig.timeouts.updateProgress = setTimeout(() => {
          store.dispatch(updateActions.updatePercentages())
        }, appConfig.actions.updates.updateProgress.timeout)
      },
      error: () => {
        appConfig.timeouts.updateProgress = setTimeout(() => {
          store.dispatch(updateActions.updatePercentages())
        }, appConfig.actions.updates.updateProgress.timeout)
      },
      silently: true,
      activeLoading: false
    })

    return {
      type: 'update_action_update_percentages'
    }
  },
  endUpdate: () => ({
    type: 'update_action_end_update'
  }),
  setPercentages: (percentages) => ({
    type: 'update_action_set_percentages',
    percentages
  })
}
