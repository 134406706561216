import Handlebars from 'handlebars'

Handlebars.registerHelper('or', function(...opts) {
  let toReturn = false

  for (let i = 0; i < opts.length - 1; i++) {
    if (opts[i]) {
      toReturn = true
    }
  }

  return toReturn
})

Handlebars.registerHelper('join', function(...opts) {
  let toReturn = []

  for (let i = 0; i < opts.length - 1; i++) {
    if (opts[i]) {
      toReturn.push(opts[i])
    }
  }

  return toReturn.join(', ')
})

export const handlebarsRender = (obj) => {
  const template = Handlebars.compile(obj.template)

  return template(obj.context)
}
