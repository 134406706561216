import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import AgendaComponent from '../../components/Agenda'

import {
  appActions,
  agendaActions
} from '../../actions'

const styles = () => ({
  root: {
    margin: '1rem',
    padding: '1rem'
  }
})

class Agenda extends React.Component {
  componentDidMount() {
    this.props.onAgendaDataGet()

    this.props.onAgendaFiltersGet()

    this.props.onChangeTitleBlock({
      title: 'Agenda',
      breadcrumbs: [
        {
          text: 'Agenda'
        }
      ]
    })
  }

  render() {
    const { classes, agendaData, agendaFilters } = this.props

    if (!agendaData && !agendaFilters) {
      return ''
    }

    return (
      <Paper className={classes.root}>
        <AgendaComponent agendaFilters={agendaFilters} agendaData={agendaData} />
      </Paper>
    )
  }
}

Agenda.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onAgendaDataGet: () => {
    dispatch(agendaActions.agendaDataGetCall())
  },
  onAgendaFiltersGet: () => {
    dispatch(agendaActions.agendaFiltersGetCall())
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Agenda)))
