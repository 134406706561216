import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from './Select'
import Submit from './Submit'
import Date from './Date'
import Label from './Label'
import Divider from './Divider'
import Checkbox from './Checkbox'
import String from './String'

import {} from '../../../actions'

const styles = theme => ({})

const fieldTypes = {
  select: Select,
  submit: Submit,
  reset: Submit,
  date: Date,
  label: Label,
  divider: Divider,
  checkbox: Checkbox,
  string: String,
}

class BaseField extends React.Component {
  render() {
    const { field, enabled, onChange, onSubmit, index, hidden } = this.props

    const { size, type } = field

    if (!fieldTypes[type]) {
      return null
    }

    const TmpField = fieldTypes[type]

    return (
      <Grid item xs={size || 12}>
        {!hidden ? (
          <TmpField field={field} enabled={enabled} onChange={onChange} onSubmit={onSubmit} index={index} />
        ) : null}
      </Grid>
    )
  }
}

BaseField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BaseField)))
