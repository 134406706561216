import { ceca } from './ceca'
import { ers } from './ers'
import { paoletti } from './paoletti'
import { pistelli2000, pistelli2007 } from './pistelli'

export const calculateTheory = (config) => {
  const theories = {
    ceca: ceca,
    ers: ers,
    paoletti: paoletti,
    pistelli2000: pistelli2000,
    pistelli2007: pistelli2007
  }

  if (theories[config.type]) {
    return theories[config.type](config)
  }

  throw new Error('Teoria non trovata')
}
