import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import FormField from './'
import ModalComponent from '../ModalComponent'

import {
  formActions
} from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
    marginTop: 0,
    marginBottom: 0
  },
  gridRoot: {
    alignItems: 'center'
  },
  noResults: {
    padding: '1rem',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    marginBottom: '1rem'
  },
  action: {
    marginBottom: '1rem'
  },
  actionIcon: {
    marginRight: '.5rem'
  },
  divider: {
    margin: '1rem 0'
  },
  inputField: {
    margin: 0
  },
  inputContainer: {
    padding: '0 .5rem',
    flexGrow: 1
  },
  title: {
    fontSize: '1rem',
    textTransform: 'uppercase'
  },
  textRight: {
    textAlign: 'right'
  },
  withAction: {
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    flexGrow: 1,
    marginTop: -8
  },
  dividerContainer: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
    marginRight: -60
  },
  dynamicInputContainer: {
    flexGrow: 1,
    marginTop: -8,
  },
  fieldClassName: {
    paddingTop: '0!important',
    paddingBottom: '0!important'
  },
  deleteAction: {
    position: 'absolute',
    right: 0,
    top: '50%',
    marginTop: -22
  },
  entryContainer: {
    position: 'relative'
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase'
  }
})

class Modal extends React.Component {
  addValue = () => {
    const { field, onChange } = this.props

    const { values = [], fields } = field

    onChange({
      type: 'dynamic',
      index: values.length,
    }, fields[0], null)
  }

  onClose = () => {
    const { field } = this.props

    const { fieldIndex } = field

    this.props.onFormUpdateFieldProps([{}], {
      fieldName: field.name,
      fieldProp: 'values',
      index: fieldIndex
    })

    return this.props.onFormUpdateFieldProps(false, {
      fieldName: field.name,
      fieldProp: 'open',
      index: fieldIndex
    })
  }

  render() {
    const { classes, field, onChange } = this.props

    const { values, label, fields, header, open } = field

    return (
      <ModalComponent
        open={open || false}
        onClose={this.onClose}
        header={(
          <div>{label}</div>
        )}
        body={(
          <Paper className={classes.root}>
            {label ? (
              <React.Fragment>
                <div className={classes.title}>{label}</div>
                <Divider light className={classes.divider} />
              </React.Fragment>
            ) : ''}
            {header ? (
              <React.Fragment>
                <Grid container spacing={16} className={classes.gridRoot + ' ' + classes.dynamicInputContainer}>
                  {header.map((headerElement, index) => {
                    return (
                      <Grid item xs={headerElement.size || 12} key={index}>
                        <div className={classes.label}>
                          {headerElement.label}
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
                <Divider light className={classes.divider} />
              </React.Fragment>
            ) : ''}
            {values.length ? (values.map((fieldValue, valueIndex) => {
              return (
                <div key={valueIndex} className={classes.entryContainer}>
                  <Grid container spacing={16} className={classes.gridRoot + ' ' + classes.dynamicInputContainer}>
                    {fields.map((tmpField, fieldIndex) => {
                      const readonly = tmpField.canReadonly && fieldValue.readonly

                      const currentField = {
                        ...tmpField,
                        disabled: readonly ? true : false,
                        value: fieldValue[tmpField.name],
                      }

                      return (
                        <FormField gridClass={classes.fieldClassName} key={'dynamic-' + valueIndex + '-' + fieldIndex} readonly={readonly} field={currentField} onChange={(action, tmpField, value) => {
                            return onChange({
                              type: 'dynamic',
                              index: valueIndex,
                              fieldIndex: fieldIndex
                            }, tmpField, value)
                          }} />
                        )
                      })}
                    </Grid>
                  </div>
                )
              })
            ) : null}
          </Paper>
        )} />
    )
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onFormUpdateFieldProps: (value, config) => {
    dispatch(formActions.formUpdateFieldProps(value, config))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Modal)))
