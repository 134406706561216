import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    margin: '-12px 0',
    fontWeight: 600,
    textTransform: 'uppercase',
    height: 41,
    display: 'flex',
    alignItems: 'center'
  }
})

class Label extends React.Component {
  render() {
    const { classes, field } = this.props

    return (
      <div className={classes.root}>
        {field.label}
      </div>
    )
  }
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Label)))
