import React from 'react'
import { matchPath, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ListItem from './ListItem'
import ListSubelement from './ListSubelement'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import {
  appActions
} from '../../actions'

const styles = theme => ({
  menuItemTitle: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: theme.palette.text.default,
    padding: '0 2rem 0 0',
    backgroundColor: 'rgba(0, 0, 0, .2)',
  },
  menuItem: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: theme.palette.text.default,
    padding: '0 2rem 0 0',
  },
  menuItemActive: {
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 3,
      backgroundColor: theme.palette.primary.light
    }
  },
  menuIcon: {
    fontSize: 18,
    padding: 15,
    minWidth: 48,
    maxWidth: 48,
    textAlign: 'center'
  },
  insetIcon: {
    display: 'block',
    color: 'rgba(255, 255, 255, .3)',
  },
  iconSet: {
    display: 'block',
    position: 'relative',
    width: 18,
    textAlign: 'center'
  },
  subIcon: {
    position: 'absolute',
    fontSize: 18,
    bottom: -3,
    right: -3,
    color: '#fff',
  },
  menuDivider: {
    height: 3,
    backgroundColor: 'rgba(0, 0, 0, .7)'
  },
  itemLabel: {
    fontSize: '.75rem'
  },
  expansionPanelRoot: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 0,
    '&::before': {
      content: 'none'
    }
  },
  expansionPanelExpanded: {
    margin: '0 !important',
    backgroundColor: theme.palette.primary.dark,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: theme.appVars.sidebarWidth.main + 'px!important'
  },
  expansionPanelSummaryRoot: {
    padding: 0,
    display: 'block',
  },
  expansionPanelSummaryContent: {
    padding: 0,
    margin: 0,
    border: 0,
  },
  expansionPanelDetailsRoot: {
    padding: 0
  },
  subItems: {
    flexGrow: 1,
  },
  listSubelement: {
    paddingLeft: '.5rem'
  }
})

class ListElement extends React.Component {
  isSelectd = () => {
    const { item, sidebarIsOpen, sidebarSelectedElement } = this.props

    if (sidebarIsOpen && sidebarSelectedElement === item.index) {
      return true
    }

    return false
  }

  render() {
    const { classes, item, location, isFirst, appConfig } = this.props

    if (!item.data) {
      return <ListItem {...{ item, isFirst }} />
    }

    const match = matchPath(location.pathname, {
      path: appConfig.appPrefix + item.match
    })

    return (
      <MuiExpansionPanel classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded,
      }} expanded={this.isSelectd()}>
        <MuiExpansionPanelSummary classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expanded: classes.expansionPanelExpanded,
        }} >
          <ListItem {...{ item, isFirst, isActive: match }} />
        </MuiExpansionPanelSummary>
        <MuiExpansionPanelDetails classes={{
          root: classes.expansionPanelDetailsRoot,
        }}>
          {item.data ? (
            <div className={classes.subItems}>
              {item.data.map((subitem, index) => {
                return (
                  <ListSubelement className={classes.listSubelement} item={{ ...subitem, index, parentIndex: item.index }} key={index} />
                )
              })}
            </div>
          ) : ''}
        </MuiExpansionPanelDetails>
      </MuiExpansionPanel>
    )
  }
}

ListElement.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onToggleSidebar: () => {
    dispatch(appActions.toggleSidebar())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ListElement)))
