/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Paper } from '@material-ui/core'

import {} from '../../actions'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 2,
    minHeight: '100%',
    display: 'flex',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px !important',
    borderRadius: '10px !important',
    flexDirection: 'column',
    '& .RichText-editor': {
      minHeight: '380px'
    }
  },
  progressBackground: {
    backgroundColor: '#e0e0e0',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  barColorPrimary: {
    backgroundColor: '#76ff03',
  },
  rootTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0 0 1rem',
    cursor: 'pointer',
    wordWrap: 'break-word'
  },
  updateItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 2,
    fontSize: '1.3rem',
  },
  updatePercentage: {
    height: '30px',
    borderRadius: '10px',
    marginTop: theme.spacing.unit,
  },
  updatePercentagesLabel: {
    marginLeft: theme.spacing.unit,
  },
  greenProgress: {
    backgroundColor: '#2c8558',
  },
  yellowProgress: {
    backgroundColor: '#fad262',
  },
  orangeProgress: {
    backgroundColor: '#fa650c',
  },
  redProgress: {
    backgroundColor: '#e4092e',
  }
})

class ProgressField extends React.Component {
  getColorClass(usedPercentage) {
    const { classes } = this.props

    if (usedPercentage < 50) return classes.greenProgress

    if (usedPercentage >= 50 && usedPercentage < 75) return classes.yellowProgress

    if (usedPercentage >= 75 && usedPercentage < 100) return classes.orangeProgress

    return classes.redProgress
  }

  render() {
    const { classes, item } = this.props

    const { usedSpaceGB, totalSpaceGB } = item.data || {}

    const usedPercentage = (usedSpaceGB / totalSpaceGB) * 100

    const progressClass = this.getColorClass(usedPercentage)

    return (
      <Paper className={classes.root} elevation={0}>
        <div className={classes.rootTitle}>
          {item.title}
        </div>
        <div className={classes.updateItem}>
          <div className={classes.updatePercentagesLabel}>
            {usedSpaceGB.toFixed(2)} GB occupati su {totalSpaceGB} GB
          </div>
        </div>
        <LinearProgress
          classes={{
            root: classes.progressBackground,
            barColorPrimary: progressClass
          }}
          className={classes.updatePercentage}
          variant="determinate"
          value={usedPercentage}
        />
      </Paper>
    )
  }
}

ProgressField.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ ...state.updateReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ProgressField)))
