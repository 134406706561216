import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ListItem from './ListItem'

import {} from '../../actions'

const styles = theme => ({})

class ListSubelement extends React.Component {
  render() {
    const { item, className } = this.props

    return (
      <ListItem className={className} item={item} />
    )
  }
}

ListSubelement.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ListSubelement)))
