import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { handlebarsRender } from '../../utils'

import {
  printActions
} from '../../actions'

const styles = (theme) => ({})

class Print extends React.Component {
  componentDidMount() {
    this.props.onInitPrint()
  }

  render() {
    const { printConfig } = this.props

    if (!printConfig) {
      return ''
    }

    const htmlToPrint = handlebarsRender({
      template: printConfig.templates.main,
      context: printConfig.context
    })

    return (
      <div dangerouslySetInnerHTML={{ __html: htmlToPrint}}></div>
    )
  }
}

Print.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.printReducer })

const mapDispatchToProps = dispatch => ({
  onInitPrint: () => {
    dispatch(printActions.initPrintPost())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Print)))
