import _ from 'lodash'

const initialState = {
  badges: {}
}

// Vars
let newState = null

const sidebarReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'sidebar_action_add_badge':
      newState = _.cloneDeep(state)

      newState.badges[action.badge] = true

      return newState
    case 'sidebar_action_remove_badge':
      newState = _.cloneDeep(state)

      delete newState.badges[action.badge]

      return newState
    default:
      return state
  }
}

export default sidebarReducer
