import store from '../stores'
import { ajaxCall } from '../utils'

import {
  messageActions
} from '../actions'

const { appConfig } = store.getState().appReducer

export const dashboardActions = {
  dashboardGet: (pageType, params) => {
    ajaxCall({
      config: {
        url: appConfig.actions[pageType].dashboardGet.path,
        params
      },
      success: (res) => {
        store.dispatch(dashboardActions.dashboard(res.data, pageType))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'dashboard_action_get_get',
      pageType
    })
  },
  saveNotePost: (data, url)=> {
    ajaxCall({
      config: {
        url,
        method: 'post',
        data: {
          note: data
        }
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))

          store.dispatch(dashboardActions.dashboardUpdateField({
            value: data,
            field: 'note',
            valueName: 'text'
          }))
        }
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'dashboard_action_save_note_post'
    })
  },
  dashboardUpdateField: (data) => ({
    type: 'dashboard_action_update_field',
    data
  }),
  dashboard: (data, pageType) => ({
    data,
    pageType,
    type: 'dashboard_action_get'
  })
}
