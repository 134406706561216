import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {
  appActions
} from '../../actions'

const styles = (theme) => ({
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  }
})

class Error extends React.Component {
  componentDidMount = () => {
    const { error } = this.props

    this.props.onChangeTitleBlock({
      title: 'Errore',
      breadcrumbs: [
        {
          text: error.message
        }
      ]
    })
  }

  render() {
    const { classes, error } = this.props

    return (
      <div className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          {error.code}
        </Typography>
        <Typography component="h3" variant="h5" align="center" color="textPrimary" gutterBottom>
          {error.message}
        </Typography>
      </div>
    )
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Error)))
