import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Select from '../FormField/Select'
import String from '../FormField/String'
import CalendarComponent from './Calendar'
import {
  agendaActions
} from '../../actions'

const styles = theme => {
  return {
    root: {
      position: 'relative'
    },
    filtersContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8
    },
    selectRoot: {
      width: '30%',
      marginBottom: 30
    },
    searchInput: {
      marginLeft: 16,
      width: '20%'
    }
  }
}

class Agenda extends React.Component {
  state = {
    weeks: [],
    type: 1,
    dayEvents: {},
    selectedDoctors: []
  }

  onChange = data => {
    const {
      weeks,
      type,
      dayEvents
    } = this.state

    if (type === 1) {
      _.set(weeks, data.path, data.visited)

      return this.setState({
        weeks
      })
    }

    const {
      eventInfo
    } = data

    let hour = null

    let findedEventIndex = -1

    dayEvents.hours.find((hourEvents, index) => {
      if (findedEventIndex === -1) {
        hour = index

        findedEventIndex = hourEvents.events.findIndex(event => {
          return event.id === eventInfo.workerId && event.eventId === eventInfo.eventId
        })
      }

      return findedEventIndex > -1
    })

    if (findedEventIndex > -1) {
      const tmpDayEvents = _.cloneDeep(dayEvents)

      tmpDayEvents.hours[hour].events[findedEventIndex].visited = data.visited

      return this.setState({
        dayEvents: tmpDayEvents
      })
    }
  }

  onChangeSelectedDoctors = (values) => {
    this.setState({
      selectedDoctors: values
    }, () => {
      const {
        day,
        type,
        selectedDoctors
      } = this.state

      const { agendaData } = this.props

      this.props.onAgendaDataGet({
        type,
        day: day ? day.format(window.appConfig.dateFormat) : null,
        selectedDoctors,
        current: agendaData.current
      })
    })
  }

  render() {
    const {
      queryString
    } = this.state

    const { classes, agendaFilters, agendaData } = this.props

    return (
      <div className={classes.root}>
        {agendaFilters && (
          <div className={classes.filtersContainer}>
            {agendaFilters.doctorFilter && (
              <Select
                field={{
                  isMulti: true,
                  isClearable: true,
                  value: [],
                  placeholder: agendaFilters.doctorFilter.placeholder,
                  label: agendaFilters.doctorFilter.label,
                  forceFocus: true,
                  options: agendaFilters.doctors.map(doctor => ({
                    value: doctor.id,
                    label: doctor.name
                  }))
                }}
                newCustomStyles={{
                  control: () => ({
                    width: '100%'
                  }),
                  singleValue: () => ({
                    flexGrow: 'unset',
                    width: 'auto',
                    marginRight: 8
                  })
                }}
                selectClasses={{
                  root: classes.selectRoot
                }}
                onChange={(action, field, newValue) => {
                  this.onChangeSelectedDoctors(newValue)

                  return this.onChange(newValue)
                }} />
            )}
            <String
              field={{
                label: 'Ricerca',
                value: queryString || '',
                clearable: true,
                icon: {
                  end: 'search'
                }
              }}
              className={classes.searchInput}
              onChange={(action, field, newValue) => {
                this.setState({
                  queryString: newValue
                })

                return this.onChange(newValue)
              }} />
          </div>
        )}
        {agendaData && <CalendarComponent queryString={queryString} selectedDoctors={this.state.selectedDoctors} />}
      </div>
    )
  }
}

Agenda.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onAgendaDataGet: params => {
    dispatch(agendaActions.agendaDataGetCall(params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Agenda)))
