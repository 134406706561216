import _ from 'lodash'

const initialState = {
  openMessage: false,
  messageConfig: {}
}

// Vars
let newState = null

const messageReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'message_show_message':
      newState = {
        ..._.cloneDeep(state),
        openMessage: true,
        messageConfig: action.obj
      }

      if (action.obj.messageId && action.obj.messageId === state.messageConfig.messageId) {
        return state
      }

      return newState
    case 'message_close_message':
      newState = {
        ..._.cloneDeep(state),
        openMessage: false
      }

      return newState
    default:
      return state
  }
}

export default messageReducer
