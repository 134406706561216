import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import MUICheckbox from '@material-ui/core/Checkbox'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    margin: '-12px 0 !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 .75rem'
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '1rem',
    marginRight: 0
  },
  controlLabel: {
    marginRight: 0
  },
  switchClass: {
    padding: '.25rem',
  }
})

class Checkbox extends React.Component {
  onChange = (event) => {
    const { field, index, onChange } = this.props

    return onChange(event.target.checked ? 1 : 0, index, field.name)
  }

  render() {
    const { field, classes } = this.props

    return (
      <FormGroup className={classes.root}>
        <FormControlLabel
          className={classes.controlLabel}
          control={
            <MUICheckbox
              onChange={this.onChange}
              color='primary'
              classes={{
                root: classes.switchClass
              }}
            />
          } />
        <FormLabel className={classes.label}>{field.label}</FormLabel>
      </FormGroup>
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Checkbox)))
