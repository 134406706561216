import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from '../../routes'
import AuthRoutes from '../../routes/Auth'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Confirm from '../../components/Confirm'
import TitleBlock from '../../components/TitleBlock'
import Print from '../../components/Print'
import HasUpdates from '../../components/HasUpdates'
import Chatbot from '../../components/Chatbot'
import '../../components/FontAwesome'
import ResetPassword from '../ResetPassword'
import OtpCheck from '../OtpCheck'

import {
  confirmActions,
  appActions,
  paginationConfigActions
} from '../../actions'

const themeConfig = {
  typography: {
    useNextVariants: true,
    fontSize: 13
  },
  appVars: {
    navBarHeight: 64,
    footerHeight: 50,
    breadcrumbsHeight: 50,
    sidebarWidth: {
      main: 48,
      open: 200
    },
    searchBoxHeight: 60,
  },
  palette: {
    background: {
      default: '#fff',
      title: '#F0F0F7'
    },
    text: {
      default: '#fff',
      primary: '#43425D'
    },
    primary: {
      main: '#0069c0',
      light: '#008bff',
      dark: '#004c8c'
    },
    error: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d',
      300: '#611a15',
    },
    warning: {
      main: '#ff9800',
      light: '#ffc947',
      dark: '#c66900'
    },
    success: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23',
      50: '#edf7ed',
      100: '#38bf60',
      200: '#3f9258',
      300: '#1e4620'
    },
    info: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0',
      badge: '#3B86FF',
      50: '#e6f3ff',
      300: '#0d3c61'
    },
    placeholder: {
      main: '#F1F1F3',
      dark: '#a1a0ae',
    },
    textColor: color => {
      const hexToRgb = hex => {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null
      }

      color = hexToRgb(color)

      const brightness = Math.sqrt((color.r * color.r * .241) + (color.g * color.g * .691) + (color.b * color.b * .068))

      return brightness < 130 ? '#fff' : '#000'
    },
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    minWidth: 1200,
    width: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    paddingLeft: themeConfig.appVars.sidebarWidth.main,
    paddingTop: 64,
    transition: 'padding-left .3s ease-in-out',
    fontSize: 13
  },
  open: {
    paddingLeft: themeConfig.appVars.sidebarWidth.open,
  },
  container: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
  },
  appAndFooterContainer: {
    maxHeight: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  routerContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 60,
  },
  routContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
})

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme(themeConfig)

class App extends React.Component {
  state = {
    reloading: false
  }

  componentDidMount = () => {
    this.props.onInitApp()
  }

  componentDidUpdate = (prevProps) => {
    const {
      appConfig: prevAppConfig,
      location: prevLocation
    } = prevProps

    const {
      appConfig,
      location
    } = this.props

    if (prevLocation.pathname !== location.pathname) {
      this.props.onInitPaginationConfig()
    }

    const {
      selectedDb: prevSelectedDb
    } = prevAppConfig

    const {
      selectedDb
    } = appConfig

    if (prevSelectedDb && prevSelectedDb.value !== selectedDb.value) {
      this.props.history.push('/')

      this.setState({
        reloading: true
      }, () => {
        setTimeout(() => {
          this.setState({
            reloading: false
          })
        }, 100)
      })
    }

    const {
      password: prevPassword = {}
    } = ((prevProps.appConfig || {}).userConfig || {}).user || {}

    const {
      password = {}
    } = ((this.props.appConfig || {}).userConfig || {}).user || {}

    if (password.expiring !== prevPassword.expiring && password.expiring) {
      this.props.onHandleOpen(password.confirm, this.testConfirm, [])
    }
  }

  testConfirm = () => {
    const {
      history,
      appConfig
    } = this.props

    history.push(appConfig.appPrefix + '/user/profile')
  }

  render() {
    const {
      classes,
      appConfig,
      sidebarConfig,
      sidebarIsOpen,
      dbList,
      hasUpdates,
      hasSoftwareUpdates,
      updater
    } = this.props

    const { reloading } = this.state

    const {
      password
    } = ((appConfig || {}).userConfig || {}).user || {}

    if (!appConfig || (appConfig.isLogedIn && !password)) {
      return ''
    }

    const is2F = appConfig.isLogedIn && password && password.is2F

    const isResetPassword = appConfig.isLogedIn && (!password || password.expired || password.firstAccess)

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Confirm />
        <Message />
        <Loading />
        {is2F ? (
          <OtpCheck />
        ) : isResetPassword ? (
          <ResetPassword />
        ) : (
          <React.Fragment>
            {appConfig.userConfig ? (
              <HasUpdates hasUpdates={hasUpdates} hasSoftwareUpdates={hasSoftwareUpdates} platform={appConfig.userConfig.platform} />
            ) : null}
            {(!appConfig || !appConfig.isLogedIn) ? (
              <AuthRoutes />
            ) : (!dbList || !sidebarConfig || reloading ? (
              <Loading forceLoading={true} />
            ) : (
              <div className={classes.root + (sidebarIsOpen ? ' ' + classes.open : '')}>
                <Print />
                <Sidebar />
                <div className={classes.container}>
                  <Header dbList={dbList} updater={updater} />
                  {appConfig.userConfig.hasChatBot ? (
                    <Chatbot/>
                  ) : null}
                  <div className={classes.appAndFooterContainer}>
                    <div className={classes.routerContainer}>
                      <TitleBlock sidebarIsOpen={sidebarIsOpen} />
                      <div className={classes.routContainer}>
                        <Routes sidebarIsOpen={sidebarIsOpen} />
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onInitApp: () => {
    dispatch(appActions.initApp())
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onInitPaginationConfig: () => {
    dispatch(paginationConfigActions.initPaginationConfig)
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(App)))
