import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import htmlToDraft from 'html-to-draftjs'
import { convertToRaw, ContentState, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { stripeHtml } from '../../../../utils'

const styles = theme => ({
  editorClassName: {
    border: '1px solid ' + theme.palette.grey[100],
    borderTop: 0,
  },
  editorToolbar: {
    marginBottom: 0,
  }
})

class RichText extends React.Component {
  state = {
    editorState: null,
    stringValue: ''
  }

  componentDidMount = () => {
    const { field = {} } = this.props

    const { value } = field

    this.setState({
      stringValue: value || '',
      editorState: this.getStateFromHtml(value || ''),
    })
  }

  getStateFromHtml = (html) => {
    return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks))
  }

  render() {
    const { field = {}, classes, onChange } = this.props

    const { editorState } = this.state

    return (
      <Editor
        editorState={editorState}
        toolbarClassName={classes.editorToolbar}
        editorClassName={classes.editorClassName}
        readOnly={!(!field.readonly)}
        onEditorStateChange={(editorState) => {
          const stringValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))

          this.setState({
            editorState,
            stringValue
          })

          onChange(stripeHtml(stringValue) ? stringValue : '')
        }}
        toolbar={{
          options: ['inline', 'colorPicker', 'list', 'textAlign', 'link', 'history'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
          },
          textAlign: {
            inDropdown: false,
            options: ['left', 'center', 'right', 'justify'],
          },
        }} />
    )
  }
}

RichText.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RichText)))
