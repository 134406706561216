import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HeaderLogin from '../../components/HeaderLogin'
import Icon from '../../components/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import _ from 'lodash'
import { FormControl, Input, InputLabel, LinearProgress } from '@material-ui/core'
import prettyBytes from 'pretty-bytes'
import {
  authActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    minWidth: 350,
    maxWidth: 350
  },
  buttonsGrid: {
    paddingTop: '3rem',
    alignItems: 'center'
  },
  navLink: {
    color: theme.palette.info.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  adornment: {
    whiteSpace: 'nowrap'
  },
  textField: {
    width: '100%'
  },
  progressBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 999999,
    marginBottom: '1rem'
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: 'rgb(232, 244, 253)',
    minWidth: 450,
    fontWeight: 500
  },
  text: {
    marginBottom: '1rem'
  }
})

class Login extends React.Component {
  _isMounted = false

  state = {
    username: '',
    password: '',
    instance: '',
    inputType: 'password',
    loadingDots: '',
    isMounted: true
  }

  changeLoadginDots = () => {
    this.setState({
      loadingDots: this.state.loadingDots.length === 5 ? '' : this.state.loadingDots + '.'
    })

    setTimeout(() => {
      if (this._isMounted) {
        this.changeLoadginDots()
      }
    }, 500)
  }

  componentDidMount = () => {
    this._isMounted = true

    this.props.onHasLocalDbs()

    this.changeLoadginDots()
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleChange = (event, name) => {
    let newState = _.cloneDeep(this.state)

    newState[name] = event.target.value

    return this.setState(newState)
  }

  render() {
    const {
      classes,
      hasLocalDbs,
      progress,
      appConfig
    } = this.props

    if (!hasLocalDbs && hasLocalDbs !== false) {
      return ''
    }

    const { username, instance, password, inputType, loadingDots } = this.state

    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={(event) => {
          event.preventDefault()

          this.props.onLoginPost(this.state)

          if (!hasLocalDbs) {
            setTimeout(() => {
              this.props.onLocalDbsStatusGet()
            }, 5000)
          }
        }}>
          <HeaderLogin />
          {!hasLocalDbs && (
            <FormControl className={classes.textField}>
              <InputLabel htmlFor='instance'>Nome istanza</InputLabel>
              <Input
                id='instance'
                value={instance}
                onChange={(event) => {
                  return this.handleChange(event, 'instance')
                }}
                endAdornment={<InputAdornment position='end' className={classes.adornment}>.achille-medlav.com</InputAdornment>}
                startAdornment={<InputAdornment position='start'>https://</InputAdornment>}
              />
            </FormControl>
          )}
          <TextField
            fullWidth
            id='username'
            label='Username'
            className={classes.textField}
            value={username}
            InputProps={{
              inputProps: {
                autoFocus: true
              }
            }}
            margin='normal'
            onChange={(event) => {
              return this.handleChange(event, 'username')
            }}
          />
          <TextField
            fullWidth
            id='password'
            type={inputType}
            label='Password'
            className={classes.textField}
            value={password}
            margin='normal'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' onClick={() => {
                  return this.setState({
                    inputType: inputType === 'text' ? 'password' : 'text'
                  })
                }}>
                  <Icon>{inputType === 'text' ? 'eye' : 'eye-slash'}</Icon>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              return this.handleChange(event, 'password')
            }}
          />
          <Grid container spacing={16} className={classes.buttonsGrid}>
            <Grid item xs={6}>
              <NavLink className={classes.navLink} to={appConfig.appPrefix + '/auth/recovery'}>Recupera password</NavLink>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='contained' color='primary' type='submit'>Login</Button>
            </Grid>
          </Grid>
        </form>
        {!hasLocalDbs && progress && (
          <div className={classes.progressBar}>
            <div className={classes.progressBarContainer}>
              {!progress.progress ? (
                <div>Preparazione delle configurazioni{loadingDots}</div>
              ) : progress.progress === progress.size ? (
                <div>Installazione delle configurazioni{loadingDots}</div>
              ) : (
                <>
                  <div className={classes.text}>Download delle configurazioni {prettyBytes(progress.progress, {
                    maximumFractionDigits: 0,
                    space: false
                  })} / {prettyBytes(progress.size, {
                    maximumFractionDigits: 0,
                    space: false
                  })}.</div>
                  <LinearProgress variant='determinate' value={(100 / progress.size) * progress.progress} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.appReducer,
  ...state.authReducer
})

const mapDispatchToProps = dispatch => ({
  onHasLocalDbs: () => {
    return dispatch(authActions.hasLocalDbsGet())
  },
  onLoginPost: (data) => {
    return dispatch(authActions.loginPost(data))
  },
  onLocalDbsStatusGet: () => {
    return dispatch(authActions.localDbsProgressGet())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login)))
