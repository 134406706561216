import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUIDivider from '@material-ui/core/Divider'

import {} from '../../actions'

const styles = theme => ({
  divider: {
    padding: '1rem',
    flexGrow: 1
  },
  dividerContainer: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verticalDivider: {
    width: 1,
    backgroundColor: theme.palette.grey[300],
    height: 40
  },
  dividerLine: {
    height: 2
  }
})

class Divider extends React.Component {
  render() {
    const { classes, field } = this.props

    if (field.vertical) {
      return (
        <div className={classes.verticalDivider}></div>
      )
    }

    return (
      <div className={classes.divider}>
        <MUIDivider className={classes.dividerLine} light />
      </div>
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Divider)))
