import store from '../stores'
import { ajaxCall } from '../utils'

import {} from './index'

const { appConfig } = store.getState().appReducer

export const statusActions = {
  status: (url) => {
    if (appConfig.timeouts.status) {
      clearTimeout(appConfig.timeouts.status[url])
    } else {
      appConfig.timeouts.status = {}
    }

    appConfig.timeouts.status[url] = null

    ajaxCall({
      config: {
        url,
      },
      success: (res) => {
        const bars = res.data.data

        const hasMore = bars.find(bar => {
          return bar.complete < 100
        })

        if (hasMore) {
          appConfig.timeouts.status[url] = setTimeout(() => {
            store.dispatch(statusActions.status(url))
          }, 1000)
        }

        return store.dispatch(statusActions.statusBars(url, res.data.data, !(!hasMore)))
      },
      error: () => {
        appConfig.timeouts.status[url] = setTimeout(() => {
          store.dispatch(statusActions.status(url))
        }, 1000)
      },
      silently: true,
      activeLoading: false
    })

    return {
      type: 'status_action_status'
    }
  },
  statusBars: (url, data, complete) => ({
    type: 'status_action_status_bars',
    url,
    data,
    complete
  })
}
