import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import _ from 'lodash'

import {
} from '../../actions'

const styles = theme => ({
  root: {
    minWidth: '100%',
  },
  chartRoot: {
    zoom: '123.33%'
  },
  textField: {
    marginTop: 0,
  },
  input: {
    padding: '.75rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  checksRoot: {
    marginTop: '2rem',
  },
  radioRoot: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: '2rem',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioLabel: {
    fontWeight: 600,
    fontSize: '0.9285714285714286rem',
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    margin: 0,
  },
  radioLabelText: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  radioElement: {
    color: theme.palette.common.white + ' !important',
  },
  checkboxLabel: {
    display: 'block',
    fontWeight: 600,
    margin: '5px 0',
    fontSize: '1rem',
  }
})

class Audiometric extends React.Component {
  colors = {
    right: '#DF2424',
    left: '#3B3BEB'
  }

  state = {
    disabledHertzs: [],
    normalValue: 0,
    tickPositions: {
      xAxis: ['125', '250', '500', '750', '1000', '1500', '2000', '3000', '4000', '6000', '8000', '11000'],
      yAxis: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
    },
    ear: 'right',
    series: [{
      name: 'Orecchio DX (AEREA)',
      data: [null, null, null, null, null, null, null, null, null, null, null, null],
      color: this.colors.right,
      connectNulls: true,
      marker: {
        enabled: true,
        symbol: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASNJREFUeNrMlM0RgjAQhZEKtAK9eFdP3sQKxA7oAKhA7QAqgA4cKxBvnhzuXrADS/Cts5lZY8KPXsjMzgY2+XjsbuI4fR+DNovuy4XH03J6vT1/AgISwIWwmRYqYSnAeSsgQEO4DOZrEEeD07u1rtg1fOQoYAfYCJvmZDSHxbAnw88swKwQwT3cjjfQ10tLOt4wGMESrIttCkOlzAajwbEtP0ZSpatVkgIVNiRNlceaQuTWNylUCS86tN2J/cQEVLIf/zS2BFbsVx32j+uA6lc9vRUslR6K3BVfQCS5EoGshbpIFLGwtc1BVQ0Kshp1Eferw41ee/QCoZBUp9rRC0VVP5raejkA6jPUlks6SbHpghi0SPpGgAl0geVN11h/x0uAAQBFj2GJMvPlCAAAAABJRU5ErkJggg==)'
      },
    }, {
      name: 'Orecchio SX (AEREA)',
      data: [null, null, null, null, null, null, null, null, null, null, null, null],
      color: this.colors.left,
      connectNulls: true,
      marker: {
        enabled: true,
        symbol: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMlJREFUeNpiYBgFlAJGdAHf8AUCQKofiAs3r0z4gE0TVM18IG4EqrmALMeERf1+IE4A0VCN2AwDqQmAqlEgZOBEKG2AbiiSYQZQoQ1AFz7A62WoxgSol0AA5CVHJNfDDFsANCyRYBjiMZSBkGF4DcRiKAMhw3CFITLYAMTIYQSK9UJ8GpjwuA4WAcixKIAr9vEaiCU2FwBxIq7YJyZhY41NbLGPnvhxJWyssQlkE3QpNgMv4ItNNEMv4Mqe2JILITUOo0UhGAAEGAD4dlmXGShdrAAAAABJRU5ErkJggg==)'
      },
    }, {
      name: 'Orecchio DX (OSSEA)',
      data: [null, null, null, null, null, null, null, null, null, null, null, null],
      color: this.colors.right,
      connectNulls: true,
      marker: {
        enabled: true,
        symbol: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKBJREFUeNpiYBhxgBGf5G0LIwMg1Q/Egaonzn2gyECgYQJA6j4Qg+gLQOxIjKFMuCSgmguhXJBL90MtId/LUJcmAKn5UC5BlzISEy6kGMpIbOwRaygjKUmCGEOZSExmG4D4AVJEORAdyziS0X4gVoAKJQJdt4HcSIEZZoBk2AJykw3RhhGT9UgyjJisR5JhpEQKUYYRFRlAHDC8CliAAAMA24ZJGclzymwAAAAASUVORK5CYII=)'
      },
    }, {
      name: 'Orecchio SX (OSSEA)',
      data: [null, null, null, null, null, null, null, null, null, null, null, null],
      color: this.colors.left,
      connectNulls: true,
      marker: {
        enabled: true,
        symbol: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAI5JREFUeNpiYBhxgJEUxb7hCwyAVD8QB25emfABmxomEg3bD8QOQLwelzomEg0TAGKQywrJ9jIWwxyB3r1AloGkGobXQHIMw2kguYbhi5R4qGEgcIBYwwh5eT6QSoByFwANTaQ4YZNjKDHJhiRDGYlM2EQbykhC1iPKUKLzMtSABVQvnoAuTRjaBSxAgAEA0NBE9aBC8MgAAAAASUVORK5CYII=)'
      },
    }]
  }

  componentDidMount = () => {
    const { field } = this.props

    const { value = {} } = field

    const newState = _.cloneDeep(this.state)

    newState.normalValue = value ? value.normalValue || 0 : 0

    if (value && value.series) {
      value.series.forEach((serie, index) => {
        newState.series[index].data = serie.map(item => {
          if (!item) {
            return null
          }

          return [
            '' + item[0],
            item[1]
          ]
        })
      })
    }

    return this.setState(newState)
  }

  componentDidUpdate = (preProps) => {
    const { value } = this.props.field

    const { series } = value

    const { series: prevSeries } = preProps.field.value

    let newState = _.cloneDeep(this.state)

    if (JSON.stringify(series) !== JSON.stringify(prevSeries)) {
      value.series.forEach((serie, index) => {
        newState.series[index].data = _.cloneDeep(serie)
      })

      return this.setState(newState, () => {
        this.onChange()

        setTimeout(() => {
          this.updateChart()
        }, 200)
      })
    }
  }

  getAudioType = () => {
    const { formContent, field } = this.props

    const { fields } = formContent

    const type = fields.filter(item => {
      return item.name === field.relatedNames.type
    })[0] || {}

    return type.value && type.value.value === 'ossea' ? 1 : 0
  }

  getNearestTick = (xPos, yPos) => {
    const { xAxis, yAxis } = this.state.tickPositions

    const newYAxis = []

    yAxis.forEach((item, index) => {
      newYAxis.push(item)

      if (index > 0) {
        newYAxis.push(item - 5)
      }
    })

    const xValue = xAxis[parseInt(Math.round(xPos))]

    const yValue = newYAxis.reduce((prev, curr) => {
      return (Math.abs(curr - yPos) < Math.abs(prev - yPos) ? curr : prev)
    })

    return [
      xValue,
      yValue,
    ]
  }

  updateChart = () => {
    this.setState(_.cloneDeep({
      series: _.cloneDeep(this.state.series)
    }))
  }

  getSelectedSerie = () => {
    const { ear } = this.state

    const type = this.getAudioType()

    let toReturn = 0

    if (ear === 'left') {
      toReturn = 1

      if (type === 1) {
        toReturn = 3
      }
    } else {
      if (type === 1) {
        toReturn = 2
      }
    }

    return toReturn
  }

  addPoint = (xPos, yPos) => {
    const nearestTick = this.getNearestTick(xPos, yPos)

    const { tickPositions, series, disabledHertzs } = this.state

    const selectedSerie = this.getSelectedSerie()

    const selectedData = _.cloneDeep(series[selectedSerie].data)

    const xPosition = tickPositions.xAxis.indexOf(nearestTick[0])

    if (disabledHertzs.indexOf(nearestTick[0]) !== -1) {
      return true
    }

    const isTheSame = selectedData[xPosition] ? nearestTick[0] === selectedData[xPosition][0] && nearestTick[1] === selectedData[xPosition][1] : false

    selectedData[xPosition] = isTheSame ? null : nearestTick

    series[selectedSerie].data = selectedData

    return this.setState(_.cloneDeep({
      series: _.cloneDeep(series)
    }), () => {
      this.onChange()

      setTimeout(() => {
        this.updateChart()
      }, 200)
    })
  }

  onClick = (e) => {
    return this.addPoint(e.xAxis[0].value, e.yAxis[0].value)
  }

  setAllNormal = () => {
    const type = this.getAudioType()

    const { series, tickPositions, normalValue, disabledHertzs } = this.state

    const types = type === 0 ? [0, 1] : [2, 3]

    types.forEach(serie => {
      const tmpData = []

      tickPositions.xAxis.forEach(item => {
        if (!disabledHertzs || disabledHertzs.findIndex(disabled => parseInt(item) === parseInt(disabled)) === -1) {
          tmpData.push([
            item,
            normalValue
          ])
        } else {
          tmpData.push(null)
        }
      })

      series[serie].data = tmpData
    })

    return this.setState(_.cloneDeep({
      series: _.cloneDeep(series)
    }), this.onChange)
  }

  setNormalValue = (e) => {
    const state = _.cloneDeep(this.state)

    const normalValue = parseInt(e.target.value, 10)

    state.normalValue = normalValue

    return this.setState(state, this.onChange)
  }

  onChange = () => {
    const { field, onChange } = this.props

    const state = _.cloneDeep(this.state)

    return onChange({type: 'string'}, field, {
      ...field.value,
      normalValue: state.normalValue,
      series: state.series.map(serie => {
        return serie.data
      })
    })
  }

  render() {
    const { classes, field } = this.props

    const { aside } = field

    const { right, left } = this.colors

    const { tickPositions, series, normalValue, ear, disabledHertzs } = this.state

    if (disabledHertzs.length === 0 && aside.disableFor && aside.disableFor.xAxis) {
      Object.entries(aside.disableFor.xAxis).forEach(([key, value]) => {
        if (value) {
          disabledHertzs.push(key)
        }
      })
    }

    const options = {
      margin: [70, 50, 60, 80],
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: 'Hertz (Hz)'
        },
        tickPosition: 'inside',
        tickPositions: tickPositions.xAxis.map((item, index) => {
          return index
        }),
        min: 0.3,
        max: 10.7,
        minTickInterval: 5,
        gridLineWidth: 1,
        categories: tickPositions.xAxis,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: 'Decibel (dB)'
        },
        minPadding: 0.2,
        maxPadding: 0.2,
        maxZoom: 60,
        min: -10,
        max: 120,
        tickPositions: tickPositions.yAxis,
        minTickInterval: 50,
        reversed: true
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
        x: 0,
        y: 10
      },
      exporting: {
        enabled: false
      },
      series: _.cloneDeep(series),
      plotOptions: {
        series: {
          enableMouseTracking: false
        }
      },
      chart: {
        type: 'line',
        events: {
          click: this.onClick
        }
      },
      credits: {
        enabled: false
      }
    }

    return (
      <Grid container spacing={24}>
        <Grid item xs={10} className={classes.chartRoot}>
          <HighchartsReact
            containerProps={{className: classes.root}}
            highcharts={Highcharts}
            options={options} />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            value={normalValue}
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.input,
              },
              inputProps: {
                min: -10,
                max: 120,
                step: 10,
              }
            }}
            onChange={this.setNormalValue}
            type='number'
            variant='outlined' />
          <Button fullWidth variant='contained' onClick={this.setAllNormal}>{aside.normal}</Button>
          <div className={classes.radioRoot}>
            {aside.ears.label ? (
              <FormLabel className={classes.radioLabel} component='legend'>{aside.ears.label}</FormLabel>
            ) : null}
            <RadioGroup
              aria-label={aside.ears.label || ''}
              className={classes.radioGroup}
              value={ear}
              onChange={(event) => {
                const state = _.cloneDeep(this.state)

                state.ear = event.target.value

                this.setState(state)
              }} >
              {aside.ears.options.map((option, index) => {
                return (
                  <FormControlLabel style={{
                    backgroundColor: option.value === 'right' ? right : left
                  }} className={classes.radioLabel} classes={{
                    label: classes.radioLabelText
                  }} key={index} value={option.value} control={<Radio className={classes.radioElement} />} label={option.label} />
                )
              })}
            </RadioGroup>
          </div>
          <div className={classes.checksRoot}>
            {aside.disableFor && aside.disableFor.label ? (
              <FormLabel className={classes.radioLabel} component='legend'>{aside.disableFor.label}</FormLabel>
            ) : null}
            {tickPositions.xAxis.map((hertz, index) => {
              const checked = disabledHertzs.indexOf(hertz) >= 0

              return (
                <label key={index} className={classes.checkboxLabel}>
                  <input type='checkbox' checked={checked} onChange={event => {
                    const isChecked = event.target.checked

                    const newState = _.cloneDeep(this.state)

                    const itemInState = newState.disabledHertzs.indexOf(hertz)

                    if (isChecked && itemInState < 0) {
                      newState.disabledHertzs.push(hertz)
                    } else if (!isChecked && itemInState >= 0) {
                      newState.disabledHertzs.splice(itemInState, 1)
                    }

                    return this.setState(newState)
                  }} /> {hertz}
                </label>
              )
            })}
          </div>
        </Grid>
      </Grid>
    )
  }
}

Audiometric.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.formReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Audiometric)))
