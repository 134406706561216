import { config } from './config'
import { countCharsFrom, eqToOneOf } from '../helpers'
import _ from 'lodash'

const calcEar = (chart, part) => {
  const aMin = [null]

  const aMax = [null]

  const aLet = [null]

  const aMisura = []

  let mpb3000 = ''

  let mpb4000 = ''

  let orecchio = ''

  let parte = 'Orecchio ' + part + ', classificazione MPB: '

  let numE = 0

  let numG = 0

  let numI = 0

  let numPos4000 = 0

  let vv = 0

  const rsAUdef = _.cloneDeep(config.merluzzi)

  const earSerie = chart.series[part === 'destro' ? 0 : 1]

  for (let i = 1; i <= 7; i++) {
    aMisura[i] = ''
  }

  if (rsAUdef.length) {
    rsAUdef.forEach((item, index) => {
      aMin[index + 1] = rsAUdef[index].min

      aMax[index + 1] = rsAUdef[index].max

      aLet[index + 1] = rsAUdef[index].lettera
    })
  }

  //  ORECCHIO DX
  for (let i = 1; i <= 5; i++) {
    vv = earSerie[3]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[1] = aLet[i]
    }

    vv = earSerie[5]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[2] = aLet[i]
    }

    vv = earSerie[7]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[3] = aLet[i]
    }

    vv = earSerie[8]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[4] = aLet[i]
    }
  }

  for (let i = 6; i <= 10; i++) {
    vv = earSerie[9]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[5] = aLet[i]
    }

    vv = earSerie[10]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[6] = aLet[i]
    }

    vv = earSerie[11]

    if (vv >= aMin[i] && vv <= aMax[i]) {
      aMisura[7] = aLet[i]
    }
  }

  mpb3000 = 'a'

  for (let i = 1; i <= 4; i++) {
    if (aMisura[i] !== '' && aMisura[i].charCodeAt(0) > mpb3000.charCodeAt(0)) {
      mpb3000 = aMisura[i]
    }
  }

  mpb4000 = 'b'

  for (let i = 5; i <= 7; i++) {
    if (aMisura[i] !== '' && aMisura[i].charCodeAt(0) > mpb4000.charCodeAt(0)) {
      mpb4000 = aMisura[i]
    }
  }

  numE = countCharsFrom(aMisura, 'e', 1, 4)

  numG = countCharsFrom(aMisura, 'g', 1, 4)

  numI = countCharsFrom(aMisura, 'i', 1, 4)

  numPos4000 = 0

  for (let i = 5; i <= 7; i++) {
    if (aMisura[i] !== '') {
      if (aMisura[i].charCodeAt(0) > ('d').charCodeAt(0)) {
        numPos4000 = numPos4000 + 1
      }
    }
  }

  if (eqToOneOf(mpb3000, 'a', 'c') && eqToOneOf(mpb4000, 'b', 'd')) {
    orecchio = parte + '(0 o 1a) normale '
  }

  if (eqToOneOf(mpb3000, 'a', 'c') && mpb4000 === 'f') {
    orecchio = parte + '(2a) ipoacusia da rumore di grado lieve '
  }

  if (eqToOneOf(mpb3000, 'a', 'c') && mpb4000 === 'h') {
    orecchio = parte + '(2b) ipoacusia da rumore di grado medio '
  }

  if (eqToOneOf(mpb3000, 'a', 'c') && mpb4000 === 'l') {
    orecchio = parte + '(2c) ipoacusia da rumore di grado grave '
  }

  if (mpb3000 === 'e' && mpb4000 === 'f') {
    orecchio = parte + '(3a) ipoacusia mista di grado lieve '
  }

  if (mpb3000 === 'g' && mpb4000 === 'h') {
    orecchio = parte + '(3b) ipoacusia mista di grado medio '
  }

  if (mpb3000 === 'i' && mpb4000 === 'l') {
    orecchio = parte + '(3c) ipoacusia mista di grado grave '
  }

  if (numE === 2 && mpb4000 === 'f') {
    orecchio = parte + '(4a) ipoacusia mista di grado lieve '
  }

  if (numG === 2 && mpb4000 === 'h') {
    orecchio = parte + '(4b) ipoacusia mista di grado medio '
  }

  if (numI === 2 && mpb4000 === 'l') {
    orecchio = parte + '(4c) ipoacusia mista di grado grave '
  }

  if (numE === 3 && mpb4000 === 'h') {
    orecchio = parte + '(5a) ipoacusia mista di grado lieve '
  }

  if (eqToOneOf(numG, 2, 1) && mpb4000 === 'l') {
    orecchio = parte + '(5b) ipoacusia mista di grado medio '
  }

  if (numI === 3 && mpb4000 === 'l') {
    orecchio = parte + '(5c) ipoacusia mista di grado grave '
  }

  if (numE === 2 && numG === 1 && mpb4000 === 'h') {
    orecchio = parte + '(6a) ipoacusia mista di grado medio '
  }

  if (numE === 3 && eqToOneOf(mpb4000, 'b', 'd')) {
    orecchio = parte + '(7) ipoacusia non da rumore lieve'
  }

  if (numG === 3 && eqToOneOf(mpb4000, 'b', 'd')) {
    orecchio = parte + '(7) ipoacusia non da rumore media'
  }

  if (numI === 3 && eqToOneOf(mpb4000, 'b', 'd')) {
    orecchio = parte + '(7) ipoacusia non da rumore grave'
  }

  if (mpb3000 === 'e' && mpb4000 === 'f' && numPos4000 > 1) {
    orecchio = parte + '(8) presbiacusia di grado lieve '
  }

  if (mpb3000 === 'g' && mpb4000 === 'h' && numPos4000 > 1) {
    orecchio = parte + '(8) presbiacusia di grado medio '
  }

  if (mpb3000 === 'i' && mpb4000 === 'l' && numPos4000 > 1) {
    orecchio = parte + '(8) presbiacusia di grado grave '
  }

  if (mpb3000 === 'i' && mpb4000 === 'l' && numPos4000 > 1) {
    orecchio = parte + '(9) deficit uditivo a doppia eziologia '
  }

  if (orecchio.length === 0) {
    orecchio = 'Orecchio ' + (part === 'destro' ? 'Dx' : 'SX') + ': Classificazione MPB non eseguita.'
  }

  return orecchio
}

export const mpb = (chart) => {
  const orecchioDX = calcEar(chart, 'destro')
  const orecchioSX = calcEar(chart, 'sinistro')

  return orecchioDX + '\n' + orecchioSX
}
