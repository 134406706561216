import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    textAlign: 'center',
    borderTop: '1px solid #ccc',
    padding: '1rem',
    marginBottom: '1rem'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 600
  },
  text: {
    fontSize: '.75rem'
  }
})

class Title extends React.Component {
  render() {
    const { classes, block } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.title}>{block.title}</div>
        <div className={classes.text}>{block.text}</div>
      </div>
    )
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Title)))
