import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TableComponent from '../../components/Table'
import PageActions from '../../components/PageActions'
import _ from 'lodash'
import { getPaginationConfig } from '../../utils/pagination'
import Typography from '@material-ui/core/Typography'
import Icon from '../../components/Icon'

import {
  appActions,
  listPageActions,
  confirmActions,
  printActions
} from '../../actions'

const styles = () => ({
  root: {
    margin: '1rem'
  },
  mBottom5: {
    marginBottom: '55px'
  },
  caption: {
    margin: '1rem',
  },
  captionIcon: {
    fontSize: 10,
    marginRight: 10,
    display: 'inline-block'
  }
})

class ListPage extends React.Component {
  state = {
    selectedElements: {},
    activeElements: {},
    loaded: false
  }

  loadPage = () => {
    const {
      pageType,
      pageTypes,
      match,
      paginationConfig: prevPaginationConfig = {}
    } = this.props

    let paginationConfig = getPaginationConfig(prevPaginationConfig)

    if (paginationConfig.lastViewedReset) {
      paginationConfig = {
        lastViewedReset: false,
        paginationConfig: paginationConfig.lastViewed
      }
    }

    const { params } = match

    this.props.onListPageGet(pageType, {
      ...paginationConfig,
      ...params
    })

    this.props.onListPagesGet(pageType, pageTypes, {
      ...paginationConfig,
      ...params
    })
  }

  componentDidMount = () => {
    const {
      pageType,
      match,
    } = this.props

    const { params } = match

    this.props.onLoadPage(pageType, params, this.loadPage)
  }

  componentDidUpdate = (prevProps) => {
    const { loaded } = this.state

    const {
      listPageConfig,
      listPageReload,
      pageType,
      pageTypes,
      match,
      reloadPageTemplate,
      requestPagination = {}
    } = this.props

    const { params } = match

    const {
      listPageReload: prevListPageReload,
      requestPagination: prevRequestPagination = {},
      listPageConfig: prevListPageConfig,
      reloadPageTemplate: prevReloadPageTemplate,
      match: prevMatch
    } = prevProps

    const {
      titleBlock,
      listPagePageType
    } = (listPageConfig || {})

    if (!_.isEqual(listPageConfig, prevListPageConfig) && listPageConfig && (!loaded || listPagePageType !== pageType) && titleBlock) {
      this.setState({
        loaded: true
      }, () => {
        return this.props.onChangeTitleBlock(titleBlock)
      })
    }

    if (!prevListPageReload && listPageReload) {
      this.props.onListPageGet(pageType, params)

      this.props.onListPagesGet(pageType, pageTypes, params)
    }

    if (!prevReloadPageTemplate && reloadPageTemplate) {
      this.props.onListPageGet(pageType, params)
    }

    if (requestPagination.page !== prevRequestPagination.page && requestPagination.page < requestPagination.pageCount) {
      this.props.onListPagesGet(pageType, pageTypes, {
        ...params,
        page: requestPagination.page + 1
      }, true, false)
    }

    if (prevMatch.url && prevMatch.url !== match.url) {
      this.props.onListPageGet(pageType, params)

      this.props.onListPagesGet(pageType, pageTypes, params)
    }
  }

  onSelectElement = (selectedElements) => {
    const newSelectedElements = {}

    Object.entries(selectedElements).forEach(([key, value]) => {
      if (value) {
        newSelectedElements[key] = value
      }
    })

    this.setState({
      selectedElements: newSelectedElements
    })
  }

  onUpdateActiveElement = (name, value) => {
    const newActiveElements = {}

    newActiveElements[name] = value

    this.setState({
      activeElements: newActiveElements
    })
  }

  onClickAction = (action, itemId, confirmed = false, item) => {
    const { pageType, match } = this.props

    if (action.confirm && !confirmed) {
      return this.props.onHandleOpen(action.confirm, this.onClickAction, [
        action,
        itemId,
        true,
        item
      ])
    }

    switch (action.action) {
      case 'clone':
        return this.props.onListPageClone(pageType, itemId)
      case 'delete':
        return this.props.onListPageDelete(pageType, itemId, match.params, action.reloadPageTemplate)
      case 'print':
        const params = {id: itemId}

        if (action.idName) {
          delete params.id

          params[action.idName] = itemId
        }

        if (action.paramsFromItem) {
          Object.entries(action.paramsFromItem).forEach(([key, value]) => {
            params[key] = item[value]
          })
        }

        return this.props.onPrintPost(action.url, params)
      default:
        return this.props.onListPageDefaultAction(action, pageType, itemId, match.params, item)
    }
  }

  render() {
    const {
      classes,
      listPageConfig,
      listPages,
      getFilteredItems,
      pageType,
      pageTypes,
      requestPagination
    } = this.props

    if (!listPageConfig || pageType !== listPageConfig.pageType) {
      return ''
    }

    let { selectedElements, activeElements } = this.state

    const {
      tableHead,
      tableHeadActions,
      labels,
      tableActions,
      caption
    } = listPageConfig

    const errorMessage = {
      message: 'Seleziona almeno un\'elemento',
      type: 'warning',
      autoHide: true
    }

    return (
      <div className={classes.mBottom5}>
        {listPageConfig.topActions ? (
          <PageActions actions={listPageConfig.topActions} isTop={true} onUpdateActiveElement={this.onUpdateActiveElement} actionsAsTab={listPageConfig.topActionsAsTab} activeElements={_.cloneDeep(activeElements)} selectedElements={_.cloneDeep(selectedElements)} {...{pageType, pageTypes}} errorMessage={errorMessage} fromAjaxPage={false} />
        ) : ''}
        {listPages ? (
          <Paper className={classes.root}>
            <TableComponent requestPagination={requestPagination} tableActions={tableActions} tableHead={tableHead} tableHeadActions={tableHeadActions} labels={labels} tableBody={listPages} setFilteredItems={getFilteredItems} pageType={pageType} onSelectElement={this.onSelectElement} onClickAction={this.onClickAction} activeBulk={listPageConfig.activeBulk} />
          </Paper>
        ) : null}
        {caption ? (
          <Typography className={classes.caption} variant="caption" gutterBottom>
            <Icon className={classes.captionIcon}>info-circle</Icon>
            <span dangerouslySetInnerHTML={{__html: caption}}/>
          </Typography>
        ) : null}
        {listPageConfig.bottomActions ? (
          <PageActions actions={listPageConfig.bottomActions} {...{pageType, pageTypes}} selectedElements={_.cloneDeep(selectedElements)} errorMessage={errorMessage} />
        ) : ''}
      </div>
    )
  }
}

ListPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.appReducer,
  ...state.listPageReducer,
  ...state.confirmReducer,
  ...state.paginationConfigReducer
})

const mapDispatchToProps = dispatch => ({
  onLoadPage: (pageType, params, callback) => {
    dispatch(listPageActions.loadPage(pageType, params, callback))
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onListPageGet: (pageType, params) => {
    dispatch(listPageActions.listPageGet(pageType, params))
  },
  onListPageDefaultAction: (action, pageType, itemId, params, item) => {
    dispatch(listPageActions.listPageDefaultActionPost(action, pageType, itemId, params, item))
  },
  onListPageDelete: (pageType, itemId, params, reloadPageTemplate) => {
    dispatch(listPageActions.listPageDeletePost(pageType, itemId, params, reloadPageTemplate))
  },
  onListPageClone: (pageType, itemId) => {
    dispatch(listPageActions.listPageClone(pageType, itemId))
  },
  onListPagesGet: (pageType, pageTypes, params, append, activeLoading = true) => {
    dispatch(listPageActions.listPagesGet(pageType, pageTypes, params, true, append, activeLoading))
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ListPage)))
