import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TableTextFilter from './TableTextFilter'
import TableSelectFilter from './TableSelectFilter'
import { getPaginationConfig } from '../../utils/pagination'
import {} from '../../actions'

const filters = {
  text: TableTextFilter,
  select: TableSelectFilter
}

const styles = theme => ({})

class TableFilter extends React.Component {
  render() {
    const {
      element,
      onChange,
      paginationConfig: propsPaginationConfig,
      filters: propsFilters
    } = this.props

    const paginationConfig = getPaginationConfig(propsPaginationConfig)

    if (!filters[element.filter.type]) {
      return ''
    }

    if ((paginationConfig.filters || {})[element.field]) {
      element.value = paginationConfig.filters[element.field].value
    }

    const NewFilter = filters[element.filter.type]

    return (
      <NewFilter filters={propsFilters} element={element} onChange={onChange} />
    )
  }
}

TableFilter.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.listPageReducer,
  ...state.paginationConfigReducer
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableFilter)))
