import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../actions'

const styles = theme => ({
  root: {
    fontWeight: 700,
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    padding: '1rem 0',
    flexGrow: 1,
    position: 'relative',
    zIndex: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -12,
      right: -12,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.grey[200],
      borderTop: '1px solid ' + theme.palette.grey[400],
      boxShadow: theme.shadows[1],
      zIndex: -1
    }
  },
  titleTopSpace: {
    marginTop: '2rem',
  },
  collapse: {
    cursor: 'pointer',
    '&:after': {
      content: '"^"',
      position: 'absolute',
      left: 'auto',
      top: 16,
      right: 20,
      bottom: 0,
      width: 16,
      height: 22,
      fontSize: 24,
      transition: 'all .2s ease-in-out',
      transform: 'var(--rotation, rotate(0deg))'
    }
  }
})

class Title extends React.Component {
  state = {
    selected: false,
    ref: React.createRef()
  }

  toggleCollapse = (field) => {
    if (field.collapse) {
      let startingPoint = this.state.ref.current.parentNode.parentNode

      const endingPoint = document.getElementById(field.collapse.endId).parentNode.parentNode

      while (startingPoint.nextElementSibling && startingPoint.nextElementSibling !== endingPoint) {
        if (startingPoint.nextElementSibling.style.display === 'none') {
          startingPoint.nextElementSibling.style.display = 'flex'

          this.state.ref.current.style.setProperty('--rotation', 'rotate(0deg)')
        } else {
          startingPoint.nextElementSibling.style.display = 'none'

          this.state.ref.current.style.setProperty('--rotation', 'rotate(180deg)')
        }

        startingPoint = startingPoint.nextElementSibling
      }
    }
  }

  render() {
    const { classes, field, fieldIndex } = this.props

    return (
      <div
        ref={this.state.ref}
        onClick={() => this.toggleCollapse(field)}
        className={classes.root + (fieldIndex && !field.firstItem ? ' ' + classes.titleTopSpace : '') + (field.collapse ? ' ' + classes.collapse : '')}>{field.label}</div>
    )
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Title)))
