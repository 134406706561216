/* eslint-disable no-unused-vars */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ModalComponent from '../../components/ModalComponent'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import Avatar from '@material-ui/core/Avatar'
import avatar from '../../assets/img/assistant.svg'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { ajaxCall } from '../../utils/index'
import store from '../../stores'
import moment from 'moment'

const styles = theme => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
  },
  '@keyframes shine': {
    '0%': {
      left: '-100px',
    },
    '20%, 100%': {
      left: '100%',
    }
  },
  chatbotButton: {
    position: 'fixed',
    fontSize: '14px',
    bottom: '-10px', 
    left: '50%', 
    transform: 'translateX(-50%)',
    overflow: 'hidden',
    background: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 150%)', 
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: theme.spacing.unit * 1,
    cursor: 'pointer',
    zIndex: 1110,
    paddingTop: '4px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100px',
      height: '100%',
      backgroundImage: 'linear-gradient(120deg, rgba(255,255,255, 0) 30%, rgba(255,255,255, .8), rgba(255,255,255, 0) 70%)',
      top: 0,
      left: '-100px',
      animation: 'shine 4s infinite linear',
      animationIterationCount: '5'
    }
  },
  lineAboveText: {
    width: '50%', 
    height: '5px', 
    backgroundColor: 'rgba(255,255,255, 0.3)',
    borderRadius: '20px',
  },
  textAndLineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBelowLine: {
    paddingBottom: '10px',
    paddingTop: '3px',
    fontSize: '16px'
  },
  chatbotButtonHover: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
  },
  avatar: {
    width: theme.spacing.unit * 7,
    height: theme.spacing.unit * 7
  },
  chatbotButtonHoverText: {
    display: 'flex',
    gap: '3px',
    flexDirection: 'column',
    fontSize: '20px',
    whiteSpace: 'nowrap',
    padding: '0 0 0 10px',
  },
  expandMoreIconContainer: {
    position: 'absolute',
    top: '-10px'
  },
  expandMoreIcon: {
    fontSize: '50px',
    animation: 'iconSlide 1.5s ease-in-out infinite',
    color: 'rgba(255,255,255, 0.3)'
  },
  '@keyframes iconSlide': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-8px)',
    },
  },
  modalContainer: {
    position: 'absolute',
    left: '50%',
    bottom: '100px',
    transform: 'translate(-50%)',    
    width: 550,
    height: 650,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    overflow: 'hidden',
    zIndex: 1040,
    borderRadius: theme.spacing.unit * 2,
    overflowY: 'auto', 
    '&:focus-visible': {
      outline: 'none',
    }
  },
  modalHeader: {
    padding: theme.spacing.unit * 2,
    background: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 120%)',
    color: 'white',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  modalHeaderContent: {
    display: 'flex',
    alignItems: 'center', 
    padding: theme.spacing.unit * 2,
    justifyContent: 'space-between'
  },
  modalHeaderText: {
    display: 'flex',
    gap: '2px',
    flexDirection: 'column',
    fontSize: '20px',
    whiteSpace: 'nowrap',
    paddingLeft: '15px'
  },
  closeIcon: {
    fontSize: '30px',
    width: '2.5em',
    cursor: 'pointer'
  },
  modalChatSection: {
    flex: 1,
    overflowY: 'scroll',
    padding: theme.spacing.unit * 2,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none'
  },
  modalFrequentlyAsked: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  modalFrequentlyAskedTitle: {
    fontSize: '22px'
  },
  modalFrequentlyAskedButton: {
    position: 'relative',
    fontSize: '14px',
    display: 'inline-block',
    backgroundColor: 'white',
    boxShadow: 'none',
    color: '#0069c0',
    borderRadius: '25px',
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '25px',
      padding: '1px',
      background: 'linear-gradient(120deg, rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
      maskComposite: 'exclude',
      '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      'webkit-mask-composite': 'xor'
    },
    '&:hover': {
      background: 'linear-gradient(120deg, rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
      color: 'white',
      borderRadius: '25px',
    }
  },
  modalMessageRow: {
    display: 'flex',
    marginBottom: theme.spacing.unit * 1.5
  },
  chatbotMessageRow: {
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse'
  },
  chatbotMessageBubble: {
    color: 'black', 
    backgroundColor: '#eae8e8', 
    marginLeft: '10px',
    marginRight: 'auto',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      left: '0',
      top: '70%',
      width: '0',
      height: '0',
      border: '15px solid transparent',
      borderRightColor: '#eae8e8',
      borderLeft: 0,
      borderTop: 0,
      marginTop: '-11.5px',
      marginLeft: '-14px'
    }
  },
  messageBubble: {
    paddingRight: theme.spacing.unit * 1.5,
    paddingLeft: theme.spacing.unit * 1.5,
    borderRadius: '15px',
    boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2)',
    maxWidth: '80%',
    wordBreak: 'break-word'
  },
  avatarFinishedTyping: {
    position: 'absolute',
    bottom: '25%',
    left: theme.spacing.unit * -1
  },
  modalMessageRowFinishedTyping: {
    position: 'relative',
    paddingLeft: '41px'
  },
  userMessageBubble: {
    background: 'linear-gradient(120deg,  rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
    fontWeight: 500,
    color: 'white', 
    marginLeft: 'auto',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      right: '-14px',
      transform: 'rotate(270deg)',
      top: '70%',
      width: '0',
      height: '0',
      border: '15px solid transparent',
      borderRightColor: '#2b9dc1',
      borderLeft: 0,
      borderTop: 0,
      marginTop: '-11.5px',
      marginLeft: '-14px'
    }
  },
  modalMessageAvatar: {
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5,
    order: 2,
    marginRight: '11px'
  },
  modalMessageText: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: '10px 0',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  '@keyframes typing': {
    '0%': { transform: 'scale(0)' },
    '40%': { transform: 'scale(1)' },
    '80%': { transform: 'scale(0)' },
    '100%': { transform: 'scale(0)' },
  },
  centeredBubbleDots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalMessageInputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.unit * 2
  },
  modalMessageInput: {
    flex: 1,
    marginRight: theme.spacing.unit * 1
  },
  tooltipText: {
    marginLeft: '20px'
  },
  modalSupportButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    padding: '16px'
  },
  isBotTyping: {
    fontStyle: 'italic'
  },
  botName: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  modalPowered: {
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  medialisLink: {
    color: '#ff0000d9',
    textDecoration: 'none',
    paddingLeft: '5px',
    '&:hover': {
      color: '#ff0000',
      textDecoration: 'underline',
    }
  },
  privacyLink: {
    color: '#757575d9',
    textDecoration: 'none',
    '&:hover': {
      color: '#757575',
      textDecoration: 'underline',
    }
  },
  messageTimestampBot: {
    fontSize: '0.75rem',
    color: '#616161',
    bottom: '5px',
    right: '10px',
    position: 'absolute'
  },
  messageTimestampUser: {
    fontSize: '0.75rem',
    color: '#ffffff',
    position: 'absolute',
    bottom: '5px',
    right: '10px'
  }, 
  modalMessageContainer: {
    minWidth: '55px',
    paddingRight: '35px'
  },
  sendButton: {
    color: 'rgb(30 123 198)'
  },
  footerSeparator: {
    padding: '0px 5px'
  },
  sourcesContainer: {
    paddingLeft: '20px',
    marginTop: '10px'
  },
  sourceLink: {
    color: '#0066cc',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  disclaimerText: {
    fontSize: '0.75rem',
    color: '#616161',
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '10px'
  }
})

const TypingIndicator = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '0 10px', }}>
    <div className='typing-dot' style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#999', marginRight: '4px', animation: 'typing 1.4s infinite both' }}></div>
    <div className='typing-dot' style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#999', marginRight: '4px', animation: 'typing 1.4s 0.2s infinite both' }}></div>
    <div className='typing-dot' style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#999', animation: 'typing 1.4s 0.4s infinite both' }}></div>
  </div>
)

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(22),
    display: 'flex',
    alignItems: 'center'
  },
}))(Tooltip)

class Chatbot extends React.Component {
inputRef = React.createRef()

chatMessagesEndRef = React.createRef()

lastMessageRef = React.createRef()

constructor(props) {
  super(props)

  this.state = {
    isModalOpen: false,
    isHovered: false,
    showTooltip: false,
    message: '',
    messages: [],
    dimensions: {
      width: '200px',
      height: '50px',
    },
    canSend: true,
    predefinedQuestions: [
      'Perché la mia firma non appare nei giudizi?',
      'Perché compaiono accertamenti non eseguiti?',
      'Perché non riesco a fatturare a un\'azienda specifica?',
      'Perché appare la vecchia mansione?',
      'Perché la firma del medico coordinato manca?'
    ],
    showSupportButtons: false,
    isModalTransitioning: false
  }
}

componentDidMount() {
  this.tooltipTimeout = setTimeout(() => {
    const { isModalOpen } = this.state

    if (!isModalOpen) {
      this.setState({ showTooltip: true })

      this.hideTooltipTimeout = setTimeout(() => {
        this.setState({ showTooltip: false })
      }, 4000)
    }
  }, 4000)
}

componentWillUnmount() {
  clearTimeout(this.tooltipTimeout)

  clearTimeout(this.hideTooltipTimeout)
}

handleInputChange = (event) => {
  this.setState({ message: event.target.value })
}

handleMouseEnter = () => {
  this.setState({ 
    isHovered: true,
    dimensions: {
      width: '450px',
      height: '100px', 
    },
  })
}

handleMouseLeave = () => {
  this.setState({ 
    isHovered: false,
    dimensions: {
      width: '200px',
      height: '50px',
    },
  })
}

sendPredefinedQuestion = (question) => {
  this.sendMessage(question)
}

sendMessage = (predefinedQuestions = null) => {
  const messageToSend = (predefinedQuestions || this.state.message || '').trim()

  if (!messageToSend || !this.state.canSend) return

  const timestamp = new Date()

  this.setState((prevState) => ({
    canSend: false,
    messages: [...prevState.messages, { type: 'user', text: messageToSend, timestamp }],
    message: '',
    isBotThinking: true,
  }))

  const { appConfig } = store.getState().appReducer

  const data = {
    query: messageToSend
  }

  ajaxCall({
    config: {
      url: appConfig.actions.chatbot.chatbotPost.path,
      method: 'post',
      data
    },
    success: (res) => {
      const addMessage = (message, sources = [], showSupportButtons) => {
        let newMessage = {
          type: 'bot',
          text: message,
          sources,
          showSupportButtons,
          timestamp: new Date()
        }
  
        this.setState(prevState => ({
          messages: [...prevState.messages, newMessage],
          isBotThinking: false,
          canSend: true
        }))
      }

      if (res.data.error) {
        setTimeout(() => {
          addMessage(res.data.error)
        }, 1500)
      } else {
        addMessage(res.data.response, res.data.sources, res.data.showSupportButtons)
      }
    },
    error: (error) => {
      console.error('Error:', error)

      this.setState({ isBotThinking: false, canSend: true })
    },
    activeLoading: false 
  })
}

toggleModal = () => {
  if (this.state.isModalTransitioning) return

  this.setState({isModalTransitioning: true})

  this.setState(prevState => ({
    isModalOpen: !prevState.isModalOpen,
    showTooltip: false,
  }), () => {
    setTimeout(() => {
      this.setState({isModalTransitioning: false})
    }, 500)

    if (this.state.isModalOpen) {
      setTimeout(() => {
        this.scrollToBottom()
      }, 0)
    }
  })
}

componentDidUpdate(prevProps, prevState) {
  if ((prevState.isModalOpen !== this.state.isModalOpen && this.state.isModalOpen) ||
      (prevState.canSend === false && this.state.canSend === true)) {
    this.focusInput()
  }

  if (prevState.messages.length !== this.state.messages.length || 
      (prevState.isModalOpen !== this.state.isModalOpen && this.state.isModalOpen)) {
    this.scrollToBottom()
  }
}

focusInput() {
  if (this.inputRef.current) {
    this.inputRef.current.focus()
  }
}

scrollToBottom = () => {
  if (this.lastMessageRef.current) {
    this.lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

renderSources = (sources, showSupportButtons) => {
  const { classes } = this.props

  if (!sources || sources.length === 0 || showSupportButtons) return null

  return (
    <ol className={classes.sourcesContainer}>
      {sources.map((source, index) => (
        <li key={index}>
          <a href={source.url} target="_blank" rel="noopener noreferrer" className={classes.sourceLink}>
            {source.question}
          </a>
        </li>
      ))}
    </ol>
  )
}

render() {
  const { classes } = this.props

  const { isModalOpen, message, messages, isBotThinking, isHovered, dimensions, showTooltip, canSend, predefinedQuestions } = this.state
  
  const { appConfig } = store.getState().appReducer

  const dynamicStyles = {
    ...dimensions,
    transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out',
    boxShadow: '0px 0px 5px 0px grey'
  }

  return (
    <div className={classes.root}>
      <HtmlTooltip open={showTooltip} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}
        title={
          <React.Fragment>
            <Avatar alt='Assistente virtuale' src={avatar} className={classes.avatar} />
            <div className={classes.tooltipText}>
              {'Ciao! Sono l\'Assistente digitale aiRis, come posso aiutarti?'}
            </div>
          </React.Fragment>
        }

      >
        <div
          className={`${classes.chatbotButton}`}
          onClick={this.toggleModal}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={dynamicStyles}
        >
          {isHovered ? (
            <>
              <div className={classes.flexContainer}>     
                <div className={classes.expandMoreIconContainer}>
                  <ExpandLessIcon className={classes.expandMoreIcon}>{}</ExpandLessIcon>
                </div>

                <div className={classes.chatbotButtonHover}>
                  <Avatar alt="Assistente virtuale" src={avatar} className={classes.avatar} />

                  <div className={`${classes.chatbotButtonHoverText}`}>
                    <div className={classes.botName}>Consulta aiRis</div>
                    <div>L'Assistente digitale di Achille</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.textAndLineContainer}>
              <div className={classes.lineAboveText}></div>
              <div className={classes.textBelowLine}>Assistente Virtuale</div>
            </div>
          )}
        </div>
      </HtmlTooltip>
      <Modal open={isModalOpen} onClose={this.toggleModal}>
        <Fade in={isModalOpen} timeout={500}>
          <Paper elevation={3} className={classes.modalContainer}>
            <div className={classes.modalHeader}>
              <div className={classes.modalHeaderContent}>
                <Avatar alt='Assistente virtuale' src={avatar} className={classes.avatar} />

                <div className={classes.modalHeaderText}>
                  <div className={classes.botName}>
                    Consulta aiRis (Beta)
                  </div>

                  {isBotThinking ? (   
                    <div className={classes.isBotTyping}>Sta scrivendo...</div>
                  ) : ( 
                    <div>L'Assistente digitale di Achille</div>
                  )}
                </div>
              </div>

              <CloseIcon className={classes.closeIcon} onClick={this.toggleModal}>{}</CloseIcon>
            </div>

            <div ref={this.chatMessagesEndRef} className={classes.modalChatSection}>
              {messages.length === 0 && (
                <div className={classes.modalFrequentlyAsked}>
                  <p className={classes.modalFrequentlyAskedTitle}>Esplora le domande più frequenti:</p>

                  {predefinedQuestions.map((question, index) => (
                    <Button className={classes.modalFrequentlyAskedButton} key={index} variant="contained" color="primary" onClick={() => this.sendPredefinedQuestion(question)} style={{ margin: 8 }}>
                      {question}
                    </Button>
                  ))}
                </div>
              )}

              {messages.map((msg, index) => (
                <React.Fragment key={index}>
                  <div className={`${classes.modalMessageRow} ${msg.type === 'bot' ? classes.modalMessageRowFinishedTyping : ''}`} ref={index === messages.length - 1 ? this.lastMessageRef : null}>
                    {msg.type === 'bot' && (
                      <Avatar alt='Assistente virtuale' src={avatar} className={classes.avatarFinishedTyping} />
                    )}

                    <Paper elevation={3} className={`${classes.messageBubble} ${msg.type === 'bot' ? classes.chatbotMessageBubble : classes.userMessageBubble}`}>
                      <div className={classes.modalMessageText}>
                        <div className={classes.modalMessageContainer}>
                          {msg.text}
                        </div>
                        <div className={msg.type === 'bot' ? classes.messageTimestampBot : classes.messageTimestampUser}>
                          {moment(msg.timestamp).format('HH:mm')}
                        </div>
                      </div>
                    </Paper>
                  </div>

                  {msg.type === 'bot' && msg.sources && msg.sources.length > 0 && !msg.showSupportButtons && (
                    <div className={`${classes.modalMessageRow} ${classes.modalMessageRowFinishedTyping}`}>
                      <Avatar alt='Assistente virtuale' src={avatar} className={classes.avatarFinishedTyping} />
                      <Paper elevation={3} className={`${classes.messageBubble} ${classes.chatbotMessageBubble}`}>
                        <div className={classes.modalMessageText}>
                          <div className={classes.modalMessageContainer}>
                            Per ulteriori dettagli, consulta i seguenti link:
                            {this.renderSources(msg.sources, msg.showSupportButtons)}
                          </div>
                        </div>
                      </Paper>
                    </div>
                  )}

                  {msg.showSupportButtons && (
                    <div className={classes.modalSupportButtons}>
                      <Button
                        variant="contained"
                        className={classes.modalFrequentlyAskedButton}
                        onClick={() => window.open(appConfig.userConfig.faq_url, '_blank')}
                      >
                        Vai alle FAQ
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.modalFrequentlyAskedButton}
                        onClick={() => window.open(appConfig.userConfig.assistance_url, '_blank')}
                      >
                        Contatta via Whatsapp
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              ))}

              {isBotThinking && (
                <div className={`${classes.modalMessageRow} ${classes.chatbotMessageRow}`}>
                  <Avatar alt='Assistente virtuale' src={avatar} className={classes.modalMessageAvatar} />

                  <Paper elevation={3} className={`${classes.messageBubble} ${classes.chatbotMessageBubble} ${classes.centeredBubbleDots}`}>
                    <TypingIndicator />
                  </Paper>
                </div>
              )}     

              <div ref={this.chatMessagesEndRef} />
            </div>

            <div className={classes.modalMessageInputContainer}>
              <TextField
                className={classes.modalMessageInput}
                value={message}
                disabled={!canSend}
                autoFocus={true}
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault()
                    this.sendMessage()
                  }
                }}
                inputRef={this.inputRef}
                placeholder='Scrivi un messaggio...'
              />

              <IconButton disabled={!canSend} className={classes.sendButton} onClick={() => {
                this.sendMessage()
              }}>
                <SendIcon />
              </IconButton>
            </div>

            <div className={classes.modalPowered}>
              Powered with &#9829; by 
              <a href={appConfig.userConfig.medialis_url} target="_blank" className={classes.medialisLink}
              > Medialis
              </a>
              <div className={classes.footerSeparator}>|</div>
              <a href={appConfig.userConfig.privacyInfo} target="_blank" className={classes.privacyLink}
              >Disclaimer Privacy
              </a>
            </div>
            <div className={classes.disclaimerText}>
              aiRis può commettere errori. Considera di verificare le informazioni con le faq.
            </div>
          </Paper>
        </Fade>

      </Modal>
    </div>
  )
}
}

Chatbot.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Chatbot)))