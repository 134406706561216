import _ from 'lodash'
import {
} from '../actions'

const initialState = {
  fromInit: false,
  printsStack: []
}

const getCurrentPrintIindex = (params = {}, printsStack) => {
  return printsStack.filter(item => !(!item)).findIndex(item => {
    return item.params.userUid === params.userUid && item.params.timestamp === params.timestamp
  })
}

let newState = null

const printReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'print_status':
      newState = _.cloneDeep(state)

      if (getCurrentPrintIindex(action.params, newState.printsStack) > -1) {
        newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)] = {
          ...newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)],
          status: 'progress',
          progress: action.progress
        }

        if (action.params.customPrintName) {
          newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)].labels.name = action.params.customPrintName
        }
      }

      return {
        ...newState,
        fromInit: false,
        currentPrintItem: null
      }
    case 'print_status_waiting':
      newState = _.cloneDeep(state)

      if (getCurrentPrintIindex(action.params, newState.printsStack) > -1) {
        newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)].status = 'waiting'

        if (action.params.customPrintName) {
          newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)].labels.name = action.params.customPrintName
        }
      }

      return {
        ...newState,
        fromInit: false,
        currentPrintItem: null
      }
    case 'print_cancel':
    case 'print_status_remove':
      newState = _.cloneDeep(state)

      if (getCurrentPrintIindex(action.params, newState.printsStack) > -1) {
        newState.printsStack.splice(getCurrentPrintIindex(action.params, newState.printsStack), 1)
      }

      return newState
    case 'print_status_error':
      newState = _.cloneDeep(state)

      if (getCurrentPrintIindex(action.params, newState.printsStack) > -1) {
        newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)] = {
          ...newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)],
          status: 'error',
          message: action.message
        }
      }

      return {
        ...newState,
        fromInit: false,
        currentPrintItem: null
      }
    case 'print_status_done':
      newState = _.cloneDeep(state)

      if (getCurrentPrintIindex(action.params, newState.printsStack) > -1) {
        newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)] = {
          ...newState.printsStack[getCurrentPrintIindex(action.params, newState.printsStack)],
          status: 'done',
          progress: 100,
          pdf: action.pdf
        }
      }

      return {
        ...newState,
        fromInit: false,
        currentPrintItem: null
      }
    case 'print_close':
      newState = _.cloneDeep(state)

      return {
        ...newState,
        fromInit: false,
        currentPrintItem: null
      }
    case 'print':
      newState = _.cloneDeep(state)

      if ((action.data.data || {}).labels) {
        newState.fromInit = (action.data.data || {}).fromInit

        return {
          ...newState,
          currentPrintItem: action.data.data,
          printsStack: [
            ...state.printsStack,
            action.data.data
          ]
        }
      }

      return newState
    default:
      return state
  }
}

export default printReducer
