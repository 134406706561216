import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'
import purple from '@material-ui/core/colors/purple'
import deepPurple from '@material-ui/core/colors/deepPurple'
import indigo from '@material-ui/core/colors/indigo'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { isInViewPort } from '../InViewport'
import {
  agendaActions
} from '../../actions'

const styles = theme => {
  const doctorColors = {}

  const colors = [
    blue,
    red,
    indigo,
    purple,
    deepPurple,
  ]

  const colorsCount = colors.length

  for (let i = 0; i < (7 * colorsCount); i++) {
    const count = parseInt(i / colorsCount)

    const colorCoutn = (count * 100) + 200

    let colorPalette = indigo

    for (let j = 0; j < colorsCount; j++) {
      if (!((i + j) % colorsCount)) {
        colorPalette = colors[j]
      }
    }

    const selectedColor = colorPalette[colorCoutn]

    doctorColors['calendarDayEventCompany-' + i] = {
      '&::after': {
        backgroundColor: selectedColor,
        color: theme.palette.textColor(selectedColor),
      }
    }
  }

  return {
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    notificationBadge: {
      position: 'absolute',
      zIndex: 2,
      right: '3px',
      top: '-3px',
      width: '12px',
      height: '12px',
      padding: 0,
      borderRadius: '50%'
    },
    confirmed: {
      backgroundColor: theme.palette.success.main
    },
    notConfirmed: {
      backgroundColor: theme.palette.error.main
    },
    calendarDayEvent: {
      padding: '.25rem .375rem .25rem .875rem',
      marginRight: '.5rem',
      borderRadius: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      marginBottom: '.25rem',
      position: 'relative',
      zIndex: 1,
      cursor: 'pointer',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '.5rem',
        bottom: 0,
        zIndex: -1
      },
      '&:hover': {
        boxShadow: theme.shadows[1]
      }
    },
    calendarDayEventTimetable: {
      backgroundColor: '#ffb300',
      color: theme.palette.common.black,
    },
    calendarDayEventMonitoringtimetable: {
      backgroundColor: '#854731',
      color: theme.palette.common.white,
    },
    calendarDayEventCoursetimetable: {
      backgroundColor: '#515a5e',
      color: theme.palette.common.white,
    },
    calendarDayEventInspectiontimetable: {
      backgroundColor: '#0d8ba0',
      color: theme.palette.common.white,
    },
    calendarDayEventSafetytimetable: {
      backgroundColor: '#8b52ab',
      color: theme.palette.common.white,
    },
    calendarDayEventCompany: {
      backgroundColor: '#1565c0'
    },
    calendarDayEventCompanyWorker: {
      paddingLeft: '1.5rem',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'auto',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '1.5rem',
        bottom: 0,
        zIndex: -1
      },
      '&:hover': {
        boxShadow: 'none'
      }
    },
    calendarDayEventCompanyWorkerCheckbox: {
      '& > *:first-child': {
        backgroundColor: '#fff',
        borderRadius: 3,
      }
    },
    calendarDayEvents: {
      height: '100%',
      overflowY: 'auto',
    },
    ...doctorColors,
    animation: {
      animation: 'shake-animation 0.5s 2',
    },
    '@keyframes shake-animation': {
      '0%': { transform: 'translateX(0)' },
      '25%': { transform: 'translateX(5px)' },
      '50%': { transform: 'translateX(-5px)' },
      '75%': { transform: 'translateX(5px)' },
      '100%': { transform: 'translateX(0)' }
    },
  }
}

class CalendarEvent extends React.Component {
  state = {
    selected: false,
    ref: React.createRef()
  }

  getOffset = (el) => {
    const rect = el.getBoundingClientRect()

    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    }
  }

  handleClick = () => {
    const { selectedEvent, event, eventIndex, dayIndex, weekIndex, onViewEvent } = this.props

    if (!event.date) {
      return
    }

    const currentId = event.id + '-' + event.date.replaceAll('/', '-')

    if (selectedEvent.id === currentId) {
      const calendarEvent = this.state.ref.current

      if (!isInViewPort(calendarEvent, 150)) {
        window.scrollTo(0, this.getOffset(calendarEvent).top - 200)
      }

      switch (selectedEvent.action) {
        case 'click':
          this.setState({
            selected: true
          })

          break
        case 'doubleclick':
          onViewEvent({
            ...event,
            path: [
              weekIndex,
              dayIndex,
              'events',
              eventIndex
            ]
          })

          break
        default:
          console.log('Azione ' + selectedEvent.action + ' non trovata.')
      }

      setTimeout(() => {
        this.setState({
          selected: false
        })
      }, 1000)

      this.props.onUpdateSelectedEvent(null)
    }
  }

  componentDidMount = () => {
    const { selectedEvent } = this.props

    if (selectedEvent) {
      this.handleClick()
    }
  }

  componentDidUpdate = prevProps => {
    const { selectedEvent: prevSelectedEvent } = prevProps

    const { selectedEvent } = this.props

    if (selectedEvent && (!prevSelectedEvent || selectedEvent.id !== prevSelectedEvent.id)) {
      this.handleClick()
    }
  }

  render() {
    const { classes, event, eventIndex, dayIndex, weekIndex, getDoctorIndex, onViewEvent } = this.props

    return (
      <div className={classes.wrapper}>
        {event.type === 'event' && (
          <div className={`${classes.notificationBadge} ${event.evento_confermato === 1 ? classes.confirmed : classes.notConfirmed}`} />
        )}
        <div
          className={[
            classes.calendarDayEvent,
            classes['calendarDayEvent' + _.capitalize(event.type)],
            event.company && event.type === 'event' ? classes.calendarDayEventCompany : '',
            event.isWorker ? classes.calendarDayEventCompanyWorker : '',
            classes['calendarDayEventCompany-' + getDoctorIndex(event)],
            this.state.selected ? classes.animation : ''
          ].join(' ')}
          ref={this.state.ref}
          onClick={() => {
            if (!event.isWorker) {
              return onViewEvent({
                ...event,
                path: [
                  weekIndex,
                  dayIndex,
                  'events',
                  eventIndex
                ]
              })
            }
          }}>
          {event.isWorker ? (
            <Checkbox
              checked={this.state.checked === undefined ? event.visited || false : this.state.checked}
              className={classes.checkbox}
              color='primary'
              classes={{
                root: classes.calendarDayEventCompanyWorkerCheckbox
              }}
              onChange={e => {
                const tmpChecked = e.target.checked

                this.setState({
                  checked: tmpChecked
                }, () => {
                  this.props.onUpdateItem(event.__path, [
                    {
                      key: 'visited',
                      value: tmpChecked
                    }
                  ])

                  this.props.onWorkerAgendaSave({
                    id: event.id,
                    visited: tmpChecked,
                    eventId: event.eventId
                  })
                })
              }}
            />
          ) : ''}
          {event.time && !(event.workerEvents || []).length ? (
            <small>({event.time}{event.endTime && (' - ' + event.endTime)})</small>
          ) : ''} {event.company && event.type === 'event' ? event.company.name : event.title}
        </div>
      </div>
    )
  }
}

CalendarEvent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onUpdateItem: (path, props) => {
    dispatch(agendaActions.updateItem(path, props))
  },
  onUpdateSelectedEvent: (data) => {
    dispatch(agendaActions.updateSelectedEvent(data))
  },
  onWorkerAgendaSave: (data) => {
    dispatch(agendaActions.workerAgendaSave(data))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CalendarEvent)))
