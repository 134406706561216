export const hParseFloat = (number) => {
  return parseFloat(number).toFixed(2)
}

export const hJoinString = (strings, joiner = '') => {
  return strings.join(joiner)
}

export const messages = {
  configNotFound: (configName) => {
    return 'Configurazione "' + configName + '" non trovata'
  }
}
