const initialState = {
  logData: []
}

// Vars

const logReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'log_load_data':
      return {
        ...state,
        logData: action.logData
      }
    default:
      return state
  }
}

export default logReducer
