import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import _ from 'lodash'

import {} from '../../actions'

const styles = theme => ({
  row: {
    height: 'auto',
  },
  cell: {
    padding: '1rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 700
  }
})

class TableCheckbox extends React.Component {
  state = {
    fields: {}
  }

  getCurrentField = () => {
    const { formContent, field } = this.props

    const { fields } = formContent

    const { valueFrom } = field

    const currentField = fields.filter(item => {
      return item.name === valueFrom.field
    })[0] || {value: {}}

    if (!currentField.value) {
      currentField.value = {}
    }

    return currentField
  }

  getCurrentFieldValue = () => {
    const currentField = this.getCurrentField()

    const { prop } = this.props.field.valueFrom

    const oldValue = _.cloneDeep(currentField.value)

    oldValue[prop] = this.state.fields

    return oldValue
  }

  getValue = () => {
    const { prop } = this.props.field.valueFrom

    const currentField = this.getCurrentField()

    return currentField.value[prop] || {}
  }

  componentDidMount = () => {
    const value = this.getValue()

    this.setState({
      fields: value
    })
  }

  onChange = (e, name) => {
    const { onChange, field } = this.props

    const currentState = _.cloneDeep(this.state)

    currentState.fields[name] = e.target.checked

    return this.setState(currentState, () => {
      return onChange({type: 'string'}, field, currentState.fields)
    })
  }

  render() {
    const { classes, field } = this.props

    const { header, body } = field.table

    const { fields } = this.state

    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow className={classes.row}>
            {header.map((item, index) => {
              return (
                <TableCell className={classes.cell} key={index} style={item.styles || {}}>
                  {item.label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map((item, index) => {
            return (
              <TableRow className={classes.row} key={index}>
                {item.map((cell, cellIndex) => {
                  let element = (
                    <div className={classes.label}>
                      {cell.label}
                    </div>
                  )

                  switch (cell.type) {
                    case 'checkbox':
                      element = (
                        <div className={classes.checkbox}>
                          <input type='checkbox' checked={fields[cell.name] || false} onChange={(e) => this.onChange(e, cell.name)} />
                        </div>
                      )

                      break;
                    default:
                  }

                  return (
                    <TableCell className={classes.cell} key={cellIndex}>
                      {element}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

TableCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.formReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableCheckbox)))
