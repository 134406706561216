import React from 'react'
import {
  NavLink,
  withRouter
} from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FormField from '../../components/FormField'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import MIcon from '../../components/Icon'
import CodiceFiscale from 'codice-fiscale-js'
import {
  getParsedLocation,
  handlebarsRender,
  getPrevPage,
  ajaxCall
} from '../../utils'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import List from '../../components/FormField/List'
import ModalActions from '../../components/PageActions/ModalActions'
import _ from 'lodash'
import moment from 'moment'
import { audiometricCalculator } from '../../utils/audiometric-calculator'
import { spirometricCalculator } from '../../utils/spirometric-calculator'
import Prompt from '../../components/Prompt'

import {
  appActions,
  formActions,
  confirmActions,
  messageActions,
  printActions
} from '../../actions'
import { getPagination } from '../../utils/pagination'

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: '1rem'
  },
  rootActions: {
    paddingBottom: '1rem'
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap'
  },
  gridRoot: {
    margin: 0
  },
  gridCol: {
    padding: '0 1rem'
  },
  divider: {
    padding: '1rem',
  },
  dividerContainer: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verticalDivider: {
    width: 1,
    backgroundColor: theme.palette.grey[300],
    height: 40
  },
  textRight: {
    textAlign: 'right'
  },
  successButton: {
    backgroundColor: theme.palette.success[100],
    color: '#fff',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.success[200],
    }
  },
  successButtonNew: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  paddingBottom: {
    paddingBottom: '1rem'
  },
  actions: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  actionRoot: {
    margin: '0 .25rem 1em',
  },
  action: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    padding: 0,
    lineHeight: '1rem',
    fontSize: '.75rem'
  },
  actionIcon: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: '.75rem'
  },
  actionLabel: {
    textAlign: 'center'
  },
  gridGroup: {
    maxHeight: 490,
    minHeight: 490,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: -15,
    paddingTop: 15,
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  hideBlock: {
    display: 'none',
  },
  tabsAction: {
    padding: '0 .5rem',
    position: 'fixed',
    right: 0,
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    left: theme.appVars.sidebarWidth.main,
    paddingLeft: 34,
    paddingTop: 19,
    top: theme.appVars.breadcrumbsHeight + theme.appVars.navBarHeight,
    transition: 'left .3s ease-in-out',
  },
  tabsActionSidebarIsOpen: {
    left: theme.appVars.sidebarWidth.open + 17,
  },
  tabAction: {
    minWidth: 0,
    marginTop: '.5rem'
  },
  tabActionLabel: {
    padding: '0 12px'
  },
  tabActionActive: {
    boxShadow: theme.shadows[1]
  },
  dividerLine: {
    height: 2
  },
  actionsAsTabs: {
    paddingTop: theme.appVars.breadcrumbsHeight
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  paginationNumber: {
    minWidth: 40
  }
})

class FormEdit extends React.Component {
  _isMounted = false

  state = {
    timeout: null,
    changed: false,
    periodFrom: moment().subtract(1, 'years').format(window.appConfig.dateFormat),
    periodTo: moment().format(window.appConfig.dateFormat),
    itemsPerPage: 10
  }

  componentDidMount = () => {
    const { match, formType } = this.props

    this._isMounted = true

    this.setState({
      groupFilter: {}
    })

    this.props.onFormGet(formType, match.params)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { formContent, exitForm, match, formType, history, appConfig, newId, goTo, redirect } = this.props

    if (redirect) {
      return history.replace(appConfig.appPrefix + redirect)
    }

    const { timeout: prevTimeout } = prevState

    const { timeout, first = true } = this.state

    const { exitForm: prevExitForm, match: prevMatch } = prevProps

    const { title, temporarySave } = formContent || {}

    const { title: prevTitle } = prevProps.formContent || {}

    if (title && title !== prevTitle) {
      const titleBlock = {
        title: title,
        titleAction: formContent.titleAction,
        breadcrumbs: formContent.breadcrumbs
      }

      this.props.onChangeTitleBlock(titleBlock)
    }

    if (prevExitForm !== exitForm && exitForm) {
      if (temporarySave) {
        return history.push(appConfig.appPrefix + temporarySave.action)
      }

      const pattern = new RegExp('(/edit/[0-9]+|/clone/[0-9]+|/copy/[0-9]+|/create)$')

      if (goTo || (formContent && formContent.goTo)) {
        return history.push(appConfig.appPrefix + (goTo || formContent.goTo))
      }

      if (pattern.test(match.url) && newId) {
        return history.push(match.url.replace(pattern, '/edit/' + newId))
      }

      this.props.onFormGet(formType, match.params)
    }

    if (prevMatch.url && prevMatch.url !== match.url) {
      this.props.onFormGet(formType, match.params)
    }

    if (!prevTimeout && !timeout && this._isMounted) {
      const _self = this

      this.setState({
        first: first,
        timeout: setTimeout(() => {
          this.props.onFormCalcValue()

          this.props.onFormCalcValue(true)

          if (_self._isMounted) {
            const newState = {
              timeout: null
            }

            if (_self.state.first && _self.state.changed) {
              newState.first = false

              newState.changed = false
            }

            _self.setState(newState)
          }
        }, 500)
      })
    }
  }

  onHandleChangeAddress = (action, field, fieldIndex, value) => {
    let fieldValues = field.values ? _.cloneDeep(field.values) : []

    switch (action.type) {
      case 'add':
        fieldValues.push(value)
        break
      case 'update':
        switch (action.property) {
          default:
            if (action.index || action.index === 0) {
              fieldValues[action.index][action.property].value = value
            } else {
              fieldValues = _.cloneDeep(field.value)

              if (action.property) {
                fieldValues[action.property].value = value
              } else {
                fieldValues = value
              }
            }
        }

        break
      case 'remove':
        fieldValues.splice(action.index, 1)

        break
      default:
    }

    this.props.onFormAddValue(fieldValues, fieldIndex)

    this.props.onCommitChanges()
  }

  updateRelated = (value, fieldIndex, action, updateRelated) => {
    const { formContent, appConfig, match } = this.props

    const { inLabel, directChange } = action

    const { fields, checkboxFields } = formContent

    let field = fields[fieldIndex]

    if (Number.isInteger(action.index) && Number.isInteger(action.fieldIndex)) {
      field = {
        ...fields[fieldIndex].fields[action.fieldIndex],
        value: fields[fieldIndex].values[action.index][fields[fieldIndex].fields[action.fieldIndex].name]
      }
    }

    if (!field) {
      return true
    }

    Object.entries(updateRelated).forEach(([type, relateds]) => {
      switch (type) {
        case 'diffDates':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            if (related.onDirectChange && !directChange) {
              return false
            }

            const filteredFields = {}

            fields.forEach((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              if (tmpFilteredField.name === related.fieldName) {
                filteredFields.to = tmpFilteredField
              }

              if (tmpFilteredField.name === related.toFieldDate) {
                filteredFields.toDate = tmpFilteredField
              }

              if (tmpFilteredField.name === related.fromFieldDate) {
                filteredFields.fromDate = tmpFilteredField
              }
            })

            let fromDate = moment()

            if (related.fromDate) {
              fromDate = moment(related.fromDate)
            } else if (related.fromFieldDate) {
              fromDate = moment(filteredFields.fromDate.value, appConfig.dateFormat)
            }

            let toDate = moment()

            if (related.toDate) {
              toDate = moment(related.toDate)
            } else if (related.toFieldDate) {
              toDate = moment(filteredFields.toDate.value, appConfig.dateFormat)
            }

            if (filteredFields.to) {
              const tmpValue = moment(fromDate)

              this.props.onFormAddValue(tmpValue.diff(toDate, related.type), filteredFields.to.index)
            }
          })
        case 'addToDate':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredFields = {
              amount: related.amount,
            }

            fields.forEach((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              if (tmpFilteredField.name === related.fieldName) {
                filteredFields.to = tmpFilteredField
              }

              if (related.amountFieldName && tmpFilteredField.name === related.amountFieldName) {
                filteredFields.amount = tmpFilteredField
              }

              if (related.fromFieldDate && tmpFilteredField.name === related.fromFieldDate) {
                filteredFields.fromDate = tmpFilteredField
              }
            })

            let amount = 0

            if (related.amount) {
              amount = related.amount
            } else if (related.amountFieldName) {
              amount = filteredFields.amount.value
            }

            let fromDate = moment()

            if (related.fromDate) {
              fromDate = moment(related.fromDate)
            } else if (related.fromFieldDate) {
              fromDate = moment(filteredFields.fromDate.value, appConfig.dateFormat)
            }

            if (filteredFields.to) {
              fromDate.add(amount, related.type)

              this.props.onFormAddValue(fromDate.format(appConfig.dateFormat), filteredFields.to.index)
            }
          })
        case 'subtractFromDate':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredFields = {}

            fields.forEach((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              if (tmpFilteredField.name === related.fieldName) {
                filteredFields.to = tmpFilteredField
              }

              if (tmpFilteredField.name === related.amountFieldName) {
                filteredFields.amount = tmpFilteredField
              }
            })

            let amount = 0

            if (related.amount) {
              amount = related.amount
            } else if (related.amountFieldName) {
              amount = filteredFields.amount.value
            }

            if (filteredFields.to) {
              const tmpValue = moment(related.fromDate)

              tmpValue.subtract(amount, related.type)

              this.props.onFormAddValue(tmpValue.format(appConfig.dateFormat), filteredFields.to.index)
            }
          })
        case 'addToNumber':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredField = fields.filter((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })[0] || null

            if (filteredField) {
              const tmpValue = parseFloat(filteredField.value) || 0

              if (related.amount) {
                this.props.onFormAddValue(tmpValue + related.amount, filteredField.index)
              }
            }
          })
        case 'subtractFromNumber':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredField = fields.filter((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })[0] || null

            if (filteredField) {
              let tmpValue = parseFloat(filteredField.value) || 0

              tmpValue = tmpValue - related.amount

              tmpValue = tmpValue >= 0 ? tmpValue : ''

              if (related.amount) {
                this.props.onFormAddValue(tmpValue, filteredField.index)
              }
            }
          })
        case 'appendMultipleToText':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const context = {}

            Object.entries(related.fields).forEach(([key, fieldName]) => {
              const filteredField = fields.filter((tmpFilteredField, index) => {
                tmpFilteredField.index = index

                if (_.isArray(fieldName) && tmpFilteredField.name === fieldName[0]) {
                  const innerFilteredField = tmpFilteredField.fields.filter(tmpInnerFilteredField => {
                    return tmpInnerFilteredField.name === fieldName[2]
                  })[0] || {}

                  if (innerFilteredField.isMulti) {
                    return tmpFilteredField.value = tmpFilteredField.values[fieldName[1]][fieldName[2]] ? tmpFilteredField.values[fieldName[1]][fieldName[2]].map(item => {
                      return item.value
                    }).join(', ') : innerFilteredField.value
                  }

                  return tmpFilteredField.value = tmpFilteredField.values[fieldName[1]][fieldName[2]] || innerFilteredField.value
                }

                return tmpFilteredField.name === fieldName
              })[0] || {}

              context[key] = typeof filteredField.value === 'object' ? filteredField.value.label : filteredField.value
            })

            const filteredField = fields.filter((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.to.name
            })[0] || null

            if (filteredField) {
              const tmpValue = filteredField.value ? filteredField.value + (!filteredField.value.endsWith('\n') ? '\n' : '') : ''

              const obj = {
                context,
                template: related.to.pattern
              }

              if (related.toUpperCase) {
                Object.entries(obj.context).forEach(([key, value]) => {
                  if (key.toLocaleLowerCase().includes('label') && value) {
                    obj.context[key] = (value).toUpperCase()
                  }
                })
              }

              const stringValue = handlebarsRender(obj)

              this.props.onFormAddValue(tmpValue + stringValue, filteredField.index)
            }
          })
        case 'addToObjectValue':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredField = fields.filter((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })[0] || null

            const currentValue = filteredField.value || {}

            const currentValueProp = currentValue[related.prop] || {}

            currentValue[related.prop] = {
              ...currentValueProp,
              ...value
            }

            this.props.onFormAddValue(currentValue, filteredField.index)
          })
        case 'replaceValueWithValueLabels':
          return relateds.forEach(related => {
            const valueField = fields.find(tmpValueField => {
              return tmpValueField.name === related.fromValue
            })

            const filteredField = fields.find((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })

            if (filteredField) {
              const tmpValue = valueField ? (valueField.value ? valueField.value.label || valueField.value : valueField.values.map(item => {
                return item.label
              }).join(related.separator)) : ''

              this.props.onFormAddValue(tmpValue, filteredField.index)
            }
          })
        case 'changeAllValues':
          return relateds.forEach(related => {
            const valueField = fields.find(tmpValueField => {
              return tmpValueField.name === related.fromValue
            }) || { value: related.value }

            if (related.fieldName === 'checkboxFields') {
              checkboxFields.forEach(name => {
                const filteredField = fields.find((tmpFilteredField, index) => {
                  if (tmpFilteredField.name === name) {
                    tmpFilteredField.index = index
        
                    return true
                  }
        
                  return false
                })
        
                if (filteredField) {
                  this.props.onFormAddValue(valueField.value, filteredField.index, undefined, filteredField.name)
                }
              })
            } else {
              const filteredField = fields.find((tmpFilteredField, index) => {
                tmpFilteredField.index = index

                return tmpFilteredField.name === related.fieldName
              })

              if (filteredField) {
                const tmpValues = _.cloneDeep(filteredField.values) || []

                tmpValues.forEach(tmpValue => {
                  tmpValue[related.valuesFieldName] = valueField.value
                })

                this.props.onFormAddValue(tmpValues, filteredField.index)
              }
            }
          })
        case 'replaceText':
        case 'appendToText':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredField = fields.find((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              if (_.isArray(related.fieldName)) {
                if (tmpFilteredField.type !== 'dynamic') {
                  return false
                }

                return tmpFilteredField.name === related.fieldName[0]
              }

              return tmpFilteredField.name === related.fieldName
            }) || null

            if (filteredField) {
              let relatedValue = related.value || field.value

              let tmpValue = [type === 'replaceText' ? '' : (filteredField.value ? filteredField.value : '')]

              if (_.isArray(related.fieldName) && type === 'appendToText') {
                tmpValue = [filteredField.values[related.fieldName[1]][related.fieldName[2]] || '']
              }

              if (typeof relatedValue === 'object') {
                tmpValue.push(related.valueProp ? relatedValue[related.valueProp] : relatedValue.value)
              } else {
                tmpValue.push(relatedValue)
              }

              const params = [tmpValue.filter(item => {
                return !(!item)
              }).join('\n'), filteredField.index]

              if (_.isArray(related.fieldName)) {
                params.push(related.fieldName[1], related.fieldName[2])
              }

              this.props.onFormAddValue(...params)
            }
          })
        case 'replaceValuesFromAjax':
        case 'preppendROValuesFromAjax':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const tmpParams = {}

            Object.entries(related.params).forEach(([param, paramFieldName]) => {
              const filteredField = fields.filter(tmpFilteredField => {
                return tmpFilteredField.name === paramFieldName
              })[0] || { value: paramFieldName }

              let tmpValue = filteredField.values ? [] : null

              if (filteredField.values) {
                filteredField.values.forEach(filteredFieldValue => {
                  return tmpValue.push(filteredFieldValue.value || filteredFieldValue)
                })
              } else {
                tmpValue = filteredField.value ? filteredField.value.value || filteredField.value : null
              }

              tmpParams[param] = tmpValue
            })

            return this.props.onPreppendROValuesFromAjax({
              ...related,
              replaceValues: type === 'replaceValuesFromAjax',
              params: tmpParams
            })
          })
        case 'fieldProp':
        case 'fieldPropWithValue':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            let currentFieldIndex = fieldIndex
            let currentChildFieldIndex = -1

            if (related.fieldName) {
              currentFieldIndex = fields.findIndex((tmpFilteredField, index) => {
                tmpFilteredField.index = index

                return tmpFilteredField.name === related.fieldName
              })

              if (currentFieldIndex !== -1 && related.fieldChildName && related.fieldChildrenName) {
                currentChildFieldIndex = (fields[currentFieldIndex][related.fieldChildrenName] || []).findIndex((tmpFilteredField, index) => {
                  tmpFilteredField.index = index

                  return tmpFilteredField.name === related.fieldChildName
                })
              }
            }

            let tmpValue = value

            if (typeof related.value !== 'undefined') {
              tmpValue = related.value
            }

            if (value && related.fieldRelatedValue && value.forRelated) {
              tmpValue = value.forRelated[related.fieldRelatedValue]
            }

            tmpValue = tmpValue || ''

            if (value && related.relatedValue && value.forRelated) {
              tmpValue = value.forRelated[related.relatedValue]
            }

            tmpValue = !tmpValue ? '' : tmpValue

            if (related.isBoolean) {
              const toCheckValue = related.isBooleanInverted ? value ? 0 : 1 : value ? 1 : 0

              if (related.booleanValues) {
                tmpValue = related.booleanValues[toCheckValue]
              } else {
                tmpValue = !toCheckValue
              }
            }

            if (related.isBirthday) {
              tmpValue = moment().diff(moment(value, appConfig.dateFormat), 'years', false)
            }

            tmpValue = tmpValue || related.defaultValue || ''

            return this.props.onFormUpdateFieldProps(tmpValue.value || tmpValue, {
              ...related,
              index: action.index,
              fieldIndex: currentFieldIndex,
              childFieldIndex: currentChildFieldIndex,
              dynamicField: related.dynamicField
            })
          })
        case 'checkboxAsRadio':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const filteredField = fields.find((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })

            if (filteredField) {
              this.props.onFormAddValue('', filteredField.index)
            }
          })
        case 'calculateAudiometry':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const fieldIndex = fields.findIndex(item => {
              return item.name === related.fieldName
            })

            const chart = { series: [[], [], [], []] }

            const audiometricChart = fields.find(item => {
              return item.name === related.audiometricChart
            }) || {}

            if (!audiometricChart.value.series) {
              audiometricChart.value.series = [[], [], [], []]
            }

            audiometricChart.value.series.forEach((item, index) => {
              for (let i = 0; i < 12; i++) {
                if (_.isArray(item[i])) {
                  chart.series[index][i] = item[i][1]
                } else {
                  chart.series[index][i] = null
                }
              }
            })

            try {
              return this.props.onFormAddValue(audiometricCalculator(chart, related.type || 'note'), fieldIndex, null, null, related.append)
            } catch (e) {
              return this.props.onShowMessage({
                message: e.message,
                type: 'error',
                autoHide: true
              })
            }
          })
        case 'calculateSpirometry':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const fieldIndex = fields.findIndex(item => {
              return item.name === related.fieldName
            })

            const config = {}

            Object.entries(related.props).forEach(([key, value]) => {
              const currentField = fields.filter(item => {
                return item.name === value
              })[0] || {}

              config[key] = currentField.value

              if (_.isObject(config[key])) {
                config[key] = config[key].value
              }
            })

            try {
              return this.props.onFormAddValue(spirometricCalculator(config), fieldIndex)
            } catch (e) {
              return this.props.onShowMessage({
                message: e.message,
                type: 'error',
                autoHide: true
              })
            }
          })
        case 'ajaxUpdateValue':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const fieldIndex = fields.findIndex(item => {
              return item.name === related.name
            })

            const tmpValues = !_.isArray(value) ? [value] : value

            let ids = tmpValues.map(currentValue => {
              return currentValue.value
            })

            if (field.inputValue) {
              ids = value || inLabel || related.runAlways ? [field.inputValue] : []
            }

            return this.props.onFormAjaxUpdateValue({ ids }, fieldIndex, related.url)
          })
        case 'ajaxUpdateValuesByName':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            if (!inLabel || !related.notInLabel) {
              const ids = []

              if (related.valuesOfGroups) {
                fields.forEach(tmpField => {
                  if (tmpField.group === related.valuesOfGroups) {
                    if (tmpField.value) {
                      if (tmpField.inputValue) {
                        return ids.push(tmpField.inputValue)
                      }

                      if (_.isArray(tmpField.value)) {
                        return ids.push(...tmpField.value)
                      }

                      return ids.push(tmpField.value)
                    }
                  }
                })
              } else if (field.value) {
                ids.push(field.inputValue || field.value)
              }

              this.props.onFormAjaxUpdateValuesByName({ ids }, related.url, related, match.params)
            }
          })
        case 'updateOptionsWithValues':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            return this.props.onUpdateOptions(related, field.values || [field.value])
          })
        case 'filterFieldsByGroup':
          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const {
              groupFilter,
              paginations = {}
            } = this.state

            groupFilter[relateds] = value

            paginations[relateds] = {
              page: 1
            }

            return this.setState({
              groupFilter,
              paginations
            })
          })
        case 'bmiCalculator':
          return relateds.forEach(related => {
            const filteredField = fields.find((tmpFilteredField, index) => {
              tmpFilteredField.index = index

              return tmpFilteredField.name === related.fieldName
            })

            const { value: height } = fields.find(tmpFilteredField => {
              return tmpFilteredField.name === related.heightField
            }) || {}

            const { value: weight } = fields.find(tmpFilteredField => {
              return tmpFilteredField.name === related.weightField
            }) || {}

            if (height && weight && filteredField) {
              const result = (weight / Math.pow((height / 100), 2)).toFixed(2)

              this.props.onFormAddValue(result, filteredField.index)
            }
          })
        case 'postalCode':
          const location = getParsedLocation(value)

          return relateds.forEach(related => {
            if (related.onlyIfOne && _.isArray(value) && value.length > 1) {
              return false
            }

            const fieldIndex = fields.findIndex(item => {
              return item.name === related
            })

            return location ? this.props.onFormAddValue(location.postalCode, fieldIndex) : true
          })
        default:
      }
    })

    this.props.onCommitChanges(action.fromState)
  }

  onHandleChange = (action, field, fieldIndex, value, confirmed) => {
    const { formType, saving } = this.props

    const { changed } = this.state

    if (!changed && !saving) {
      this.setState({
        changed: true,
        first: false
      })
    }

    if (action.confirm && !confirmed) {
      return this.props.onHandleOpen(action.confirm, this.onHandleChange, [action, field, fieldIndex, value, true])
    }

    if (field.type) {
      switch (field.type) {
        case 'address':
        case 'contact':
        case 'print':
          this.onHandleChangeAddress(action, field, fieldIndex, value)

          break
        default:
          switch (action.type) {
            case 'remove':
              this.props.onFormRemoveValue(value, fieldIndex, action.index, field.name)

              break
            case 'replace':
              this.props.onFormReplaceValues({
                data: {
                  [field.name]: value
                }
              })
              break
            default:
              this.props.onFormAddValue(value, fieldIndex, action.index, field.name)
          }

          if (field.updateRelated) {
            setTimeout(() => {
              this.updateRelated(value, fieldIndex, action, field.updateRelated)
            }, 200)
          }

          break
      }
    }

    switch (action.type) {
      case 'dynamic':
      case 'add':
      case 'remove':
        if (!action.fromState) {
          this.props.onCommitChanges(action.fromState)
        }

        break
      case 'saveBeforeAction':
        this.saveForm(formType, field.params, false, field.callback, field.callbackProps, field.callbackUrlInterpolation)

        break
      default:

    }
  }

  getFieldValue = (fieldName) => {
    const { fields } = this.props.formContent

    const tmpArray = fields.filter(field => {
      return fieldName === field.name
    })

    if (tmpArray.length > 0) {
      if (typeof tmpArray[0].value === 'string') {
        return tmpArray[0].value
      }

      return tmpArray[0].value || {}
    }

    return {}
  }

  getFieldIndex = (fieldName) => {
    const { fields } = this.props.formContent

    return fields.findIndex(field => field.name === fieldName)
  }  

  onSpecialClick = (special, fieldIndex) => {
    switch (special.type) {
      case 'bmiCalculator':
        try {
          const { fields } = special

          const height = this.getFieldValue(fields.height)

          const weight = this.getFieldValue(fields.weight)

          const result = (weight / Math.pow((height / 100), 2)).toFixed(2)

          this.props.onFormAddValue(result, fieldIndex)
        } catch (err) {
          console.log(err)
        }

        break
      case 'cfCalculator':
        try {
          const { fields } = special

          const birthplace = getParsedLocation(this.getFieldValue(fields.birthplace))

          const birthday = this.getFieldValue(fields.birthday)

          let birthdayArray = birthday.split('/')

          if (birthdayArray.length < 3) {
            birthdayArray = '0/0/0'.split('/')
          }

          const gender = this.getFieldValue(fields.gender)

          const cfConfig = {
            name: this.getFieldValue(fields.name),
            surname: this.getFieldValue(fields.lastname),
            gender: gender.value || gender,
            day: birthdayArray[0],
            month: birthdayArray[1],
            year: birthdayArray[2],
            birthplace: birthplace.name,
          }

          try {
            const cf = CodiceFiscale.compute(cfConfig)

            this.props.onFormAddValue(cf, fieldIndex)
          } catch (err) {
            cfConfig.birthplaceProvincia = birthplace.province

            const cf = CodiceFiscale.compute(cfConfig)

            this.props.onFormAddValue(cf, fieldIndex)
          }
        } catch (err) {
          console.log(err)
        }

        break
      case 'cfDecoder':
        try {
          const { options } = special

          const cfValue = this.getFieldValue('codice_fiscale')

          const cf = new CodiceFiscale(cfValue)

          const toReplace = {
            sesso: cf.gender,
            data_nascita: cf.birthday.toLocaleDateString('it-IT', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          }

          ajaxCall({
            config: {
              method: 'GET',
              url: `${options}?query=${cf.birthplace.nome}`,
            },
            success: (response) => {  
              const data = response.data.data
  
              if (data && data.length > 0) {
                const location = data[0]

                const locationValue = {
                  value: location.value, 
                  label: location.label,
                  forRelated: location.forRelated
                }

                toReplace.uid_luogo_nascita = locationValue
              } else {
                console.log('No matching location found.')
              }

              this.props.onFormReplaceValues(toReplace, {})
            },
            error: (error) => {
              console.log('API Error:', error)

              this.props.onFormReplaceValues(toReplace, {})
            },
          })
        } catch (err) {
          console.log(err)
        }
        break
      default:
    }

    this.props.onCommitChanges()
  }

  getField = (field, index, toHide, inGroup = false, isFirst = false) => {
    const { classes, formContent, formType } = this.props

    return (
      <FormField field={field} key={index} fieldIndex={index} onChange={(action, field, value) => this.onHandleChange(action, field, index, value)} onSpecialClick={(special) => this.onSpecialClick(special, index)} formType={formType} isFirst={isFirst} inGroup={inGroup} gridClass={toHide ? classes.hideBlock : ''} onActionClick={this.onActionClick} readonly={formContent.readonly} />
    )
  }

  onActionClick = (action, currentModalAction, params) => {
    const { history, match, formType, appConfig, inlineForm, formContent } = this.props

    const { periodFrom, periodTo, openModal, changed } = this.state

    if (!action.withParams) {
      params = {}
    }

    params = {
      ...params,
      id: [match.params.id]
    }

    switch (action.type) {
      case 'group':
        return this.setState({
          openModal: true,
          currentModalAction: action
        })
      case 'print':
        if (openModal) {
          params.period = {
            from: periodFrom,
            to: periodTo
          }
        }

        Object.values(inlineForm).forEach(formParams => {
          params = {
            ...params,
            ...formParams
          }
        })

        return this.props.onPrintPost(action.action, params)
      default:
        if (action.saveBeforeAction && (changed || formContent.saveUnchanged)) {
          return this.saveForm(formType, {
            ...match.params,
            temporarySave: action
          })
        }

        return history.push(appConfig.appPrefix + action.action)
    }
  }

  saveForm = (formType, params, asNew = false, callback, callbackProps, callbackUrlInterpolation) => {
    return this.setState({
      changed: false
    }, () => {
      this.props.onFormSave(formType, params, asNew, callback, callbackProps, callbackUrlInterpolation)
    })
  }

  getFilteredFields = groupFields => {
    return groupFields.filter(groupField => {
      const { groupFilter } = this.state

      const patternValue = groupFilter ? groupFilter[groupField.field.group] || '' : ''

      return groupField.field.label.toLowerCase().includes(patternValue.toLowerCase())
    })
  }

  goToPage = (page, group) => {
    const currentPaginations = _.cloneDeep(this.state.paginations || {})

    currentPaginations[group] = {
      ...(currentPaginations[group] || {}),
      page
    }

    this.setState({
      paginations: currentPaginations
    })
  }

  getPagination = (groupFields) => {
    const {
      classes
    } = this.props

    const tmpGroupFields = this.getFilteredFields(groupFields)

    const {
      group,
      pagedGroup
    } = (tmpGroupFields[0] || {}).field || {}

    if (!pagedGroup) {
      return ''
    }

    const {
      paginations = {},
      itemsPerPage
    } = this.state

    const {
      page = 1
    } = paginations[group] || {}

    const {
      first,
      last,
      pages,
      prevPage,
      nextPage,
      totalPages
    } = getPagination({
      current: page,
      rows: itemsPerPage,
      totalItems: tmpGroupFields.length
    })

    return (
      <div className={classes.pagination}>
        <IconButton disabled={!prevPage} onClick={() => this.goToPage(prevPage, group)}>
          <Icon>chevron_left</Icon>
        </IconButton>
        {first && (
          <React.Fragment>
            <Button className={classes.paginationNumber} size='small' onClick={() => this.goToPage(1, group)}>1</Button>
            <Button className={classes.paginationNumber} size='small' disabled={true}>...</Button>
          </React.Fragment>
        )}
        {pages.map((item, index) => {
          return (
            <Button className={classes.paginationNumber} size='small' disabled={item === page} onClick={() => this.goToPage(item, group)} key={index}>{item}</Button>
          )
        })}
        {last && (
          <React.Fragment>
            <Button className={classes.paginationNumber} size='small' disabled={true}>...</Button>
            <Button className={classes.paginationNumber} size='small' onClick={() => this.goToPage(totalPages, group)}>{totalPages}</Button>
          </React.Fragment>
        )}
        <IconButton disabled={!nextPage} onClick={() => this.goToPage(nextPage, group)}>
          <Icon>chevron_right</Icon>
        </IconButton>
      </div>
    )
  }

  getPagetFields = groupFields => {
    let tmpGroupFields = this.getFilteredFields(groupFields)

    const {
      group,
      pagedGroup
    } = (tmpGroupFields[0] || {}).field || {}

    tmpGroupFields.sort((a, b) => {
      const aIsAnAssessment = (a.field.name || '').toLowerCase().includes('assessment')

      const bIsAnAssessment = (b.field.name || '').toLowerCase().includes('assessment')

      const aIsOccasional = aIsAnAssessment && a.field.value.label ? a.field.value.label.toLowerCase().includes('occasionale') : false

      const bIsOccasional = bIsAnAssessment && b.field.value.label ? b.field.value.label.toLowerCase().includes('occasionale') : false

      if (aIsAnAssessment && bIsAnAssessment) {
        if (aIsOccasional && !bIsOccasional) {
          return 1
        }

        if (!aIsOccasional && bIsOccasional) {
          return -1
        }
      }

      return 0
    })

    if (pagedGroup) {
      const {
        paginations = {},
        itemsPerPage
      } = this.state

      const {
        page = 1
      } = paginations[group] || {}

      let from = itemsPerPage * (page - 1)

      const maxFrom = Math.ceil((groupFields.length - 1) / itemsPerPage) * itemsPerPage

      if (from > maxFrom) {
        from = maxFrom
      }

      tmpGroupFields = tmpGroupFields.slice(from, from + 10)
    }

    return tmpGroupFields.map((groupField, index) => {
      return (this.getField(groupField.field, groupField.index, false, true, index === 0))
    })
  }

  render() {
    const { classes, formContent, formType, match, sidebarIsOpen, goToEdit } = this.props

    if (!formContent) {
      return ''
    }

    const { changed, currentModalAction, openModal, periodFrom, periodTo } = this.state

    const prevPage = getPrevPage(goToEdit, match)

    const { fields, actions, actionsAsTabs, rightList } = formContent

    let groupFields = []

    const canSave = formContent.canSave !== false || formContent.readonly === false

    return (
      <div className={classes.root}>
        <Prompt when={changed} message='Ci sono delle modifiche non salvate. Sei sicuro di uscire senza salvare?' />
        {currentModalAction ? (
          <ModalActions {...{
            currentModalAction, openModal, periodFrom, periodTo, onClose: () => {
              this.setState({
                openModal: false
              })
            }, onClick: this.onActionClick, parent: this
          }} />
        ) : null}
        <Grid className={classes.gridRoot + (actionsAsTabs ? ' ' + classes.actionsAsTabs : '')} container spacing={24}>
          {actions && actions.length ? actionsAsTabs ? (
            <Tabs
              value={actions.findIndex(action => {
                return action.active
              })}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
              classes={{
                flexContainer: classes.tabsAction + (sidebarIsOpen ? ' ' + classes.tabsActionSidebarIsOpen : '')
              }} >
              {actions.map((action, index) => {
                return (
                  <Tab classes={{
                    root: classes.tabAction + (action.active ? ' ' + classes.tabActionActive : ''),
                    labelContainer: classes.tabActionLabel
                  }} label={action.label} key={index} onClick={() => this.onActionClick(action)} />
                )
              })}
            </Tabs>
          ) : (
            <React.Fragment>
              <Paper className={classes.paper}>
                <div className={classes.actions + ' ' + classes.paperActions}>
                  {actions.map((action, index) => {
                    if (action.type === 'divider') {
                      return (
                        <div className={classes.divider} key={index}>-</div>
                      )
                    }

                    if (action.url) {
                      return (
                        <Button classes={{ root: classes.actionRoot, label: classes.action }} key={index} href={action.url} style={action.styles || {}} target='_blank' rel='noopener noreferrer' variant='contained'>
                          <span className={classes.actionIcon}><MIcon>{action.icon}</MIcon></span>
                          <span className={classes.actionLabel}>{action.label}</span>
                        </Button>
                      )
                    }

                    return (
                      <Button disabled={action.active ? true : false} classes={{ root: classes.actionRoot, label: classes.action }} style={action.styles || {}} key={index} onClick={() => this.onActionClick(action)} variant='contained'>
                        <span className={classes.actionIcon}><MIcon>{action.icon}</MIcon></span>
                        <span className={classes.actionLabel}>{action.label}</span>
                      </Button>
                    )
                  })}
                </div>
              </Paper>
              <Grid item xs={12} className={classes.divider}>
                <Divider className={classes.dividerLine} light />
              </Grid>
            </React.Fragment>
          ) : ''}
          <Grid item xs={rightList ? 8 : 12}>
            <Paper className={classes.paper + ' ' + classes.paddingBottom} component={Grid} container spacing={24}>
              {fields.map((field, index) => {
                if (field.hidden && !field.forceValueIfHidden) {
                  groupFields = []

                  return null
                }

                const { groupFilter } = this.state

                const patternValue = groupFilter ? groupFilter[field.group] || '' : ''

                if (field.inGroup) {
                  const tmpConfig = {
                    field: {
                      ...field,
                      size: 12
                    },
                    index,
                    size: field.groupSize || field.size,
                    gridStyles: {},
                    fullHeightGroup: false,
                  }

                  if (!groupFields[0]) {
                    if (Number.isInteger(field.groupSize)) {
                      tmpConfig.hasCustomSize = true

                      tmpConfig.size = field.groupSize

                      tmpConfig.field.size = field.size
                    }

                    if (field.gridStyles) {
                      tmpConfig.gridStyles = field.gridStyles
                    }

                    if (field.fullHeightGroup) {
                      tmpConfig.fullHeightGroup = true
                    }
                  } else if (groupFields[0].hasCustomSize) {
                    tmpConfig.size = groupFields[0].size

                    tmpConfig.field.size = field.size
                  }

                  groupFields.push(tmpConfig)

                  if (fields[index + 1] && !fields[index + 1].inGroup) {
                    return (
                      <Grid item xs={groupFields[0].size} style={groupFields[0].gridStyles} key={index}>
                        <Grid container spacing={24} className={!groupFields[0].fullHeightGroup ? classes.gridGroup : ''}>
                          {groupFields.sort((a) => {
                            if (a.field.value === true) {
                              return -1
                            }

                            return 1
                          }).map((groupField, index) => {
                            const toHide = !groupField.field.label.toLowerCase().includes(patternValue.toLowerCase())

                            return (this.getField(groupField.field, groupField.index, toHide, true, index === 0))
                          })}
                        </Grid>
                      </Grid>
                    )
                  }

                  return ''
                }

                groupFields = []

                return this.getField(field, index, false)
              })}
              {groupFields.length ? (
                <Grid item xs={groupFields[0].size} style={groupFields[0].gridStyles}>
                  <Grid container spacing={24} className={!groupFields[0].fullHeightGroup ? classes.gridGroup : ''}>
                    {this.getPagetFields(groupFields)}
                    {this.getPagination(groupFields)}
                  </Grid>
                </Grid>
              ) : ''}
            </Paper>
          </Grid>
          {rightList ? (
            <Grid item xs={4}>
              <List list={rightList} />
            </Grid>
          ) : null}
          {!canSave ? null : (
            <React.Fragment>
              <Grid item xs={12} className={classes.divider}>
                <Divider className={classes.dividerLine} light />
              </Grid>
              <Paper className={classes.paper}>
                <Grid item xs={6} className={classes.divider}>
                  <Button variant='contained' className={classes.successButton} onClick={() => this.saveForm(formType, this.props.match.params)}>{formContent.saveLabel || 'Salva'}</Button>
                  {formContent.saveOptions && formContent.saveOptions.canSaveNew ? (
                    <Button variant='contained' className={classes.successButtonNew} onClick={() => this.saveForm(formType, this.props.match.params, true)}>Salva nuovo</Button>
                  ) : null}
                </Grid>
                <Grid item xs={6} className={classes.divider + ' ' + classes.textRight}>
                  {formContent.saveOptions && formContent.saveOptions.canDelete ? (
                    <Button variant='contained' color='secondary' component={NavLink} to={prevPage}>Elimina</Button>
                  ) : null}
                  <Button variant='contained' component={NavLink} to={prevPage}>Annulla</Button>
                </Grid>
              </Paper>
            </React.Fragment>
          )}
        </Grid>
      </div>
    )
  }
}

FormEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.formReducer, ...state.inlineFormReducer })

const mapDispatchToProps = dispatch => ({
  onFormUpdateFieldProps: (value, config) => {
    dispatch(formActions.formUpdateFieldProps(value, config))
  },
  onPreppendROValuesFromAjax: (config) => {
    dispatch(formActions.preppendROValuesFromAjaxPost(config))
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onFormGet: (formType, params) => {
    dispatch(formActions.formGet(formType, params))
  },
  onUpdateOptions: (fieldName, value) => {
    dispatch(formActions.updateOptions(fieldName, value))
  },
  onFormAjaxUpdateValuesByName: (data, url, config, params) => {
    dispatch(formActions.formAjaxUpdateValuesByName(data, url, config, params))
  },
  onFormAjaxUpdateValue: (data, fieldIndex, url) => {
    dispatch(formActions.formAjaxUpdateValue(data, fieldIndex, url))
  },
  onFormRemoveValue: (value, fieldIndex, dynamicIndex, fieldName) => {
    dispatch(formActions.formRemoveValue(value, fieldIndex, dynamicIndex, fieldName))
  },
  onFormAddValue: (value, fieldIndex, dynamicIndex, fieldName, append) => {
    dispatch(formActions.formAddValue(value, fieldIndex, dynamicIndex, fieldName, append))
  },
  onFormReplaceValues: (data, config) => {
    dispatch(formActions.formReplaceValues(data, config))
  },
  onCommitChanges: (fromProp = true) => {
    dispatch(formActions.commitChanges(fromProp))
  },
  onFormSave: (formType, params, asNew, callback, callbackProps, callbackUrlInterpolation) => {
    dispatch(formActions.formSavePost(formType, params, asNew, callback, callbackProps, callbackUrlInterpolation))
  },
  onFormCalcValue: (global = false) => {
    dispatch(formActions.formCalcValue(global))
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onShowMessage: (config) => {
    dispatch(messageActions.showMessage(config))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FormEdit)))
