import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

import {
  appActions
} from '../../actions'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 50,
    right: '-100%',
    transition: 'right .5s ease-in-out, left .5s ease-in-out',
    display: 'flex',
    flexDirection: 'column'
  },
  rootSw: {
    bottom: 0
  },
  active: {
    left: 'auto',
    right: '-1.5rem',
    zIndex: 1301,
  },
  fab: {
    width: 420,
    justifyContent: 'flex-start',
    paddingRight: '2rem',
    backgroundColor: theme.palette.warning.light,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    }
  },
  fabSw: {
    width: 420,
    justifyContent: 'flex-start',
    paddingRight: '2rem',
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    }
  },
  animate: {
    right: '-370px',
    '&:hover': {
      right: '-1.5rem'
    }
  },
  icon: {
    marginRight: '1rem'
  }
})

class HasUpdates extends React.Component {
  state = {
    animate: false,
    hasUpdates: false
  }

  componentDidMount = () => {
    this.props.onCheckUpdates()

    this.props.onUpdateAll()

    this.props.onGetNotifications()

    this.props.onCheckSoftwareUpdate()

    this.props.onUploadDriveZips()
  }

  componentDidUpdate = (prevProps) => {
    const { hasUpdates, hasSoftwareUpdates } = this.props

    const hasUpdatesForState = !(!(hasUpdates || hasSoftwareUpdates))

    const { hasUpdates: stateHasUpdates } = this.state

    const { animate } = this.state

    if (stateHasUpdates !== hasUpdatesForState) {
      this.setState({
        hasUpdates: hasUpdatesForState
      })

      if (!animate && hasUpdatesForState) {
        setTimeout(() => {
          this.setState({
            animate: true
          })
        }, 5000)
      }
    } else if (!hasUpdatesForState && animate) {
      this.setState({
        animate: false,
      })
    }
  }

  render() {
    const { classes, hasUpdates, appConfig } = this.props

    const { animate } = this.state

    return (
      <div className={classes.root + (hasUpdates  ? ' ' + classes.active : '') + (animate ? ' ' + classes.animate : '')}>
        {hasUpdates ? (
          <Fab variant='extended' aria-label='Delete' className={classes.fab} component={NavLink} to={appConfig.appPrefix + '/update'}>
            <Icon className={classes.icon}>sync</Icon> Ci sono delle modifiche da sincronizzare.
          </Fab>
        ) : null}
      </div>
    )
  }
}

HasUpdates.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onCheckUpdates: () => {
    dispatch(appActions.checkUpdates())
  },
  onUpdateAll: () => {
    dispatch(appActions.updateAll())
  },
  onGetNotifications: () => {
    dispatch(appActions.getNotifications())
  },
  onCheckSoftwareUpdate: () => {
    dispatch(appActions.checkSoftwareUpdate())
  },
  onUploadDriveZips:() => {
    dispatch(appActions.uploadDriveZips())
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(HasUpdates)))
