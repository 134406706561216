import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '../Icon'
import Divider from '@material-ui/core/Divider'

import {
  appActions
} from '../../actions'

const styles = theme => ({
  button: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    transition: 'opacity .3s ease-in-out'
  },
  buttonInModal: {
    margin: 0
  },
  disabled: {
    opacity: '.5'
  },
  label: {
    marginLeft: '.5rem'
  },
})

class List extends React.Component {
  state = {
    open: false,
    anchorEl: null
  }

  handleOpenMenu = (event, onFiltered) => {
    if (onFiltered) {
      this.props.onGetFilteredItems()
    }

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  handleCloseMenu = (event, index) => {
    this.props.onResetFilteredItems()

    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  render() {
    const { classes, enabled, action, onClick } = this.props

    const { style = {}, icon, label, onFiltered = false } = action

    const { open, anchorEl } = this.state

    return (
      <React.Fragment>
        <Button variant='contained' disabled={!enabled} className={classes.button + (!enabled ? ' ' + classes.disabled : '')} style={style} onClick={(event) => this.handleOpenMenu(event, onFiltered)}>
          {icon ? (
            <Icon>{icon}</Icon>
          ) : null}
          <span className={classes.label}>{label}</span>
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={this.handleCloseMenu}>
          {action.actions.map((listItem, listItemIndex) => {
            const { icon: listItemIcon } = listItem
            switch (listItem.type) {
              case 'divider':
                return (
                  <Divider key={listItemIndex} />
                )
              default:
                return (
                  <MenuItem key={listItemIndex} onClick={() => onClick(listItem, action)}>
                    {listItemIcon ? (
                      <ListItemIcon>
                        <Icon>{listItemIcon}</Icon>
                      </ListItemIcon>
                    ) : null}
                    <ListItemText inset primary={listItem.label} />
                  </MenuItem>
                )
            }
          })}
        </Menu>
      </React.Fragment>
    )
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onResetFilteredItems: () => {
    dispatch(appActions.resetFilteredItems())
  },
  onGetFilteredItems: () => {
    dispatch(appActions.getFilteredItems())
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(List)))
