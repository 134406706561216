import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.common.black
  }
})

class Label extends React.Component {
  render() {
    const { classes, cell } = this.props

    return (
      <div className={classes.root}>
        {cell.label}
      </div>
    )
  }
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Label)))
