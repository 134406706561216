import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Title from './Title'
import Table from './Table'
import Grid from './Grid'
import VisioTest from './VisioTest'
import _ from 'lodash'

import {
  blockActions
} from '../../actions'

const styles = theme => ({})

const blocks = {
  title: Title,
  table: Table,
  grid: Grid,
  visiotest: VisioTest
}

class Block extends React.Component {
  updateRelated = {
    changeFieldProp: (configs, fieldPath) => {
      const { blockData, fieldPathMapping } = this.props

      const { blocks } = blockData

      configs.forEach(config => {
        const currentField = _.get(blocks, fieldPath)

        let currentValue = currentField.value

        if (currentField.options && config.relatedValue) {
          currentValue = currentField.options.find(item => {
            return ('' + item.value) === ('' + currentValue)
          }).relatedValue[config.relatedValue]
        }

        this.props.onChangeFieldProp(currentValue, config.prop, fieldPathMapping[config.field])
      })
    },
    changeFields: (configs) => {
      const { blockData, fieldPathMapping } = this.props

      const { blocks } = blockData

      configs.forEach(config => {
        config.fields.forEach((field) => {
          if (config.conditions) {
            let canChange = false

            Object.entries(config.conditions).forEach(([condition, config]) => {
              switch (condition) {
                case 'ifOr':
                  Object.entries(config).forEach(([fieldName, value]) => {
                    const { value: currentValue } = _.get(blocks, fieldPathMapping[fieldName])

                    if (!canChange) {
                      canChange = currentValue.value ? currentValue.value === value : currentValue === value
                    }
                  })

                  break
                default:
              }
            })

            if (canChange) {
              this.props.onChangeField(config.value, fieldPathMapping[field])
            }
          } else {
            this.props.onChangeField(config.value, fieldPathMapping[field])
          }
        })
      })

      this.props.onCommitChanges()
    },
    changeFieldsIfValueInRange: (configs) => {
      const { blockData, fieldPathMapping } = this.props

      const { blocks } = blockData

      configs.forEach(config => {
        const { value: currentValue = 0 } = _.get(blocks, fieldPathMapping[config.fieldName])

        config.fields.forEach((field) => {
          let toReturn = ''

          config.switch.forEach(switchElement => {
            if (!toReturn) {
              const lt = switchElement.lt || (currentValue + 1)

              const gt = switchElement.gt || (currentValue - 1)

              if (currentValue < lt && currentValue > gt) {
                return toReturn = switchElement.value
              }
            }
          })

          this.props.onChangeField(toReturn, fieldPathMapping[field])
        })
      })

      this.props.onCommitChanges()
    }
  }

  onChangeField = (value, fieldPath) => {
    this.props.onChangeField(value, fieldPath)

    const { blocks } = this.props.blockData

    const field = _.get(blocks, fieldPath)

    if (field.updateRelated) {
      Object.entries(field.updateRelated).forEach(([method, config]) => {
        if (typeof this.updateRelated[method] === 'function') {
          return this.updateRelated[method](config, fieldPath)
        }
      })
    }
  }

  render() {
    const { block, blockIndex, readonly } = this.props

    const { type } = block

    if (!blocks[type]) {
      return ''
    }

    const TmpBlock = blocks[type]

    return (
      <TmpBlock block={block} blockIndex={blockIndex} onChange={this.onChangeField} {...{readonly}} />
    )
  }
}

Block.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.blockReducer })

const mapDispatchToProps = dispatch => ({
  onCommitChanges: () => {
    dispatch(blockActions.commitChanges())
  },
  onChangeFieldProp: (value, prop, fieldPath) => {
    dispatch(blockActions.changeFieldProp(value, prop, fieldPath))
  },
  onChangeField: (value, fieldPath) => {
    dispatch(blockActions.changeField(value, fieldPath))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Block)))
