import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUIGrid from '@material-ui/core/Grid'
import BaseField from '../TableCell/BaseField'

import {
  blockActions,
} from '../../actions'

const styles = theme => ({})

class Grid extends React.Component {
  onChangeField = (value, fieldPath) => {
    return this.props.onChangeField(value, fieldPath)
  }

  render() {
    const { classes, block, blockIndex, readonly } = this.props

    return (
      <MUIGrid container className={classes.root} spacing={16}>
        {block.fields.map((field, index) => {
          return (
            <MUIGrid item xs={field.size || 12} key={index}>
              <BaseField onChange={this.onChangeField} cell={field} fieldPath={[blockIndex, 'fields', index]} isActive={true} {...{readonly}} />
            </MUIGrid>
          )
        })}
      </MUIGrid>
    )
  }
}

Grid.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onChangeField: (value, fieldPath) => {
    dispatch(blockActions.changeField(value, fieldPath))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Grid)))
