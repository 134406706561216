import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUIDivider from '@material-ui/core/Divider'

import {} from '../../../actions'

const styles = theme => ({})

class Divider extends React.Component {
  render() {
    return (
      <MUIDivider light />
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Divider)))
