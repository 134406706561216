import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HeaderLogin from '../../components/HeaderLogin'
import _ from 'lodash'

import {
  authActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    textAlign: 'center',
    paddingTop: '1rem'
  },
  form: {
    minWidth: 350,
    maxWidth: 350
  },
  buttonsGrid: {
    paddingTop: '2rem',
    alignItems: 'center'
  },
  navLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  helperText: {
    marginTop: '1.25rem',
    fontSize: '.875rem',
    '& ul': {
      marginBottom: 0,
      paddingLeft: '1rem'
    }
  },
  buttonItem: {
    marginBottom: '2rem'
  },
  divider: {
    maxWidth: 75
  },
  logout: {
    textTransform: 'none',
    marginBottom: '2rem'
  }
})

class Login extends React.Component {
  state = {
    otpCode: ''
  }

  handleChange = (event, name) => {
    let newState = _.cloneDeep(this.state)

    newState[name] = event.target.value

    return this.setState(newState)
  }

  render() {
    const {
      classes,
      resetPassword
    } = this.props

    const {
      otpCode
    } = this.state

    const canSubmit = otpCode.length === 6

    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={(event) => {
          event.preventDefault()

          return this.props.onCheckOtpPost(this.state)
        }}>
          <HeaderLogin />
          <div className={classes.info}>Inserisci il codice di verifica per l'autenticazione a due fattori!</div>
          <TextField
            fullWidth
            id='otp_code'
            label='Codice'
            className={classes.textField}
            value={otpCode}
            margin='normal'
            autoFocus={true}
            error={resetPassword.error === 'otpCode'}
            onChange={(event) => {
              const pattern = new RegExp('^[0-9]{0,6}$', 'ig')

              if (pattern.test(event.target.value)) {
                this.props.onResetPasswordReducer()

                return this.handleChange(event, 'otpCode')
              }
            }}
            FormHelperTextProps={{
              component: 'div',
              className: classes.helperText
            }}
            helperText={resetPassword.error === 'otpCode' && 'Codice non valido'}
          />
          <Grid container spacing={16} className={classes.buttonsGrid}>
            <Grid item xs={12} className={classes.buttonItem}>
              <Button fullWidth variant='contained' color='primary' type='submit' disabled={!canSubmit}>Verifica</Button>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.divider} />
            </Grid>
            <Grid item xs={12} className={classes.info}>
              È la prima volta che accedi oppure hai problemi di accesso (es. smarrito/cambiato smartphone)?<br />Richiedi un nuovo account authenticator.
            </Grid>
            <Grid item xs={12} className={classes.info}>
              <Button color='primary' onClick={this.props.onRestOtpPost}>Richiedi nuovo account</Button>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.divider} />
            </Grid>
            <Grid item xs={12} className={classes.info}>
              <Button className={classes.logout} color='primary' onClick={() => {
                this.props.onLogout()
              }}>Esci</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.authReducer })

const mapDispatchToProps = dispatch => ({
  onResetPasswordReducer: () => {
    return dispatch(authActions.resetPasswordReducer())
  },
  onCheckOtpPost: (data) => {
    return dispatch(authActions.checkOtpPost(data))
  },
  onRestOtpPost: () => {
    return dispatch(authActions.resetOtpPost())
  },
  onLogout: () => {
    return dispatch(authActions.logout())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login)))
