import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Block from '../../components/Block'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TopActions from '../../components/Block/TopActions'
import { getPrevPage } from '../../utils'
import Prompt from '../../components/Prompt'

import {
  blockActions,
  appActions,
  confirmActions,
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    padding: '1rem'
  },
  paper: {
    padding: '1rem',
    flexGrow: 1,
    display: 'flex'
  },
  textRight: {
    textAlign: 'right'
  },
  paperColumn: {
    flexDirection: 'column'
  },
  successButton: {
    backgroundColor: theme.palette.success[100],
    color: '#fff'
  },
  button: {
    marginRight: '1rem'
  },
  icon: {
    marginRight: '.5rem'
  },
  checkbox: {
    fontSize: '1rem',
    alignSelf: 'center',
    marginRight: '1rem'
  }
})

class Questionnaire extends React.Component {
  state = {
    params: {},
  }

  componentDidMount() {
    const { params } = this.props.match

    this.props.onQuestionnaireGet(params)
  }

  componentDidUpdate(prevProps) {
    const {
      blockData: prevBlockData,
      match: prevMatch
    } = prevProps

    const { titleBlock: prevTitleBlock } = prevBlockData

    const { newId, blockData, match, history, goTo, appConfig } = this.props

    const { titleBlock } = blockData

    if (goTo) {
      return history.push(appConfig.appPrefix + goTo)
    }

    if (prevTitleBlock !== titleBlock && titleBlock) {
      this.props.onChangeTitleBlock(titleBlock)
    }

    if (newId) {
      const pattern = new RegExp('(/clone/(.*?)|/copy/(.*?)|/create|/create/([0-9a-zA-Z]|(-)){36})$')

      if (pattern.test(match.url) && newId) {
        return history.push(match.url.replace(pattern, '/edit/' + newId))
      }
    }

    if (prevMatch.url && prevMatch.url !== match.url) {
      this.props.onQuestionnaireGet(match.params)
    }
  }

  onActionClick = (action) => {
    const { match } = this.props

    const { params = {} } = this.state

    this.props.onQuestionnairePost({
      ...match.params,
      fromPrint: true,
    }, questionnaireParams => {
      this.props.onPrintPost(action, {
        ...questionnaireParams,
        ...params
      })
    })
  }

  onDelete = (confirm, id, confirmed = false) => {
    if (!confirmed) {
      return this.props.onHandleOpen(confirm, this.onDelete, [
        confirm,
        id,
        true
      ])
    }

    return this.props.onDelete(id)
  }

  render() {
    const { blockData, classes, goToEdit, match, changed } = this.props

    const { blocks, readonly, topActions, deleteConfirm, id } = blockData

    if (!blocks) {
      return ''
    }

    const prevPage = getPrevPage(goToEdit, match)

    return (
      <div className={classes.root}>
        <Prompt when={changed} message='Ci sono delle modifiche non salvate. Sei sicuro di uscire senza salvare?' />
        <Grid container spacing={24} className={classes.root}>
          {topActions ? (
            <React.Fragment>
              <Paper className={classes.paper}>
                <TopActions parent={this} topActions={topActions} />
              </Paper>
              <Grid item xs={12} className={classes.divider}>
                <Divider light />
              </Grid>
            </React.Fragment>
          ) : null}
          <Paper className={classes.paper + ' ' + classes.paperColumn} id='questionnaire-container'>
            <Grid container spacing={16}>
              {blocks.map((block, index) => {
                return (
                  <Grid item xs={block.size || 12} key={index}>
                    <Block block={block} blockIndex={index} {...{readonly}} />
                  </Grid>
                )
              })}
            </Grid>
          </Paper>
          {readonly ? null : (
            <React.Fragment>
              <Grid item xs={12} className={classes.divider}>
                <Divider light />
              </Grid>
              <Paper className={classes.paper + ' ' + classes.actions}>
                <Grid item xs={6} className={classes.divider}>
                  <Button variant='contained' className={classes.successButton} onClick={() => this.props.onQuestionnairePost(match.params)}>Salva</Button>
                </Grid>
                <Grid item xs={6} className={classes.divider + ' ' + classes.textRight}>
                  {id ? (
                    <Button variant='contained' color='secondary' className={classes.button} onClick={() => {
                      return this.onDelete(deleteConfirm, id)
                    }}>Elimina</Button>
                  ) : null}
                  <Button variant='contained' component={NavLink} to={prevPage}>Annulla</Button>
                </Grid>
              </Paper>
            </React.Fragment>
          )}
        </Grid>
      </div>
    )
  }
}

Questionnaire.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.blockReducer, ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onQuestionnairePost: (params, callback) => {
    dispatch(blockActions.blockPost(params, callback))
  },
  onQuestionnaireGet: (params) => {
    dispatch(blockActions.blockGet(params))
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onHandleOpen: (config, callback, callbackParams = []) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onDelete: (id) => {
    dispatch(blockActions.blockDeletePost(id))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Questionnaire)))
