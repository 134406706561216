import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUITableRow from '@material-ui/core/TableRow'
import TableCell from '../TableCell'

const styles = theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    }
  },
  rootBody: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[50],
    }
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  hidden: {
    display: 'none'
  }
})

class TableRow extends React.Component {
  render() {
    const { classes, row, inBody, isActive, isHidden, fieldPath, onChange, readonly } = this.props

    let rowIsActive = null

    row.forEach(cell => {
      if (rowIsActive !== null) {
        return
      }

      if (cell.rowControl) {
        return rowIsActive = cell.value && cell.value !== '0' ? true : false
      }

      if (cell.data) {
        const innerRowControl = cell.data.filter(innerCell => {
          return innerCell.rowControl ? true : false
        })[0] || null

        if (innerRowControl) {
          if (typeof innerRowControl.value !== 'boolean') {
            return rowIsActive = parseInt(innerRowControl.value) ? true : false
          }

          return rowIsActive = innerRowControl.value ? true : false
        }
      }
    })

    if (typeof rowIsActive !== 'boolean') {
      rowIsActive = true
    }

    return (
      <MUITableRow className={isHidden ? classes['hidden'] : classes['root' + (inBody ? 'Body' : '')]}>
        {row.map((cell, index) => {
          return (
            <TableCell key={index} cell={cell} rowIsActive={rowIsActive} isActive={isActive} fieldPath={[...fieldPath, index]} onChange={onChange} {...{ readonly }} />
          )
        })}
      </MUITableRow>
    )
  }
}

TableRow.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableRow)))
