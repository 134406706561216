import _ from 'lodash'

const initialState = {
  dashboardConfig: null
}

// Vars
let newState = null

const dashboardReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'dashboard_action_get':
      newState = _.cloneDeep(state)

      newState.dashboardConfig = action.data.data

      return newState
    case 'dashboard_action_update_field':
      const {
        field,
        value,
        valueName
      } = action.data

      newState = _.cloneDeep(state)

      newState.dashboardConfig.forEach(row => {
        const toBeUpdated = (row.items || []).find(item => {
          return item.name && item.name === field
        })

        if (toBeUpdated) {
          toBeUpdated[valueName] = value

          return
        }
      })

      return newState
    default:
      return state
  }
}

export default dashboardReducer
