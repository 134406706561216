import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from '../FormField/Select'

import {} from '../../actions'

const styles = () => ({
  selectRoot: {
    margin: 0,
  }
})

class TableSelectFilter extends React.Component {
  state = {}

  componentDidMount() {
    const { element, onChange, filterValue } = this.props

    const { filter } = element

    const options = Object.entries(element.filter.options).map(option => ({
      value: option[1],
      label: option[0],
    }))

    const tmpValue = options.find(item => {
      return filterValue === item.value || item.value === filter.value
    })

    onChange(element, tmpValue)

    this.setState({
      value: tmpValue,
      options
    })
  }

  onChange = (value) => {
    this.setState({
      value: value
    })
  }

  render() {
    const { onChange, element, classes } = this.props

    const { value, options } = this.state

    return (
      <Select
        field={{
          options,
          value,
          placeholder: element.filter.placeholder,
          isClearable: true
        }}
        newCustomStyles={{
          control: () => ({
            minHeight: 38
          })
        }}
        selectClasses={{
          root: classes.selectRoot
        }}
        onChange={(action, field, newValue) => {
          onChange(element, newValue)

          return this.onChange(newValue)
        }} />
    )
  }
}

TableSelectFilter.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableSelectFilter)))
