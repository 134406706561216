import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import BaseField from './BaseField'
import _ from 'lodash'

import {
  inlineFormActions,
  listPageAjaxActions,
  confirmActions,
  printActions
} from '../../../actions'

const styles = theme => ({
  root: {
    padding: '0 1rem 1rem',
    display: 'flex',
    flexGrow: 1
  },
  label: {
    paddingRight: '1rem',
    alignSelf: 'center'
  },
  container: {
    flexGrow: 1
  }
})

class Divider extends React.Component {
  state = {
    fieldProps: {}
  }

  componentDidMount = () => {
    this.props.onResetParams()
  }

  onChange = (value, index, fieldName) => {
    return this.props.onAddValueToField(value, index, fieldName)
  }

  onSubmit = (type, index, url, field, confirmed) => {
    const { inlineForm } = this.props

    switch (type) {
      case 'print':
        return this.props.onPrintPost(field.action, inlineForm[index] || {})
      default:
        switch (url) {
          case 'resetPage':
            return this.updatePage(index, true)
          case 'updatePage':
            return this.updatePage(index)
          default:
            if (field.confirm) {
              if (field.confirm && !confirmed) {
                return this.props.onHandleOpen(field.confirm, this.onSubmit, [
                  type,
                  index,
                  url,
                  field,
                  true
                ])
              }
            }

            return this.submitForm(type, index, url, true)
        }
    }
  }

  updatePage = (index, reset) => {
    const { match, pageType, pageTypes, inlineForm, listPageParams } = this.props

    if (reset) {
      this.props.onResetParams()

      this.props.onListPagesAjaxGet(pageType, pageTypes, {
        ...match.params,
        ...listPageParams
      })

      return this.props.onListPageAjaxGet(pageType, match.params)
    }

    const params = {
      ...match.params,
      ...listPageParams,
      ...(reset ? {} : inlineForm[index] || {})
    }

    return this.props.onListPagesAjaxGet(pageType, pageTypes, params)
  }

  submitFormAction = (type, index, url, reset, ids) => {
    const { inlineForm } = this.props

    this.props.onSubmitForm({
      ids,
      type,
      ...(reset ? inlineForm[index] : {})
    }, url)
  }

  submitForm = (type, index, url, reset) => {
    const { selectedElements } = this.props

    if (type === 'filtered') {
      return this.props.onGetFilteredItems(this.submitFormAction, [
        type,
        index,
        url,
        reset
      ])
    }

    return this.submitFormAction(type, index, url, reset, Object.keys(selectedElements))
  }

  updateRelated = (field, index, sourceValue) => {
    if (!field.updateRelated) {
      return
    }

    Object.entries(field.updateRelated).forEach(([type, relateds]) => {
      switch (type) {
        case 'addPropToField':
          const addPropToField = (relateds, index = 0) => {
            const related = relateds[index]

            if (!related) {
              return
            }

            const fieldProps = _.cloneDeep(this.state.fieldProps)

            const currentProp = {}

            currentProp[related.prop] = sourceValue.forRelated[related.valueProp]

            fieldProps[related.fieldName] = {
              ...fieldProps[related.fieldName],
              ...currentProp
            }

            return this.setState({
              fieldProps
            }, () => {
              return addPropToField(relateds, index + 1)
            })
          }

          return addPropToField(relateds)
        default:

      }
    })
  }

  render() {
    const { classes, action, enabled, index } = this.props

    return (
      <div className={classes.root}>
        {action.title ? (
          <div className={classes.label}>{action.title}</div>
        ) : null}
        <div className={classes.container}>
          <Grid container spacing={24}>
            {action.fields.map((field, fieldIndex) => {
              const stateProps = field.name ? this.state.fieldProps[field.name] : {}

              return (
                <BaseField field={field} key={fieldIndex} enabled={enabled} index={index} onChange={(value, index, fieldName, sourceValue) => {
                  this.updateRelated(field, index, sourceValue)

                  return this.onChange(value, index, fieldName)
                }} {...stateProps} onSubmit={(type, index, actionType) => this.onSubmit(type, index, actionType || action.action, field)} />
              )
            })}
          </Grid>
        </div>
      </div>
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.listPageAjaxReducer, ...state.inlineFormReducer })

const mapDispatchToProps = dispatch => ({
  onListPagesAjaxGet: (pageType, pageTypes, params) => {
    dispatch(listPageAjaxActions.listPagesAjaxGet(pageType, pageTypes, params, false))
  },
  onListPageAjaxGet: (pageType, params) => {
    dispatch(listPageAjaxActions.listPageAjaxGet(pageType, params))
  },
  onAddValueToField: (value, index, fieldName) => {
    dispatch(inlineFormActions.addValueToField(value, index, fieldName))
  },
  onSubmitForm: (params = {}, url) => {
    dispatch(inlineFormActions.submitFormPost(params, url, true))
  },
  onResetParams: () => {
    dispatch(inlineFormActions.resetParams())
  },
  onGetFilteredItems: (callback, callbackParams) => {
    dispatch(listPageAjaxActions.getFilteredItems(callback, callbackParams))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Divider)))
