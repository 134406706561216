import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumb from '../Breadcrumb'

import {} from '../../actions'

const styles = theme => ({
  root: {
    position: 'fixed',
    left: theme.appVars.sidebarWidth.main,
    right: 0,
    zIndex: 4,
    top: theme.appVars.navBarHeight,
    minHeight: theme.appVars.breadcrumbsHeight,
    maxHeight: theme.appVars.breadcrumbsHeight,
    transition: 'left .3s ease-in-out',
  },
  sidebarIsOpen: {
    left: theme.appVars.sidebarWidth.open,
  }
})

class TitleBlock extends React.Component {
  render() {
    const { classes, titleBlock, sidebarIsOpen } = this.props

    return (
      <div className={classes.root + (sidebarIsOpen ? ' ' + classes.sidebarIsOpen : '')}>
        <Breadcrumb breadcrumbs={titleBlock.breadcrumbs || []} />
      </div>
    )
  }
}

TitleBlock.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TitleBlock)))
