export const confirmActions = {
  handleOpen: (config, callback, callbackParams) => ({
    type: 'confirm_handle_open',
    config,
    callback,
    callbackParams
  }),
  handleConfirm: () => ({
    type: 'confirm_handle_confirm'
  }),
  handleClose: () => ({
    type: 'confirm_handle_close',
  })
}
