import store from '../stores'
import _ from 'lodash'

import {
  listPageActions
} from '../actions'

const initialState = {
  listPageConfig: null,
  listPages: null,
  listPagination: {},
  requestPagination: {},
  listPageReload: false,
  lastPageType: null,
}

// Vars
let newState = null

const listPageReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'auth_action_logout':
      return _.cloneDeep(initialState)
    case 'list_page_action_reload_list':
      newState = {
        ..._.cloneDeep(state),
        listPageReload: true
      }

      return newState
    case 'list_page_action_load_page':
      newState = _.cloneDeep(state)

      setTimeout(() => {
        return store.dispatch(listPageActions.loadPageCallback(action.callback))
      }, 200)

      return newState
    case 'list_page_action_list_page_page_get':
      newState = action.pageType !== state.lastPageType ? {
        ...initialState,
        lastPageType: action.pageType
      } : {
        ..._.cloneDeep(state),
        listPageConfig: null
      }

      return newState
    case 'list_page_action_list_page_page':
      newState = {
        ..._.cloneDeep(state),
        listPageConfig: {
          ...action.data.data,
          pageType: action.pageType
        }
      }

      return newState
    case 'list_page_action_list_page_pages_get':
      return {
        ...initialState,
        lastPageType: state.lastPageType
      }
    case 'list_page_action_list_page':
      newState = {
        ..._.cloneDeep(state),
        exitForm: false,
        formContent: action.data ? action.data.data : null
      }

      if (newState.formContent) {
        newState.formContent.fields.forEach((field) => {
          if (field.values) {
            field.values.forEach((fieldValue => {
              return fieldValue.id = _.uniqueId('field_id')
            }))
          }
        })
      }

      return newState
    default:
      return state
  }
}

export default listPageReducer
