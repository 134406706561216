import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { agendaActions } from '../../../actions'
import moment from 'moment'
import Event from './Event'
import { Button, CircularProgress } from '@material-ui/core'

const styles = theme => ({
  root: {
    height: '100vh',
    overflowY: 'auto',
    position: 'sticky',
    top: 114,
    scrollbarWidth: 'none',
    borderLeft: '3px solid ' + theme.palette.grey[300],
    overflowStyle: 'none',
    paddingBottom: 90,
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  dayDivider: {
    borderBottom: '1px solid ' + theme.palette.grey[300],
    padding: '8px 16px',
    textTransform: 'uppercase',
    fontSize: '.8rem',
    margin: '.5rem 0'
  },
  todayDivider: {
    color: theme.palette.error.main,
    borderBottom: '2px solid ' + theme.palette.error.main,
  },
  spinner: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
    opacity: '.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: '10px 40px'
  },
  placeholder: {
    padding: '0 30px',
    fontSize: '1rem',
    textAlign: 'center',
    opacity: '.7'
  }
})

class Search extends React.Component {
  state = {
    scrollPosition: 0,
    prevHeight: 0
  }

  componentDidUpdate = prevProps => {
    const { queryString: prevQueryString, selectedDoctors: prevSelectedDoctors } = prevProps

    const { queryString, selectedDoctors } = this.props

    const searchRoot = document.getElementById('search-root')

    if (searchRoot) {
      const { scrollPosition, prevHeight } = this.state

      const newHeight = searchRoot.firstChild.clientHeight

      const heightDiff = newHeight - prevHeight

      const position = !scrollPosition && prevHeight && heightDiff ? scrollPosition + heightDiff : (
        scrollPosition && !prevHeight && heightDiff ? scrollPosition : null
      )

      if (position) {
        searchRoot.scroll({
          top: position,
          left: 0
        })

        this.setState({
          scrollPosition: 0,
          prevHeight: 0
        })
      }
    }

    if (queryString && (queryString !== prevQueryString || selectedDoctors !== prevSelectedDoctors) && queryString.length > 2) {
      this.props.onAgendaSearch({
        day: this.props.day ? this.props.day.format(window.appConfig.dateFormat) : null,
        type: this.props.type,
        selectedDoctors: this.props.selectedDoctors,
        current: this.props.current,
        query: this.props.queryString,
        clear: true,
        date: moment().format(window.appConfig.dateFormat)
      })
    }

    if (!queryString && prevQueryString) {
      this.props.onAgendaSearch({
        preventNewSearch: true,
        clear: true
      })
    }
  }

  loadElements = (previous) => {
    this.setState({
      scrollPosition: previous ? 0 : document.getElementById('search-root').scrollTop,
      prevHeight: previous ? document.getElementById('search-root').firstChild.clientHeight : 0
    })

    this.props.onAgendaSearch({
      day: this.props.day ? this.props.day.format(window.appConfig.dateFormat) : null,
      type: this.props.type,
      selectedDoctors: this.props.selectedDoctors,
      current: this.props.current,
      query: this.props.queryString,
      previous,
      date: previous ? this.props.results.from : this.props.results.to
    })
  }

  renderDayEvents = (eventsMap, date) => {
    const { classes, day, current, selectedDoctors, type } = this.props

    return (
      <span>
        <h6 className={classes.dayDivider}>{moment(date, 'DD/MM/YYYY').format('D MMMM YYYY')}</h6>
        {eventsMap[date].map((event, index) => (
          <Event key={index} event={event} day={day} current={current} type={type} selectedDoctors={selectedDoctors} />
        ))}
      </span>
    )
  }

  render() {
    const { classes, results, queryString } = this.props

    const pastEvents = (results || {}).pastEvents || []

    const futureEvents = (results || {}).futureEvents || []

    const pastEventsMap = {}, futureEventsMap = {}

    pastEvents.forEach(event => {
      pastEventsMap[event.date] = pastEventsMap[event.date] || []

      pastEventsMap[event.date].push(event)
    })

    futureEvents.forEach(event => {
      futureEventsMap[event.date] = futureEventsMap[event.date] || []

      futureEventsMap[event.date].push(event)
    })

    return (
      <div className={classes.root} id='search-root'>
        {results && queryString && queryString.length > 2 ? (
          <span className={classes.container}>
            {results.hasMore.pastEvents && (
              <Button variant='outlined' size='small' className={classes.button} onClick={() => this.loadElements(true)}>
                Carica precedenti
              </Button>
            )}
            {pastEvents.concat(futureEvents).length ? (
              <span>
                {Object.keys(pastEventsMap).map((date, index) => (
                  <div key={index}>
                    {this.renderDayEvents(pastEventsMap, date)}
                  </div>
                ))}
                <h6 className={[classes.todayDivider, classes.dayDivider].join(' ')}>OGGI</h6>
                {Object.keys(futureEventsMap).map((date, index) => (
                  <div key={index}>
                    {this.renderDayEvents(futureEventsMap, date)}
                  </div>
                ))}
              </span>
            ) : (
              <p className={classes.placeholder}>Nessun risultato trovato</p>
            )}
            {results.hasMore.futureEvents && (
              <Button variant='outlined' size='small' className={classes.button} onClick={() => this.loadElements()}>
                Carica successivi
              </Button>
            )}
          </span>
        ) : (
          <div className={classes.spinner}>
            {queryString && queryString.length > 2 ? (
              <CircularProgress />
            ) : (
              <p className={classes.placeholder}>Inserisci almeno 3 lettere per effettuare una ricerca</p>
            )}
          </div>
        )}
      </div>
    )
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onAgendaSearch: (params) => {
    dispatch(agendaActions.agendaSearchCall(params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Search)))
