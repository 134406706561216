import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUITable from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from './TableRow'

import { } from '../../actions'

const styles = theme => ({
  root: {
    padding: '1rem 0'
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '1rem'
  }
})

class Table extends React.Component {
  state = {}

  componentDidMount = () => {
    const { block } = this.props

    const tableControl = block.head ? block.head.filter(cell => {
      return cell.tableControl ? true : false
    })[0] || { value: true } : { value: true }

    this.setState({
      isActive: tableControl.value ? true : false
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { block } = this.props

    const tableControl = block.head ? block.head.find(cell => {
      return cell.tableControl ? true : false
    }) : null

    if (tableControl) {
      const {
        isActive: prevIsActive
      } = prevState

      if (typeof tableControl.value === 'boolean' && prevIsActive !== tableControl.value) {
        this.setState({
          isActive: tableControl.value
        })
      }
    }
  }

  render() {
    const { classes, block, blockIndex, onChange, readonly } = this.props

    const { isActive } = this.state

    return (
      <div className={classes.root}>
        {block.label ? (
          <div className={classes.title}>{block.label}</div>
        ) : ''}
        <MUITable>
          {block.head ? (
            <TableHead>
              <TableRow row={block.head} isActive={isActive} fieldPath={[blockIndex, 'head']} onChange={onChange} {...{ readonly }} />
            </TableHead>
          ) : null}
          {block.body ? (
            <TableBody>
              {block.body.map((row, index) => {
                const isHidden = row.some(item => item.hidden)

                return (
                  <TableRow row={row} key={index} inBody={true} isActive={isActive} isHidden={isHidden} fieldPath={[blockIndex, 'body', index]} onChange={onChange} {...{ readonly }} />
                )
              })}
            </TableBody>
          ) : null}
        </MUITable>
      </div>
    )
  }
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Table)))
