import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'

import {
  agendaActions
} from '../../../../actions'

const styles = theme => ({
  root: {
    paddingTop: '.5rem',
    borderTop: '1px solid ' + theme.palette.grey[100],
    marginBottom: '1.5rem'
  },
  title: {
    fontWeight: 600,
    marginBottom: '.5rem'
  },
  tableCell: {
    padding: '.5rem 0',
    whiteSpace: 'nowrap',
    width: 1
  },
  tableRow: {
    height: 'auto'
  },
  checkbox: {
    padding: 0
  }
})

class Event extends React.Component {
  state = {}

  componentDidMount = () => {
    const { workers } = this.props

    this.setState({
      checked: workers.map(worker => {
        return worker.visited
      })
    })
  }

  render() {
    const { workers, eventId, classes, onChange, path } = this.props

    const { checked = [] } = this.state

    if (!workers || !workers.length) {
      return ''
    }

    return (
      <div className={classes.root}>
        <div className={classes.title}>Lavoratori da convocare</div>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Visitato
              </TableCell>
              <TableCell className={classes.tableCell}>
                Numero
              </TableCell>
              <TableCell className={classes.tableCell}>
                Orario
              </TableCell>
              <TableCell className={classes.tableCell}>
                Lavoratore
              </TableCell>
              <TableCell className={classes.tableCell}>
                Medico
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workers.map((worker, index) => {
              return (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Checkbox
                      checked={checked[index] || false}
                      className={classes.checkbox}
                      color='primary'
                      onChange={e => {
                        const tmpChecked = _.cloneDeep(checked)

                        tmpChecked[index] = e.target.checked

                        this.props.onUpdateItem(worker.__path, [
                          {
                            key: 'visited',
                            value: e.target.checked
                          }
                        ])

                        this.setState({
                          checked: tmpChecked
                        })

                        const tmpEvent = {
                          id: worker.id,
                          path: [
                            ...path,
                            'workers',
                            index
                          ],
                          visited: tmpChecked[index],
                          eventId
                        }

                        onChange(tmpEvent)

                        this.props.onWorkerAgendaSave(tmpEvent)
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {index + 1}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {worker.time}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {worker.worker.name} - {worker.worker.cf}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {worker.doctor.name}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

Event.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onWorkerAgendaSave: (data) => {
    dispatch(agendaActions.workerAgendaSave(data))
  },
  onUpdateItem: (path, props) => {
    dispatch(agendaActions.updateItem(path, props))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Event)))
