export const config = {
  merluzzi: [
    {
      guid: 1,
      progressivo: 1,
      Metodo: 'MPB-02',
      lettera: 'a',
      min: -10,
      max: 14,
      hertz: 3000
    },
    {
      guid: 2,
      progressivo: 2,
      Metodo: 'MPB-02',
      lettera: 'c',
      min: 16,
      max: 29,
      hertz: 3000
    },
    {
      guid: 3,
      progressivo: 3,
      Metodo: 'MPB-02',
      lettera: 'e',
      min: 30,
      max: 49,
      hertz: 3000
    },
    {
      guid: 4,
      progressivo: 4,
      Metodo: 'MPB-02',
      lettera: 'g',
      min: 50,
      max: 69,
      hertz: 3000
    },
    {
      guid: 5,
      progressivo: 5,
      Metodo: 'MPB-02',
      lettera: 'i',
      min: 70,
      max: 110,
      hertz: 3000
    },
    {
      guid: 6,
      progressivo: 6,
      Metodo: 'MPB-02',
      lettera: 'b',
      min: -10,
      max: 14,
      hertz: 4000
    },
    {
      guid: 7,
      progressivo: 7,
      Metodo: 'MPB-02',
      lettera: 'd',
      min: 15,
      max: 29,
      hertz: 4000
    },
    {
      guid: 8,
      progressivo: 8,
      Metodo: 'MPB-02',
      lettera: 'f',
      min: 30,
      max: 49,
      hertz: 4000
    },
    {
      guid: 9,
      progressivo: 9,
      Metodo: 'MPB-02',
      lettera: 'h',
      min: 50,
      max: 69,
      hertz: 4000
    },
    {
      guid: 10,
      progressivo: 10,
      Metodo: 'MPB-02',
      lettera: 'l',
      min: 70,
      max: 110,
      hertz: 4000
    },
    {
      guid: 11,
      progressivo: 11,
      Metodo: 'MERL-79',
      lettera: 'a',
      min: -10,
      max: 25,
      hertz: 3000
    },
    {
      guid: 12,
      progressivo: 12,
      Metodo: 'MERL-79',
      lettera: 'e',
      min: 26,
      max: 50,
      hertz: 3000
    },
    {
      guid: 13,
      progressivo: 13,
      Metodo: 'MERL-79',
      lettera: 'f',
      min: 51,
      max: 120,
      hertz: 3000
    },
    {
      guid: 14,
      progressivo: 14,
      Metodo: 'MERL-79',
      lettera: 'b',
      min: -10,
      max: 25,
      hertz: 4000
    },
    {
      guid: 15,
      progressivo: 15,
      Metodo: 'MERL-79',
      lettera: 'c',
      min: 26,
      max: 50,
      hertz: 4000
    },
    {
      guid: 16,
      progressivo: 16,
      Metodo: 'MERL-79',
      lettera: 'd',
      min: 51,
      max: 120,
      hertz: 4000
    }
  ],
  mpb: [
    {
      guid: 1,
      progressivo: 1,
      Metodo: 'MPB-02',
      lettera: 'a',
      min: -10,
      max: 14,
      hertz: 3000
    },
    {
      guid: 2,
      progressivo: 2,
      Metodo: 'MPB-02',
      lettera: 'c',
      min: 16,
      max: 29,
      hertz: 3000
    },
    {
      guid: 3,
      progressivo: 3,
      Metodo: 'MPB-02',
      lettera: 'e',
      min: 30,
      max: 49,
      hertz: 3000
    },
    {
      guid: 4,
      progressivo: 4,
      Metodo: 'MPB-02',
      lettera: 'g',
      min: 50,
      max: 69,
      hertz: 3000
    },
    {
      guid: 5,
      progressivo: 5,
      Metodo: 'MPB-02',
      lettera: 'i',
      min: 70,
      max: 110,
      hertz: 3000
    },
    {
      guid: 6,
      progressivo: 6,
      Metodo: 'MPB-02',
      lettera: 'b',
      min: -10,
      max: 14,
      hertz: 4000
    },
    {
      guid: 7,
      progressivo: 7,
      Metodo: 'MPB-02',
      lettera: 'd',
      min: 15,
      max: 29,
      hertz: 4000
    },
    {
      guid: 8,
      progressivo: 8,
      Metodo: 'MPB-02',
      lettera: 'f',
      min: 30,
      max: 49,
      hertz: 4000
    },
    {
      guid: 9,
      progressivo: 9,
      Metodo: 'MPB-02',
      lettera: 'h',
      min: 50,
      max: 69,
      hertz: 4000
    },
    {
      guid: 10,
      progressivo: 10,
      Metodo: 'MPB-02',
      lettera: 'l',
      min: 70,
      max: 110,
      hertz: 4000
    }
  ],
  note: {
    125: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    250: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    500: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 1.25,
      35: 2.5,
      40: 5,
      45: 7.5,
      50: 11.25,
      55: 15,
      60: 17.5,
      65: 18.75,
      70: 20,
      75: 21.25,
      80: 22.5,
      85: 23.75,
      90: 25,
      95: 25,
      100: 25,
      105: 25,
      110: 25,
      115: 25,
      120: 25
    },
    750: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    1000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 1.5,
      35: 3,
      40: 6,
      45: 9,
      50: 13.5,
      55: 18,
      60: 21,
      65: 22.5,
      70: 24,
      75: 25.5,
      80: 27,
      85: 28.5,
      90: 30,
      95: 30,
      100: 30,
      105: 30,
      110: 30,
      115: 30,
      120: 30
    },
    1500: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    2000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 1.75,
      35: 3.5,
      40: 7,
      45: 10.5,
      50: 15.75,
      55: 21,
      60: 24.5,
      65: 26.25,
      70: 28,
      75: 29.75,
      80: 31.5,
      85: 33.25,
      90: 35,
      95: 35,
      100: 35,
      105: 35,
      110: 35,
      115: 35,
      120: 35
    },
    3000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0.4,
      35: 0.8,
      40: 1.6,
      45: 2.4,
      50: 3.6,
      55: 4.8,
      60: 5.6,
      65: 6,
      70: 6.4,
      75: 6.8,
      80: 7.2,
      85: 7.6,
      90: 8,
      95: 8,
      100: 8,
      105: 8,
      110: 8,
      115: 8,
      120: 8
    },
    4000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0.1,
      35: 0.2,
      40: 0.4,
      45: 0.6,
      50: 0.9,
      55: 1.2,
      60: 1.4,
      65: 1.5,
      70: 1.6,
      75: 1.7,
      80: 1.8,
      85: 1.9,
      90: 2,
      95: 2,
      100: 2,
      105: 2,
      110: 2,
      115: 2,
      120: 2
    },
    6000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    8000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    },
    11000: {
      0: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
      30: 0,
      35: 0,
      40: 0,
      45: 0,
      50: 0,
      55: 0,
      60: 0,
      65: 0,
      70: 0,
      75: 0,
      80: 0,
      85: 0,
      90: 0,
      95: 0,
      100: 0,
      105: 0,
      110: 0,
      115: 0,
      120: 0
    }
  }
}
