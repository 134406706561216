import store from '../stores'
import { ajaxCall } from '../utils'

import {
  messageActions
} from '../actions'

const { appConfig } = store.getState().appReducer

export const agendaActions = {
  agendaFiltersGetCall: params => {
    ajaxCall({
      config: {
        method: 'get',
        url: appConfig.actions.calendar.filters.path,
        params
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }

        store.dispatch(agendaActions.agendaFiltersGet(res.data))
      },
      error: (res) => {
        // TODO
      },
      activeLoading: true
    })

    return ({
      type: 'agenda_action_agenda_filters_get_call'
    })
  },
  agendaFiltersGet: data => ({
    type: 'agenda_action_agenda_filters_get',
    data
  }),
  agendaDataGetCall: params => {
    ajaxCall({
      config: {
        method: 'get',
        url: appConfig.actions.calendar.get.path,
        params
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }

        store.dispatch(agendaActions.agendaDataGet({
          ...res.data,
          callback: params ? params.callback : null
        }))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'agenda_action_agenda_data_get_call'
    })
  },
  agendaDataGet: data => ({
    type: 'agenda_action_agenda_data_get',
    data
  }),
  updateItem: (path, props, unset) => ({
    type: 'agenda_action_update_item',
    path,
    unset,
    props
  }),
  updateSelectedEvent: data => ({
    type: 'agenda_action_update_selected_event',
    data
  }),
  agendaDelete: data => {
    ajaxCall({
      config: {
        method: 'post',
        url: appConfig.actions.calendar.delete.path,
        data
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }

        store.dispatch(agendaActions.agendaDataGetCall(data))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'agenda_action_agenda_delete_post'
    })
  },
  agendaSaveError: data => ({
    type: 'agenda_action_agenda_save_errors',
    data
  }),
  workerAgendaSave: data => {
    ajaxCall({
      config: {
        method: 'post',
        url: appConfig.actions.calendar.workerAgendaSavePost.path,
        data
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }
      },
      error: (err) => {
        const tmpErrorBody = err.response.data.data

        if ((tmpErrorBody || {}).isJoi) {
          store.dispatch(agendaActions.agendaSaveError(tmpErrorBody))
        }
      },
      activeLoading: false
    })

    return ({
      type: 'agenda_action_worker_agenda_save_post'
    })
  },
  agendaSave: data => {
    ajaxCall({
      config: {
        method: 'post',
        url: appConfig.actions.calendar.save.path,
        data
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }

        store.dispatch(agendaActions.agendaCloseEvent())

        store.dispatch(agendaActions.agendaDataGetCall(data))
      },
      error: (err) => {
        const tmpErrorBody = err.response.data.data

        if (tmpErrorBody.isJoi) {
          store.dispatch(agendaActions.agendaSaveError(tmpErrorBody))
        }
      },
      activeLoading: true
    })

    return ({
      type: 'agenda_action_agenda_save_post'
    })
  },
  agendaSearch: data => ({
    type: 'agenda_action_agenda_search',
    data
  }),
  agendaSearchCall: params => {
    if (!params.preventNewSearch) {
      ajaxCall({
        config: {
          method: 'get',
          url: appConfig.actions.calendar.search.path,
          params
        },
        success: (res) => {
          if (res.data.message) {
            store.dispatch(messageActions.showMessage({
              message: res.data.message,
              type: res.data.status,
              autoHide: true
            }))
          }

          store.dispatch(agendaActions.agendaSearch(res.data))
        },
        error: (res) => {
          // TODO
        },
        activeLoading: false
      })
    }

    return ({
      type: 'agenda_action_agenda_search_call',
      data: {
        clear: params.clear
      }
    })
  },
  agendaCloseEvent: data => ({
    type: 'agenda_action_agenda_close_event',
    data
  }),
  agendaSetActiveEvent: data => ({
    type: 'agenda_action_agenda_set_active_event',
    data
  }),
}
