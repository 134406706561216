import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../actions'

const styles = theme => ({
  labelBlock: {
    padding: '0 .5rem 1rem',
    textTransform: 'uppercase'
  },
})

class Label extends React.Component {
  render() {
    const { classes, action } = this.props

    return (
      <div className={classes.labelBlock}>{action.label}</div>
    )
  }
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Label)))
