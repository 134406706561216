import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AsyncSelectField from './AsyncSelect'
import _ from 'lodash'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    margin: '-12px 0 !important',
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 3,
      top: '50%',
      marginTop: -2,
      left: -5,
      right: -5,
      zIndex: -1,
      backgroundColor: theme.palette.common.white
    }
  },
  labelActive: {
    transform: 'translate(14px, 0px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: state.isFocused ? '#43425D' : '#c4c4c4',
      borderRadius: 4,
      backgroundColor: state.isDisabled ? '#fafafa' : 'transparent',
      opacity: state.isDisabled ? '.8' : 1,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#43425D',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      margin: '.5rem 0 0',
      flexGrow: 1,
      width: '100%',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      flexGrow: 1,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: 0,
      flexGrow: 1,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
    }),
  }
}

class SelectField extends React.Component {
  state = {
    focused: false,
    selectedValue: ''
  }

  componentDidMount() {
    const { value, values } = this.props.field

    this.setState({
      selectedValue: (values || value) || ''
    })
  }

  render() {
    const { field, classes, noOptionsMessage, selectClasses, index, onChange } = this.props

    const newClasses = {
      ...classes,
      ...selectClasses
    }

    if (typeof field.options === 'string') {
      return <AsyncSelectField {...this.props} />
    }

    const options = typeof field.options !== 'string' ? field.options : []

    const { focused, selectedValue } = this.state

    const activeLabel = selectedValue ? (_.isArray(selectedValue) ? selectedValue.length > 0 || focused : true) : focused

    const labelActive = (activeLabel ? ' ' + newClasses.labelActive : '')

    return (
      <FormControl fullWidth={true} className={newClasses.root}>
        {field.label ? (
          <InputLabel variant='outlined' shrink={field.placeholder} className={newClasses.label + labelActive}>{field.label}</InputLabel>
        ) : ''}
        <Select
          variant='outlined'
          defaultValue={field.value}
          styles={customStyles()}
          isDisabled={field.disabled}
          options={options}
          inputId={_.uniqueId()}
          placeholder={field.placeholder || false}
          isMulti={field.isMulti ? true : false}
          isClearable={field.isClearable ? true : false}
          onChange={(values) => {
            const selectedValue = _.isArray(values) ? values.map(value => {
              return value.value
            }) : (values.value || Number.isInteger(values.value) ? '' + values.value : null)

            this.setState({
              selectedValue
            })

            onChange(selectedValue, index, field.name, values)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          noOptionsMessage={() => noOptionsMessage ? noOptionsMessage.noResults : null} />
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectField)))
