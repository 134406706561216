import { config as globalTheoryConfig } from './config'
import { messages } from '../helpers'
import { commonCalculate, getCecaErsPaoletti } from './common'

export const ceca = (config) => {
  const theoryConfig = globalTheoryConfig['ceca_' + config.sex.toLowerCase()]

  if (!theoryConfig) {
    throw new Error(messages.configNotFound('ceca_' + config.sex.toLowerCase()))
  }

  return commonCalculate(getCecaErsPaoletti(theoryConfig, config))
}
