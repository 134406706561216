import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import MIcon from '../Icon'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import _ from 'lodash'

import {} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end'
  },
  icon: {
    fontSize: '.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 24,
    lineHeight: '24px'
  },
  tooltipRoot: {
    fontSize: '1rem'
  },
  leftIcon: {
    marginRight: '.5rem'
  }
})

class TableActions extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClickActionsMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  getAction = (action) => {
    const { anchorEl } = this.state

    const open = Boolean(anchorEl)

    const { appConfig, itemId: tmpItemId, classes, onClickAction, item } = this.props

    const itemId = action.id || (action.postfix ? item[action.postfix] : tmpItemId)

    switch (action.type) {
      case 'menu':
        return (
          <div>
            <IconButton
              aria-label='More'
              aria-owns={open ? 'long-menu' : undefined}
              aria-haspopup='true'
              onClick={this.handleClickActionsMenu} >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              onClose={() => {
                return this.setState({
                  anchorEl: null
                })
              }}
              open={open} >
              {action.actions.map((innerAction, innerActionIndex) => {
                return (
                  <MenuItem key={innerActionIndex} style={innerAction.iconColor ? {
                    color: innerAction.iconColor
                  } : {}} component={NavLink} to={appConfig.appPrefix + innerAction.action + '/' + itemId}>
                    <ListItemIcon style={innerAction.iconColor ? {
                      color: innerAction.iconColor
                    } : {}}>
                      <MIcon>{innerAction.icon}</MIcon>
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {innerAction.label}
                    </Typography>
                  </MenuItem>
                )
              })}
            </Menu>
          </div>
        )
      default:
        let actionProps = {
          component: NavLink,
          to: appConfig.appPrefix + action.action + '/' + itemId
        }

        switch (action.type) {
          case 'inner':
            actionProps = {
              onClick: () => onClickAction(action, itemId, false, item)
            }

            break
          case 'download':
            actionProps = {
              href: action.url + itemId,
              target: '_blank',
              rel: 'noopener noreferrer',
              download: item[action.download] ? item[action.download] + '.xml' : true
            }

            if (action.urlType === 'api') {
              actionProps.href = appConfig.apiUrl + actionProps.href
            }

            break
          case 'blank':
            actionProps = {
              href: action.action + '/' + (action.postfix ? item[action.postfix] : itemId),
              target: '_blank',
              rel: 'noopener noreferrer'
            }

            break
          default:
            break
        }

        switch (action.buttonType) {
          case 'button':
            return (
              <Button variant='contained' style={{
                backgroundColor: action.iconColor,
                color: '#fff'
              }} {...actionProps}>
                <MIcon className={classes.leftIcon}>{action.icon}</MIcon>
                {action.label}
              </Button>
            )
          default:
            return (
              <IconButton
                aria-label={action.label}
                {...actionProps} >
                <span className={classes.icon} style={{ color: action.icon === 'pen-to-square' ? '#428bca' : action.iconColor }}>
                  <MIcon>{action.icon}</MIcon>
                </span>
              </IconButton>
            )
        }
    }
  }

  render() {
    const { actions, classes, item } = this.props

    return (
      <div className={classes.root}>
        {actions.map((action, actionIndex) => {
          if (_.isEmpty(action) || (action.hideIf && item[action.hideIf])) {
            return null
          }

          const hasTooltip = action.buttonType === 'button' ? !(!action.tooltip) : !(!action.label)

          if (hasTooltip) {
            return (
              <Tooltip title={action.tooltip || action.label} classes={{
                tooltip: classes.tooltipRoot
              }} placement='left' key={actionIndex}>
                {this.getAction(action)}
              </Tooltip>
            )
          }

          return (
            <div key={actionIndex}>
              {this.getAction(action)}
            </div>
          )
        })}
      </div>
    )
  }
}

TableActions.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableActions)))
