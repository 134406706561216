import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HeaderLogin from '../../components/HeaderLogin'
import Icon from '../../components/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import _ from 'lodash'

import {
  authActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    textAlign: 'center'
  },
  form: {
    minWidth: 350,
    maxWidth: 350
  },
  buttonsGrid: {
    paddingTop: '3rem',
    alignItems: 'center'
  },
  navLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  helperText: {
    marginTop: '1.25rem',
    fontSize: '.875rem',
    '& ul': {
      marginBottom: 0,
      paddingLeft: '1rem'
    }
  }
})

class ResetPassword extends React.Component {
  state = {
    currentPassword: '',
    newPassword: '',
    newRePassword: '',
    inputType: 'password'
  }

  handleChange = (event, name) => {
    let newState = _.cloneDeep(this.state)

    newState[name] = event.target.value

    return this.setState(newState)
  }

  render() {
    const {
      classes,
      appConfig,
      resetPassword
    } = this.props

    const {
      currentPassword,
      newPassword,
      newRePassword,
      inputType,
      canCheckNewPassword
    } = this.state

    const {
      strict,
      strictInfo,
      firstAccess,
      isResetPassword
    } = appConfig.userConfig.user.password

    const canSubmit = (!!currentPassword || firstAccess) && !!newPassword && newPassword === newRePassword

    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={(event) => {
          event.preventDefault()

          return this.props.onUpdatePasswordPost(this.state)
        }}>
          <HeaderLogin />
          <div className={classes.info}>
            {isResetPassword ? (
              <>
                Cambia la password per poter accedere ad Achille&copy;
              </>
            ) : firstAccess ? (
              <>
                La password usata è quella di default.<br />
                Cambiala per poter accedere ad Achille&copy;
              </>
            ) : (
              <>
                La tua password è scaduta.<br /> Reimpostala per poter accedere ad Achille&copy;.
              </>
            )}
          </div>
          {!firstAccess ? (
            <TextField
              fullWidth
              id='old_password'
              type={inputType}
              label='Password attuale'
              className={classes.textField}
              value={currentPassword}
              margin='normal'
              error={resetPassword.error === 'old_password'}
              helperText={resetPassword.error === 'old_password' && 'La password attuale non corrisponde'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' onClick={() => {
                    return this.setState({
                      inputType: inputType === 'text' ? 'currentPassword' : 'text'
                    })
                  }}>
                    <Icon>{inputType === 'text' ? 'eye' : 'eye-slash'}</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                this.props.onResetPasswordReducer()

                return this.handleChange(event, 'currentPassword')
              }}
            />
          ) : ''}
          <TextField
            fullWidth
            id='new_password'
            type={inputType}
            label='Nuova password'
            className={classes.textField}
            value={newPassword}
            margin='normal'
            error={resetPassword.error === 'new_password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' onClick={() => {
                  return this.setState({
                    inputType: inputType === 'text' ? 'newPassword' : 'text'
                  })
                }}>
                  <Icon>{inputType === 'text' ? 'eye' : 'eye-slash'}</Icon>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              this.props.onResetPasswordReducer()

              return this.handleChange(event, 'newPassword')
            }}
            FormHelperTextProps={{
              component: 'div',
              className: classes.helperText
            }}
            helperText={strict && <div dangerouslySetInnerHTML={{
              __html: strictInfo
            }}></div>}
          />
          <TextField
            fullWidth
            id='new_re_password'
            type={inputType}
            label='Ripeti nuova password'
            className={classes.textField}
            value={newRePassword}
            margin='normal'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' onClick={() => {
                  return this.setState({
                    inputType: inputType === 'text' ? 'newRePassword' : 'text'
                  })
                }}>
                  <Icon>{inputType === 'text' ? 'eye' : 'eye-slash'}</Icon>
                </InputAdornment>
              ),
            }}
            error={canCheckNewPassword && !!newPassword && newPassword !== newRePassword}
            onChange={(event) => {
              this.setState({
                canCheckNewPassword: true
              })

              return this.handleChange(event, 'newRePassword')
            }}
            helperText={canCheckNewPassword && !!newPassword && newPassword !== newRePassword && 'La nuova password non corrisponde'}
          />
          <Grid container spacing={16} className={classes.buttonsGrid}>
            <Grid item xs={6}>
              <Button fullWidth variant='contained' color='primary' type='submit' disabled={!canSubmit}>Salva</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.authReducer })

const mapDispatchToProps = dispatch => ({
  onResetPasswordReducer: () => {
    return dispatch(authActions.resetPasswordReducer())
  },
  onUpdatePasswordPost: (data) => {
    return dispatch(authActions.updatePasswordPost(data))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ResetPassword)))
