import store from '../stores'
import { ajaxCall } from '../utils'

import {
  messageActions
} from '../actions'

export const commonActions = {
  callPost: (config, callback, callbackParams) => {
    ajaxCall({
      config: {
        method: 'post',
        ...config
      },
      success: (res) => {
        if (typeof callback === 'function') {
          const toReturn = [res.data]

          if (callbackParams) {
            toReturn.unshift(callbackParams)
          }

          callback(...toReturn)
        }

        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'common_action_call_post'
    })
  }
}
