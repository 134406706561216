import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'
import Select from './Select/Select'

import { } from '../../actions'
import { clsx } from '../../utils'

const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'flex-start',
    flexGrow: 1,
    paddingBottom: '1rem',
    borderBottom: '2px solid ' + theme.palette.grey[50],
  },
  label: {
    backgroundColor: theme.palette.grey[50],
    padding: '.5rem 1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '0.9285714285714286rem'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  buttonRoot: {
    display: 'flex',
    margin: '1em .25rem 0'
  },
  buttonRootToDo: {
    border: '2px solid ' + theme.palette.warning.main
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    width: 90,
    alignItems: 'center',
    textAlign: 'center'
  },
  buttonLabel: {
    display: 'block',
    fontSize: '.6875rem'
  }
})

class ActionList extends React.Component {
  onChange = (currentValue, action) => {
    const { appConfig, history } = this.props

    if (currentValue.value !== action.value.value) {
      return history.push(appConfig.appPrefix + currentValue.value)
    }

    return
  }

  render() {
    const { classes, field, onActionClick, readonly } = this.props

    return (
      <div className={classes.root}>
        {field.label ? (
          <div className={classes.label}>{field.label}</div>
        ) : null}
        <div className={classes.container}>
          {field.values.map((action, index) => {
            switch (action.type) {
              case 'select':
                return (
                  <Select
                    field={action}
                    key={index}
                    onChange={(type, currentField, currentValue) => this.onChange(currentValue, action)}
                  />
                )
              default:
                return (
                  <Button
                    variant='contained'
                    color={action.active ? 'primary' : null}
                    classes={{
                      root: clsx([
                        classes.buttonRoot,
                        !action.active && action.isToDo ? classes.buttonRootToDo : ''
                      ]),
                      label: classes.button
                    }}
                    onClick={() => onActionClick(action)}
                    key={index}
                    disabled={!action.active && (readonly || field.disabled)}
                  >
                    <Icon>{action.icon || 'ghost'}</Icon>
                    <span className={classes.buttonLabel}>{action.label}</span>
                  </Button>
                )
            }
          })}
        </div>
      </div>
    )
  }
}

ActionList.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ActionList)))
