import store from '../stores'
import { ajaxCall } from '../utils'
import _ from 'lodash'
import { messageActions } from './Message'

const { appConfig } = store.getState().appReducer

export const listPageActions = {
  updatePagination: (paginationConfig, pageType) => ({
    type: 'list_page_action_pagination_config',
    paginationConfig,
    pageType
  }),
  loadPage: (pageType, params, callback) => ({
    type: 'list_page_action_load_page',
    pageType,
    params,
    callback
  }),
  loadPageCallback: callback => {
    callback()

    return {
      type: 'list_page_action_load_page_callback'
    }
  },
  listPageDefaultActionPost: (action, pageType, itemId, params, item) => {
    const cCAction = _.camelCase([pageType, action.action, 'post'].join('-'))

    const url = action.custom ? appConfig.actions[action.pageType][action.action].path : appConfig.actions[pageType][cCAction].path

    const payload = action.custom ? item : { id: itemId }

    ajaxCall({
      config: {
        url,
        method: 'post',
        params,
        data: payload
      },
      success: (res) => {
        if (action.custom) {
          return store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: res.data.status,
            autoHide: true
          }))
        }

        store.dispatch(listPageActions.listPageChange(res.data, itemId))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_action_list_page_default_action_post'
    })
  },
  listPageChange: (data, itemId) => ({
    type: 'list_page_action_list_page_change',
    data,
    itemId
  }),
  listPageDeletePost: (pageType, itemId, params, reloadPageTemplate) => {
    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'DeletePost'].path,
        method: 'post',
        params,
        data: {
          id: itemId
        }
      },
      success: (res) => {
        store.dispatch(listPageActions.listPageDelete(res.data, itemId, reloadPageTemplate))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_action_list_page_delete_post'
    })
  },
  listPageDelete: (data, itemId, reloadPageTemplate) => ({
    type: 'list_page_action_list_page_delete',
    data,
    reloadPageTemplate,
    itemId
  }),
  listPageGet: (pageType, params) => {
    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'PageGet'].path,
        params
      },
      success: (res) => {
        store.dispatch(listPageActions.listPage(res.data, pageType))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_action_list_page_page_get',
      pageType
    })
  },
  listPage: (data, pageType) => ({
    type: 'list_page_action_list_page_page',
    data,
    pageType
  }),
  listPagesGet: (pageType, pageTypes, params, reload = true, append = false, activeLoading = true) => {
    const url = (appConfig.actions[pageType][(pageTypes || pageType) + 'sGet'] || {}).path

    if (url) {
      ajaxCall({
        config: {
          url,
          params
        },
        success: (res) => {
          store.dispatch(listPageActions.listPages(res.data, append, pageType, params))
        },
        error: () => {},
        activeLoading: activeLoading
      })
    }

    return ({
      type: reload ? append ? 'list_page_action_list_page_pages_append' : 'list_page_action_list_page_pages_get' : 'list_page_action_list_page_pages_not_reload'
    })
  },
  reloadList: () => ({
    type: 'list_page_action_reload_list'
  }),
  listPages: (data, append, pageType, params) => ({
    type: 'list_page_action_list_pages',
    data,
    append,
    pageType,
    params
  })
}
