export const isInViewPort = (domElement, offsetTop = 0, offsetLeft = 0) => {
  const rect = domElement.getBoundingClientRect()

  const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth)

  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height - offsetTop) >= 0)
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width - offsetLeft) >= 0)

  return vertInView && horInView
}
