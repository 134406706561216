import store from '../stores'
import { ajaxCall } from '../utils'

const { appConfig } = store.getState().appReducer

export const printActions = {
  printPost: (url, data) => {
    ajaxCall({
      config: {
        url: url,
        method: 'post',
        data: {
          ...data,
          savePdf: '1'
        }
      },
      success: (res) => {
        store.dispatch(printActions.print(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'print_init_post'
    })
  },
  printStatus: (progress, params) => ({
    type: 'print_status',
    progress,
    params
  }),
  printStatusWaiting: (params) => ({
    type: 'print_status_waiting',
    params
  }),
  printStatusError: (message, params) => ({
    type: 'print_status_error',
    message,
    params
  }),
  printStatusDone: (pdf, params) => ({
    type: 'print_status_done',
    pdf,
    params
  }),
  printStatusRemove: params => ({
    type: 'print_status_remove',
    params
  }),
  printStatusGet: params => {
    ajaxCall({
      config: {
        url: appConfig.actions.print.printStatus.path,
        method: 'get',
        params
      },
      success: res => {
        const {
          data
        } = res.data

        const timeoutId = Object.values(params).join('-')

        if (data.customPrintName) {
          params.customPrintName = data.customPrintName
        }

        switch (data.status) {
          case 'waiting':
          case 'progress':
            if (data.status === 'waiting') {
              store.dispatch(printActions.printStatusWaiting(params))
            } else {
              store.dispatch(printActions.printStatus(data.progress, params))
            }

            if (!appConfig.timeouts.printStatus) {
              appConfig.timeouts.printStatus = {}
            }

            if (appConfig.timeouts.printStatus[timeoutId]) {
              clearTimeout(appConfig.timeouts.printStatus[timeoutId])
            }

            return appConfig.timeouts.printStatus[timeoutId] = setTimeout(() => {
              store.dispatch(printActions.printStatusGet(params))
            }, appConfig.actions.print.printStatus.timeout)
          case 'error':
            return store.dispatch(printActions.printStatusError(data.message, params))
          default:
            return store.dispatch(printActions.printStatusDone(data.pdf, params))
        }
      },
      error: () => {
        // store.dispatch(printActions.printStatus(res.data, error))
      },
      activeLoading: false
    })

    return ({
      type: 'print_status_get'
    })
  },
  printClose: () => ({
    type: 'print_close'
  }),
  print: (data) => ({
    type: 'print',
    data
  }),
  prints: printStack => {
    (printStack || []).forEach(data => {
      store.dispatch(printActions.print({
        data
      }))
    })

    return {
      type: 'prints'
    }
  },
  printCancel: params => {
    ajaxCall({
      config: {
        url: appConfig.actions.print.printCancel.path,
        method: 'get',
        params
      },
      success: () => {},
      error: () => {},
      activeLoading: false
    })

    return ({
      type: 'print_cancel',
      params
    })
  }
}
