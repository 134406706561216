import store from '../stores'
import { ajaxCall } from '../utils'
import { messageActions } from './Message'

const { appConfig } = store.getState().appReducer

export const authActions = {
  resetPasswordReducer: () => ({
    type: 'auth_action_reset_password_reducer'
  }),
  updatePassword: data => ({
    type: 'auth_action_update_password',
    data
  }),
  hasLocalDbs: data => ({
    type: 'auth_action_has_local_dbs',
    data
  }),
  hasLocalDbsGet: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.user.hasLocalDbs.path,
        method: 'get'
      },
      success: res => {
        store.dispatch(authActions.hasLocalDbs(res.data))
      },
      preventToken: true
    })

    return {
      type: 'auth_action_has_local_dbs_get'
    }
  },
  updatePasswordPost: data => {
    ajaxCall({
      config: {
        url: appConfig.actions.user.updatePassword.path,
        method: 'post',
        data: data
      },
      success: res => {
        if (res.data.error) {
          store.dispatch(messageActions.showMessage({
            message: res.data.message,
            type: 'error',
            autoHide: true
          }))

          return store.dispatch(authActions.updatePassword(res.data))
        }

        store.dispatch(messageActions.showMessage({
          message: res.data.message,
          type: 'success',
          autoHide: true
        }))

        setTimeout(() => {
          window.location.reload()
        }, 500)
      },
      activeLoading: true
    })

    return {
      type: 'auth_action_update_password_post'
    }
  },
  localDbsProgress: (progress) => ({
    type: 'auth_action_local_dbs_progress',
    progress
  }),
  localDbsProgressGet: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.localDbsProgressGet.path
      },
      success: (res) => {
        const {
          size,
          progress
        } = res.data.data

        store.dispatch(authActions.localDbsProgress(res.data.data))

        if (!size || size !== progress) {
          setTimeout(() => {
            store.dispatch(authActions.localDbsProgressGet())
          }, 500)
        }
      },
      activeLoading: false
    })

    return ({
      type: 'auth_action_local_dbs_progress_get'
    })
  },
  loginPost: (data) => {
    if (localStorage.getItem('token')) {
      store.dispatch(authActions.login())
    } else {
      localStorage.removeItem('token')

      ajaxCall({
        config: {
          url: appConfig.actions.auth.loginPost.path,
          method: 'post',
          data: data,
          timeout: (60 * 60 * 24) * 1000
        },
        success: (res) => {
          store.dispatch(authActions.login(res.data))
        },
        error: () => {},
        activeLoading: true
      })
    }

    return ({
      type: 'auth_action_login_post'
    })
  },
  resetOtpPost: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.resetOtp.path,
        method: 'post'
      },
      success: (res) => {
        store.dispatch(messageActions.showMessage({
          message: res.data.message,
          type: 'success',
          autoHide: true
        }))
      },
      error: () => { },
      activeLoading: true
    })

    return ({
      type: 'auth_action_reset_otp_post'
    })
  },
  checkOtp: () => ({
    type: 'auth_action_check_otp'
  }),
  checkOtpPost: (data) => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.checkOtp.path,
        method: 'post',
        data
      },
      success: (res) => {
        if (!res.data.verified) {
          return store.dispatch(authActions.checkOtp())
        }

        setTimeout(() => {
          window.location.reload()
        }, 500)
      },
      error: () => { },
      activeLoading: true
    })

    return ({
      type: 'auth_action_check_otp_post'
    })
  },
  login: (res) => ({
    type: 'auth_action_login',
    res
  }),
  logout: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.logoutPost.path,
        method: 'post'
      },
      success: (res) => {
        store.dispatch(authActions.logoutAfterPost(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'auth_action_logout_post'
    }
  },
  logoutAfterPost: () => ({
    type: 'auth_action_logout'
  }),
  passwordRecoveryPost: (username) => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.passwordRecovery.path,
        method: 'post',
        data: {
          username
        }
      },
      success: (res) => {
        store.dispatch(messageActions.showMessage({
          message: res.data.message,
          type: 'success',
          autoHide: true
        }))

        store.dispatch(authActions.passwordRecovery(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'auth_action_password_recovery_post'
    }
  },
  passwordRecovery: () => ({
    type: 'auth_action_password_recovery'
  }),
}
