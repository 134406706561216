import _ from 'lodash'

export const getPagination = pagination => {
  const {
    current,
    rows,
    totalItems
  } = pagination

  let {
    maxPages = 5
  } = pagination

  const halfPages = (Math.ceil(maxPages / 2) - 1)

  const totalPages = Math.ceil(totalItems / rows)

  let first = (current - halfPages) > 3 && totalPages > (maxPages + 3)

  let from = 1

  if (first) {
    from = current - halfPages

    if (totalPages - maxPages - 1 < from) {
      from = totalPages - maxPages - 1
    }

    if (from < 4) {
      from = 1

      first = false
    }
  }

  let to = from + maxPages + (first ? -1 : 1)

  if (to > totalPages - 3) {
    to = totalPages
  }

  const last = to < totalPages

  const prevPage = current > 1 ? current - 1 : 0

  const nextPage = current < totalPages ? current + 1 : 0

  const pages = []

  for (let i = from; i <= to; i++) {
    pages.push(i)
  }

  return {
    first,
    last,
    pages,
    totalPages,
    prevPage,
    nextPage
  }
}

export const setPaginationConfig = (state, paginationConfig, extPaginationConfig) => {
  const tmpPaginationConfig = {}

  const currentPath = window.location.hash

  const blacklist = [
    '#/app/company',
    '#/app/company/worker'
  ]

  const currentPathInBlackList = blacklist.indexOf(currentPath) > -1

  Object.entries(state.paginationConfig || {}).forEach(([key, value]) => {
    if (currentPath.startsWith(key) && !currentPathInBlackList) {
      tmpPaginationConfig[key] = value
    }
  })

  tmpPaginationConfig[currentPath] = {
    ...(paginationConfig || state.paginationConfig[currentPath]),
    ...extPaginationConfig || {}
  }

  return {
    ...state,
    paginationConfig: tmpPaginationConfig
  }
}

export const getPaginationConfig = (paginationConfigs, onlyFilters = false) => {
  const currentPath = window.location.hash

  const toReturn = _.cloneDeep(paginationConfigs[currentPath] || {})

  if (onlyFilters) {
    const propsToRemove = [
      'lastViewedReset',
      'page',
      'pageCount',
      'pageSize',
      'rowCount',
      'selectCache'
    ]

    propsToRemove.forEach(propToRemove => {
      delete toReturn[propToRemove]
    })
  }

  return toReturn
}
