import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUITableCell from '@material-ui/core/TableCell'
import BaseField from './BaseField'

const styles = theme => ({
  root: {
    border: '1px solid #ccc',
    padding: 5,
    '&:last-child': {
      paddingRight: 5
    }
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase'
  }
})

class TableCell extends React.Component {
  render() {
    const { classes, cell, isActive, fieldPath, onChange, rowIsActive, readonly } = this.props

    const { colSpan, rowSpan, styles = {} } = cell

    return (
      <MUITableCell className={classes.root} colSpan={colSpan || 1} rowSpan={rowSpan || 1} style={styles}>
        <BaseField cell={cell} isActive={isActive} rowIsActive={rowIsActive} fieldPath={fieldPath} onChange={onChange} {...{readonly}} />
      </MUITableCell>
    )
  }
}

TableCell.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableCell)))
