import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import NoteField from '../NoteField'

import {
} from '../../actions'

const styles = theme => ({})

class Notes extends React.Component {
  render() {
    const { item } = this.props

    return (
      <NoteField 
        item={item}
      />
    )
  }
}

Notes.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Notes)))
