import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AlertGroup from './AlertGroup'
import ChartGroup from './ChartGroup'
import GridRow from './GridRow'
import Title from './Title'
import Agenda from './Agenda'
import Notes from './Notes'
import ProgressIndicator from './ProgressIndicator'
import Counter from './Counter'

import { } from '../../actions'

const styles = theme => ({})

const types = {
  alertGroup: AlertGroup,
  chartGroup: ChartGroup,
  gridRow: GridRow,
  title: Title,
  agenda: Agenda,
  notes: Notes,
  space: ProgressIndicator,
  counter: Counter
}

class DashboardFields extends React.Component {
  render() {
    const { items } = this.props

    return (
      <Grid container spacing={24}>
        {items.map((item, index) => {
          const TmpType = types[item.type] || null

          if (TmpType) {
            return (
              <Grid key={index} item xs={parseInt(item.size || 12) || 12}>
                <TmpType item={item} />
              </Grid>
            )
          }

          return ''
        })}
      </Grid>
    )
  }
}

DashboardFields.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DashboardFields)))
