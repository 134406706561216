import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MIcon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import {
  appActions,
  logActions
} from '../../actions'

const styles = theme => ({
  root: {
    margin: '1rem'
  },
  terminal: {
    padding: '.5rem',
    backgroundColor: '#000',
    borderRadius: '5px',
    maxHeight: 400
  },
  terminalContent: {
    overflowX: 'hidden',
    color: '#fff'
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 700,
    display: 'block'
  },
  secondaryHeading: {
    margin: '0 .5rem',
    display: 'block'
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

class LogContainer extends React.Component {
  state = {
    currentTab: 0,
    currentAccordion: null
  }

  componentDidMount = () => {
    this.props.onLoadLogData()

    this.props.onChangeTitleBlock({
      title: 'Log',
      breadcrumbs: [
        {
          text: 'Log'
        }
      ]
    })
  }

  changeTab = (event, newHandle) => {
    this.setState({
      currentTab: newHandle
    })
  }

  changeAccordion = (currentAccordion) => {
    const { currentAccordion: stateCurrentAccordion } = this.state

    if (stateCurrentAccordion === currentAccordion) {
      return this.setState({
        currentAccordion: null
      })
    }

    this.setState({
      currentAccordion
    })
  }

  render() {
    const { classes, logData = [] } = this.props

    const { currentTab, currentAccordion } = this.state

    return (
      <Paper className={classes.root}>
        <AppBar position='static'>
          <Tabs value={currentTab} onChange={this.changeTab}>
            {logData.map((day, index) => {
              return (
                <Tab label={day.date} key={index} id={index} />
              )
            })}
          </Tabs>
        </AppBar>
        {logData.map((day, index) => {
          return (
            <div key={index} role='tabpanel' hidden={index !== currentTab}>
              {day.logs.map((log, logIndex) => {
                const currentIndex = 'log-' + index + '-' + logIndex

                return (
                  <ExpansionPanel expanded={currentAccordion === currentIndex} key={currentIndex} onChange={() => this.changeAccordion(currentIndex)}>
                    <ExpansionPanelSummary expandIcon={<MIcon>expand_more</MIcon>} classes={{content: classes.headingContainer}}>
                      <Typography className={classes.heading}>{log.name}</Typography>
                      {log.counts.map((count, countIndex) => {
                        return (
                          <Typography key={countIndex} className={classes.secondaryHeading}>{count.title} ({count.count})</Typography>
                        )
                      })}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.terminal}>
                      <div className={classes.terminalContent} dangerouslySetInnerHTML={{ __html: log.text }}></div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
            </div>
          )
        })}
      </Paper>
    )
  }
}

LogContainer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.logReducer })

const mapDispatchToProps = dispatch => ({
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onLoadLogData: () => {
    dispatch(logActions.loadLogDataAjax())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LogContainer)))
