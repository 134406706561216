import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import logo from '../../assets/img/logo.svg'

const styles = theme => ({
  root: {
    marginBottom: '1rem'
  },
  title: {
    textAlign: 'center',
    marginBottom: '.3rem'
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0
  },
  img: {
    width: 350
  }
})

class HeaderLogin extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <h1 className={classes.title}>
          <img className={classes.img} src={logo} alt={'Achille'} />
        </h1>
      </div>
    )
  }
}

HeaderLogin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(HeaderLogin)
