import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AsyncSelect from 'react-select/async'
import { ajaxCall } from '../../../utils'
import _ from 'lodash'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    margin: '-12px 0 !important',
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1,
      backgroundColor: theme.palette.common.white
    }
  },
  labelActive: {
    transform: 'translate(14px, -6px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = () => ({
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: state.isFocused ? 2 : 1,
    borderColor: state.isFocused ? '#43425D' : '#c4c4c4',
    borderRadius: 4,
    backgroundColor: 'transparent',
    margin: 0,
    minHeight: 41,
    padding: '0 14px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#43425D',
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    fontSize: '0.9285714285714286rem',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
  }),
})

class AsyncSelectField extends React.Component {
  state = {
    focused: false,
    selectedValue: null
  }

  filterOptions = (options, inputValue) => {
    return options ? options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    ) : []
  }

  promiseOptions = inputValue => {
    const { field } = this.props

    return new Promise(resolve => {
      ajaxCall({
        config: {
          url: field.options,
          method: 'get',
          params: {
            query: inputValue
          }
        },
        success: (res) => {
          resolve(this.filterOptions(res.data.data, inputValue))
        },
        error: (res) => {
          // TODO
        },
        activeLoading: false
      })
    })
  }

  render() {
    const { field, classes, onChange, noOptionsMessage, index } = this.props

    const { focused, selectedValue } = this.state

    const activeLabel = selectedValue ? (_.isArray(selectedValue) ? selectedValue.length > 0 || focused : true) : focused

    const labelActive = (activeLabel ? ' ' + classes.labelActive : '')

    const props = {}

    if (noOptionsMessage) {
      props.noOptionsMessage = () => noOptionsMessage.noResults
    }

    return (
      <FormControl fullWidth={true} error={field.error ? true : false} className={classes.root}>
        <InputLabel variant='outlined' className={[
          classes.label,
          labelActive,
          (field.classes || {}).label
        ].join(' ')}>{field.label}</InputLabel>
        <AsyncSelect
          defaultOptions
          defaultValue={field.value}
          loadOptions={this.promiseOptions}
          styles={customStyles()}
          onChange={values => {
            const selectedValue = _.isArray(values) ? values.map(value => {
              return value.value
            }) : values.value || null

            this.setState({
              selectedValue
            })

            return onChange(selectedValue, index, field.name)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          isMulti={field.isMulti || false}
          placeholder={null}
          {...props}
          laceholder='' />
      </FormControl>
    )
  }
}

AsyncSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AsyncSelectField)))
