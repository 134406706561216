import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import * as regularIcons from '@fortawesome/free-regular-svg-icons'
import MIcon from '@material-ui/core/Icon'

const styles = theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '1em',
    maxHeight: '1em'
  },
  icon: {
    display: 'block'
  }
})

class Icon extends React.Component {
  render() {
    const { classes, children, className, far, color, style } = this.props

    if (!children) {
      return null
    }

    const existsFw = icon => {
      const existsSolid = !!Object.values(solidIcons).find(item => {
        return item.iconName === icon
      })

      const existsRegular = !!Object.values(regularIcons).find(item => {
        return item.iconName === icon
      })

      if (existsSolid) {
        return 'fas'
      }

      if (existsRegular) {
        return 'far'
      }

      return null
    }

    const iconType = far ? 'far' : existsFw(children)

    const iconStyle = { ...style }

    if (color) {
      iconStyle.color = color
    }

    return (
      <span className={classes.root + (className ? ' ' + className : '')} style={iconStyle} >
        {iconType ? (
          <FontAwesomeIcon className={classes.icon} icon={[iconType, children]} />
        ) : (
          <MIcon fontSize='inherit'>{children}</MIcon>
        )}
      </span>
    )
  }
}

Icon.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Icon)))
