import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import {} from '../../../actions'

const styles = theme => ({
  checkbox: {
    display: 'flex',
    gap: '2px'
  }
})

class Checkbox extends React.Component {
  render() {
    const { classes, parent, action } = this.props

    const { label } = action

    const { params = {} } = parent.state

    return (
      <label className={classes.checkbox}>
        <input type='checkbox' onChange={e => {
          const currentParams = _.cloneDeep(params)

          currentParams[action.name] = e.target.checked ? 1 : 0

          return parent.setState({
            params: currentParams
          })
        }} />
        {label}
      </label>
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Checkbox)))
