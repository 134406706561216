import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: 300,
      display: 'block',
    }
  }
})

class Image extends React.Component {
  render() {
    const { classes, cell } = this.props

    return (
      <div className={classes.root}>
        <img alt={cell.alt} src={cell.src} />
      </div>
    )
  }
}

Image.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Image)))
