import store from '../stores'
import { ajaxCall } from '../utils'

import {
  sidebarActions,
  printActions
} from './index'

const { appConfig } = store.getState().appReducer

export const appActions = {
  initApp: () => {
    if (localStorage.getItem('token')) {
      store.dispatch(appActions.fillDbListGet())
    }

    return {
      type: 'app_action_init_app'
    }
  },
  hasSoftwareUpdates: (hasSoftwareUpdates = false, forceSoftwareUpdates = false) => ({
    type: 'app_action_has_sw_updates',
    hasSoftwareUpdates,
    forceSoftwareUpdates
  }),
  hasUpdates: (hasUpdates = false, failures = []) => ({
    type: 'app_action_has_updates',
    hasUpdates,
    failures
  }),
  updateAll: () => {
    if (appConfig.appEnv !== 'cloud') {
      clearTimeout(appConfig.timeouts.updateAll)

      appConfig.timeouts.updateAll = null

      ajaxCall({
        config: {
          url: appConfig.actions.updates.updateAll.path,
        },
        success: () => {
          appConfig.timeouts.updateAll = setTimeout(() => {
            store.dispatch(appActions.updateAll())
          }, appConfig.actions.updates.updateAll.timeout)
        },
        error: (err) => {
          console.log(err)

          appConfig.timeouts.updateAll = setTimeout(() => {
            store.dispatch(appActions.updateAll())
          }, appConfig.actions.updates.updateAll.timeout)
        },
        silently: true,
        activeLoading: false
      })
    }

    return {
      type: 'app_action_update_all'
    }
  },
  uploadDriveZips: () => {
    if (appConfig.appEnv !== 'cloud') {
      clearTimeout(appConfig.timeouts.uploadDriveZips)

      appConfig.timeouts.uploadDriveZips = null

      ajaxCall({
        config: {
          url: appConfig.actions.updates.uploadDriveZips.path,
        },
        success: () => {
          appConfig.timeouts.uploadDriveZips = setTimeout(() => {
            store.dispatch(appActions.uploadDriveZips())
          }, appConfig.actions.updates.uploadDriveZips.timeout)
        },
        error: () => {
          appConfig.timeouts.uploadDriveZips = setTimeout(() => {
            store.dispatch(appActions.uploadDriveZips())
          }, appConfig.actions.updates.uploadDriveZips.timeout)
        },
        silently: true,
        activeLoading: false
      })
    }

    return {
      type: 'app_action_upload_drive_zips',
    }
  },
  getNotifications: () => {
    if (appConfig.appEnv !== 'cloud') {
      clearTimeout(appConfig.timeouts.getNotifications)

      appConfig.timeouts.getNotifications = null

      ajaxCall({
        config: {
          url: appConfig.actions.updates.getNotifications.path,
        },
        success: () => {
          appConfig.timeouts.getNotifications = setTimeout(() => {
            store.dispatch(appActions.getNotifications())
          }, appConfig.actions.updates.getNotifications.timeout)
        },
        error: (err) => {
          console.log(err)

          appConfig.timeouts.getNotifications = setTimeout(() => {
            store.dispatch(appActions.getNotifications())
          }, appConfig.actions.updates.getNotifications.timeout)
        },
        silently: true,
        activeLoading: false
      })
    }

    return {
      type: 'app_action_get_notifications'
    }
  },
  stopCheckUpdates: () => {
    clearTimeout(appConfig.timeouts.checkUpdates)

    appConfig.timeouts.checkUpdates = null

    return {
      type: 'app_action_stop_check_updates'
    }
  },
  checkUpdates: () => {
    // TODO
    if (appConfig.appEnv !== 'cloud') {
      clearTimeout(appConfig.timeouts.checkUpdates)

      appConfig.timeouts.checkUpdates = null

      ajaxCall({
        config: {
          url: appConfig.actions.updates.checkGet.path,
        },
        success: (res) => {
          const { hasUpdates, hasSoftwareUpdates, forceSoftwareUpdates, failures } = res.data.data

          store.dispatch(sidebarActions[(hasUpdates ? 'add' : 'remove') + 'Badge']('sync'))

          if (hasUpdates) {
            store.dispatch(appActions.hasUpdates(true, failures))
          }

          if (hasSoftwareUpdates) {
            store.dispatch(appActions.hasSoftwareUpdates(hasSoftwareUpdates, forceSoftwareUpdates))
          }

          if (hasUpdates) {
            return
          }

          store.dispatch(appActions.hasUpdates(false))

          appConfig.timeouts.checkUpdates = setTimeout(() => {
            store.dispatch(appActions.checkUpdates())
          }, appConfig.actions.updates.checkGet.timeout)
        },
        error: (err) => {
          console.log(err)

          appConfig.timeouts.checkUpdates = setTimeout(() => {
            store.dispatch(appActions.checkUpdates())
          }, appConfig.actions.updates.checkGet.timeout)
        },
        silently: true,
        activeLoading: false
      })
    }

    return {
      type: 'app_action_check_updates'
    }
  },
  appConfigGet: () => {
    const complementaryActions = {
      printActions
    }

    ajaxCall({
      config: {
        url: appConfig.actions.app.appConfig.path,
      },
      success: (res) => {
        const {
          complementaryActions: appConfigComplementaryActions,
          user
        } = res.data.data

        if (appConfigComplementaryActions) {
          appConfigComplementaryActions.forEach(complementaryAction => {
            if (complementaryActions[complementaryAction.type]) {
              Object.entries(complementaryAction.actions || {}).forEach(([key, value]) => {
                store.dispatch(complementaryActions[complementaryAction.type][key](value))
              })
            }
          })
        }

        if (!user.password.expired && !user.password.firstAccess && !user.password.is2F) {
          setTimeout(() => {
            return store.dispatch(appActions.fillSidebarGet())
          }, 100)
        }

        delete res.data.data.complementaryActions

        store.dispatch(appActions.appConfig(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'app_action_app_config_get'
    }
  },
  appConfig: (data) => ({
    type: 'app_action_app_config',
    data
  }),
  changeDb: (selectedDb) => ({
    type: 'app_action_change_db',
    selectedDb
  }),
  getFilteredItems: (callback, callbackParams) => ({
    type: 'app_action_get_filtered_items',
    callback,
    callbackParams
  }),
  resetFilteredItems: () => ({
    type: 'app_action_reset_filtered_items',
  }),
  setFilteredItems: (data) => ({
    type: 'app_action_set_filtered_items',
    data
  }),
  fillDbListGet: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.app.fillDbList.path,
      },
      success: (res) => {
        store.dispatch(appActions.fillDbList(res.data))

        store.dispatch(appActions.appConfigGet())
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'app_action_fill_db_list_get'
    }
  },
  fillDbList: (data) => ({
    type: 'app_action_fill_db_list',
    data
  }),
  resetTitleBlock: () => ({
    type: 'app_reset_title_block',
  }),
  changeTitleBlock: (titleBlock) => ({
    type: 'app_change_title_block',
    titleBlock: titleBlock
  }),
  toggleSidebarElement: (element) => ({
    type: 'app_toggle_sidebar_element',
    element: element
  }),
  toggleSidebar: () => ({
    type: 'app_toggle_sidebar'
  }),
  fillSidebarGet: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.app.fillSidebar.path,
      },
      success: (res) => {
        store.dispatch(appActions.fillSidebar(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'app_fill_sidebar_get'
    }
  },
  fillSidebar: (data) => ({
    type: 'app_fill_sidebar',
    data: data
  }),
  startLoading: () => ({
    type: 'app_action_start_loading'
  }),
  stopLoading: () => ({
    type: 'app_action_stop_loading'
  }),
  checkSoftwareUpdate: (params) => {
    if (appConfig.appEnv !== 'cloud') {
      clearTimeout(appConfig.timeouts.checkSoftwareUpdate)

      appConfig.timeouts.checkSoftwareUpdate = null

      ajaxCall({
        config: {
          url: appConfig.actions.app.checkSoftwareUpdate.path,
          params
        },
        success: (res) => {
          store.dispatch(appActions.setUpdater(res.data))

          if (res.data && res.data.data && ['checking', 'downloading'].indexOf(res.data.data.status) !== -1) {
            appConfig.timeouts.checkSoftwareUpdate = setTimeout(() => {
              store.dispatch(appActions.checkSoftwareUpdate())
            }, appConfig.actions.app.checkSoftwareUpdate.timeout)
          }
        },
        error: (err) => {
          console.log(err)
        },
        silently: true,
        activeLoading: false
      })
    }

    return {
      type: 'app_action_check_software_update'
    }
  },
  setUpdater: (data) => ({
    type: 'app_action_set_updater',
    data
  }),
  installSoftwareUpdate: () => {
    if (appConfig.appEnv !== 'cloud') {
      ajaxCall({
        config: {
          url: appConfig.actions.app.installSoftwareUpdate.path,
        },
        success: () => {
          console.log('install update')
        },
        error: (err) => {
          console.log(err)
        },
        activeLoading: true
      })
    }

    return {
      type: 'app_action_install_sw_update'
    }
  },
  toggleUpdater: (isOpenUpdater) => ({
    type: 'app_action_toggle_updater',
    isOpenUpdater
  })
}
