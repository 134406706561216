export const sidebarActions = {
  addBadge: (badge) => ({
    type: 'sidebar_action_add_badge',
    badge
  }),
  removeBadge: (badge) => ({
    type: 'sidebar_action_remove_badge',
    badge
  })
}
