import _ from 'lodash'

export const validators = {
  mount: (_this) => {
    const { validateForm } = _this.props

    if (validateForm) {
      validators.validate(_this)
    }

    return true
  },
  update: (_this, prevProps) => {
    const { field, validateForm } = _this.props

    const { field: prevField, validateForm: prevValidateForm } = prevProps

    if ((field.value !== prevField.value) || (validateForm !== prevValidateForm)) {
      validators.validate(_this)
    }
  },
  minLengthValidator: (validator, props) => {
    const { value } = props.field

    if (!value && value !== 0) {
      return true
    }

    return value.length >= validator.value
  },
  maxLengthValidator: (validator, props) => {
    const { value } = props.field

    return value.length <= validator.value
  },
  requiredValidator: (props) => {
    const { value } = props.field

    if (!value && value !== 0) {
      return false
    }

    return true
  },
  regexValidator: (validator, props) => {
    const { value } = props.field

    const regexp = new RegExp(validator.regex.pattern, validator.regex.flags)

    return regexp.test(value)
  },
  validate: (_this) => {
    const { error } = _this.state

    const { field } = _this.props

    if (_.isEmpty(field.validators)) {
      return error ? _this.setState({
        error: false,
        errorMessage: null
      }) : true
    }

    const isRequired = field.validators.filter(validator => {
      return validator.type === 'required'
    })[0] || null

    if (isRequired && (!field.value && field.value !== 0)) {
      if (!validators.requiredValidator(_this.props)) {
        return _this.setState({
          error: true,
          errorMessage: isRequired.message
        })
      }
    }

    for (let i = 0; i < field.validators.length; i++) {
      const validator = field.validators[i]

      if (validator.type !== 'required') {
        try {
          if (!validators[validator.type + 'Validator'](validator, _this.props)) {
            return _this.setState({
              error: true,
              errorMessage: validator.message
            })
          }
        } catch (e) {}
      }
    }

    return _this.setState({
      error: false,
      errorMessage: null
    })
  }
}
