import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Calendar } from 'react-date-range'
import { it } from 'react-date-range/dist/locale'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'
import { isValidDate } from '../../utils'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  inputBlock: {
    flexGrow: 1,
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    margin: 0
  },
  input: {
    backgroundColor: theme.palette.common.white,
    padding: '.375rem .75rem',
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 9px) !important',
    fontSize: '.75rem',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  labelShrink: {
    transform: 'translate(14px, -7px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  calendarContainer: {
    display: 'none',
    position: 'absolute',
    top: '100%',
    marginTop: -8,
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1]
  },
  calendarContainerIsOpen: {
    display: 'block'
  },
  calendarIcon: {
    padding: 10
  }
})

class Date extends React.Component {
  _isMounted = false

  state = {
    open: false,
    calendarRef: null
  }

  componentDidMount() {
    const { appConfig, cell } = this.props

    this._isMounted = true

    const { value } = cell

    const { dateFormat } = appConfig

    if (value) {
      this.setState({
        value,
        currentDate: moment(value, dateFormat).toDate()
      })
    }

    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    this._isMounted = false

    document.removeEventListener('click', this.handleClickOutside)
  }

  onChange = (tmpValue) => {
    const { fieldPath, onChange, appConfig } = this.props

    const { dateFormat } = appConfig

    const formatTestRegex = new RegExp('^$|^([0-3])$|(^(0)[1-9]$|^[1-2][0-9]$|^(3)[0-1]$)$|(^((0)[1-9]|[1-2][0-9]|(3)[0-1])(/)$)|(^(((0)[1-9]|[1-2][0-9]|(3)[0-1])(/))([0-1]|(0)[1-9]|(1)[0-2])$)|(^((((0)[1-9]|[1-2][0-9]|(3)[0-1])(/))([0-1]|(0)[1-9]|(1)[0-2]))((/){1}([0-9]{0,4}){0,1})$)', 'ig')

    const formatRegex = new RegExp('^([0-9]{2})((/)([0-9]{2})){0,1}$', 'ig')

    let value = tmpValue

    if (!formatTestRegex.test(value)) {
      return
    }

    value = value.replace(formatRegex, '$1/$2/').replace(/\/\//, '/')

    const state = {
      value
    }

    const tmpDate = moment(value, dateFormat)

    if (value && tmpDate.isValid() && isValidDate(value)) {
      state.currentDate = tmpDate.toDate()

      state.open = false

      onChange(value, fieldPath)
    }

    this.setState(state)
  }

  handleClickOutside = (event) => {
    const { calendarRef } = this.state

    if (!calendarRef.contains(event.target) && this._isMounted) {
      this.setState({
        open: false
      })
    }
  }

  setRef = (node) => {
    const { calendarRef } = this.state

    if (!calendarRef) {
      this.setState({
        calendarRef: ReactDOM.findDOMNode(node)
      })
    }
  }

  toggleCalendar = (show = false) => {
    const { readonly } = this.props

    return this.setState({
      open: readonly ? false : show || !this.state.open
    })
  }

  render() {
    const { classes, cell, appConfig, readonly = false } = this.props

    const { dateFormat } = appConfig

    const { open, currentDate, value } = this.state

    return (
      <div className={classes.root} ref={this.setRef}>
        <div className={classes.inputContainer}>
          <TextField
            variant='outlined'
            classes={{
              root: classes.inputBlock + (readonly ? ' ' + classes.inputReadOnly : ''),
            }}
            value={value || ''}
            onFocus={() => this.toggleCalendar(true)}
            onBlur={this.onBlur}
            readOnly={readonly}
            disabled={!(!cell.readonly)}
            InputProps={{
              maxLength: 10,
              readOnly: readonly,
              inputProps: {
                maxLength: 10,
                className: classes.input
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                shrink: classes.labelShrink,
              }
            }}
            label={cell.label}
            onChange={(event) => this.onChange(event.target.value)}
            error={cell.error ? true : false}
            helperText={cell.error} />
          <IconButton onClick={() => this.toggleCalendar()} disabled={readonly || cell.readonly} className={classes.calendarIcon}>
            <Icon className={classes.icon}>event</Icon>
          </IconButton>
        </div>
        <div className={classes.calendarContainer + (open ? ' ' + classes.calendarContainerIsOpen : '')}>
          <Calendar
            date={currentDate}
            locale={it}
            onChange={(date) => {
              return this.onChange(moment(date).format(dateFormat))
            }} />
        </div>
      </div>
    )
  }
}

Date.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Date)))
