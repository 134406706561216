import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ModalComponent from '../../ModalComponent'
import Timetable from './Timetable'
import Event from './Event'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import {
  confirmActions
} from '../../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '1rem',
    flex: '1 1 auto',
  },
  description: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '1rem',
    flex: '1 1 auto',
  },
  company: {
    marginBottom: '1rem',
    flex: '1 1 auto',
  },
  companyName: {
    fontSize: '1.25rem',
    fontWeight: 500
  },
  companyAddress: {
    fontWeight: 500
  },
  author: {
    fontSize: '.75rem',
    fontStyle: 'italic',
    color: theme.palette.grey[500]
  },
  authors: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center'
  },
  authorDivider: {
    padding: '0 1rem'
  },
  modalBody: {
    display: 'flex'
  },
  date: {
    fontSize: '.875rem',
    fontWeight: 500,
    flexGrow: 1
  },
  top: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: '-1rem',
    marginBottom: '-.5rem'
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: '""',
      marginLeft: 8,
      flexGrow: 1,
      height: 1,
      backgroundColor: theme.palette.grey[400]
    }
  }
})

class Preview extends React.Component {
  onWorkerChange = data => {
    const {
      onChange
    } = this.props

    const {
      path,
      visited,
      eventInfo
    } = data

    onChange({
      path,
      eventInfo,
      visited
    })
  }

  render() {
    const {
      openPreview,
      activeEvent,
      onClose,
      classes,
      onEdit,
      onDelete,
      doctors
    } = this.props

    if (!activeEvent) {
      return ''
    }

    const deleteEvent = (data) => {
      onDelete(data)

      onClose()
    }

    const eventDoctor = activeEvent.doctor ? doctors.find(item => item.id === activeEvent.doctor) : null

    return (
      <ModalComponent
        open={!(!openPreview)}
        onClose={onClose}
        type='lg'
        modalClasses={{
          body: classes.modalBody
        }}
        body={(
          <div className={classes.root}>
            {!activeEvent || activeEvent.type === 'event' ? (
              <div className={classes.top}>
                <div className={classes.date}>
                  {[activeEvent.date, [
                    activeEvent.endTime ? 'dalle' : 'alle',
                    activeEvent.time,
                    ...(activeEvent.endTime ? [
                      'alle',
                      activeEvent.endTime
                    ] : [])
                  ].join(' ')].filter(item => !(!item)).join(' - ')}
                </div>
                <div className={classes.actions}>
                  <IconButton aria-label='Edit' className={classes.actionItem} onClick={onEdit}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton aria-label='Delete' className={classes.actionItem} onClick={() => {
                    this.props.onHandleOpen({
                      title: 'Elimina elemento',
                      message: 'Sei sicuro di voler eliminare questo elemento?',
                      ok: 'Elimina',
                      ko: 'Annulla'
                    }, deleteEvent, [])
                  }}>
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </div>
            ) : ''}
            <div className={classes.title}>
              {activeEvent.title}
            </div>
            {activeEvent.company ? (
              <div className={classes.company}>
                <div className={classes.companyName}>{activeEvent.company.name}</div>
                <div className={classes.companyAddress}>{activeEvent.company.address}</div>
                <a href={'mailto:' + activeEvent.company.email} className={classes.companyEmail}>{activeEvent.company.email}</a>
              </div>
            ) : ''}
            {activeEvent.description ? (
              <React.Fragment>
                <small className={classes.divider}>Descrizione</small>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: activeEvent.description.replace(/\n/ig, '<br>')
                  }}>
                </div>
              </React.Fragment>
            ) : ''}
            {activeEvent.notes ? (
              <React.Fragment>
                <small className={classes.divider}>Note</small>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: activeEvent.notes.replace(/\n/ig, '<br>')
                  }}>
                </div>
              </React.Fragment>
            ) : ''}
            {activeEvent.type === 'event' ? (
              <Event eventId={activeEvent.id} workers={activeEvent.workers} path={activeEvent.path} onChange={this.onWorkerChange} />
            ) : (
              <Timetable workers={activeEvent.workers} />
            )}
            <div className={classes.authors}>
              {[
                activeEvent.author ? (
                  <div className={classes.author}>{activeEvent.author}</div>
                ) : '',
                eventDoctor ? (
                  <div className={classes.author}>Medico: {eventDoctor.name}</div>
                ) : ''
              ].filter(item => !(!item)).map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {index ? (
                      <div className={classes.authorDivider}> - </div>
                    ) : ''}
                    {item}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )} />
    )
  }
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Preview)))
