import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../../Icon'
import ModalComponent from '../../ModalComponent'
import FormField from '../../FormField'
import Select from './Select'
import AsyncSelect from './AsyncSelect'
import Checkbox from './Checkbox'
import CustomizeModal from './CustomizeModal'
import moment from 'moment'
import _ from 'lodash'

import { } from '../../../actions'
import { clsx } from '../../../utils'

const styles = theme => ({
  modalTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.25rem'
  },
  modalPeriod: {
    display: 'flex',
    padding: '1rem 0 0',
    alignItems: 'center'
  },
  modalPeriodLabel: {
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: '16px 0 8px'
  },
  modalPeriodElement: {
    flexGrow: 1
  },
  listTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1rem'
  },
  buttonInModal: {
    margin: '0 1rem',
  },
  yearInModal: {
    margin: '0 1rem',
    backgroundColor: '#f0ad4e',
    color: '#fff'
  },
  label: {
    marginLeft: '.5rem'
  },
  actionRoot: {
    display: 'flex'
  },
  param: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem'
  },
  paramSelect: {
    minWidth: 250
  },
  editButton: {
    backgroundColor: '#428bca',
    color: '#fff',
  },
  divider: {
    minWidth: '100%',
    maxWidth: '100%',
    margin: '1rem 0',
    borderBottom: '1px solid ' + theme.palette.grey[200]
  },
  multiButtonContainer: {
    margin: '0 1rem',
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    display: 'flex'
  },
  multiButton: {
    flex: 1,
    margin: 0,
    borderRadius: 0
  },
  tooltipRoot: {
    fontSize: '1rem'
  }
})
class ModalActions extends React.Component {
  state = {
    params: {},
    customizeModalOpened: false
  }

  render() {
    const { classes, openModal, currentModalAction, onClose, onClick, parent } = this.props

    const { periodFrom, periodTo } = parent.state

    const { params } = this.state

    const currentYear = parseInt(moment().format('YYYY'))

    const years = []

    for (var i = 0; i < 6; i++) {
      years.unshift(currentYear - i)
    }

    const modalSize = currentModalAction.modalSize || {}

    const style = {
      ...(modalSize.width && { width: modalSize.width }),
      ...(modalSize.height && { height: modalSize.height }),
      ...(modalSize.minWidth && { minWidth: modalSize.minWidth }),
      ...(modalSize.minHeight && { minHeight: modalSize.minHeight })
    }

    return (
      <ModalComponent
        open={openModal}
        onClose={onClose}
        style={style}
        header={(
          <div>
            <div className={classes.modalTitle}>
              {currentModalAction.label}
            </div>
            <div className={classes.modalPeriod}>
              <div className={classes.modalPeriodLabel}>{currentModalAction.filtersLabel || 'Stampa documenti:'} </div>
              <div className={classes.modalPeriodElement}>
                <FormField field={{
                  label: 'Dal',
                  value: periodFrom,
                  maxDate: periodTo,
                  type: 'date'
                }} onChange={(action, field, value) => {
                  return parent.setState({
                    periodFrom: value
                  })
                }} />
              </div>
              <div className={classes.modalPeriodElement}>
                <FormField field={{
                  label: 'Al',
                  value: periodTo,
                  minDate: periodFrom,
                  type: 'date'
                }} onChange={(action, field, value) => {
                  return parent.setState({
                    periodTo: value
                  })
                }} />
              </div>
            </div>
            {!currentModalAction.hidePeriod && (
              <div className={classes.modalPeriod}>
                <div className={classes.modalPeriodLabel}>Oppure tutto il: </div>
                <div className={classes.modalPeriodElement}>
                  {years.map((year, index) => {
                    return (
                      <Button
                        variant='contained'
                        className={classes.yearInModal}
                        onClick={() => {
                          return parent.setState({
                            periodFrom: '01/01/' + year,
                            periodTo: '31/12/' + year
                          })
                        }}
                        key={index} >{year}</Button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        body={(
          <div>
            <Grid container spacing={24}>
              {currentModalAction.actions.map((group, groupIndex) => {
                switch (group.type) {
                  case 'divider':
                    return (
                      <div className={classes.divider} style={group.style || {}} key={groupIndex}></div>
                    )

                  default:
                    return (
                      <Grid item xs={12} key={groupIndex}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} className={classes.listTitle}>
                            {group.label}
                          </Grid>
                          {group.actions.map((action, actionIndex) => {
                            const getButton = (button, className, key) => {
                              if (button.type === 'multibutton') {
                                return (
                                  <div className={classes.multiButtonContainer}>
                                    {button.buttons.map((innerButton, index) => {
                                      return getButton(innerButton, classes.multiButton, index)
                                    })}
                                  </div>
                                )
                              }

                              return (
                                <Tooltip TransitionComponent={Zoom} title={button.tooltip || ''} key={[actionIndex, key].filter(el => !!el).join('_')} classes={{ tooltip: classes.tooltipRoot }}>
                                  <Button variant='contained' className={[classes.buttonInModal, className].filter(el => !!el).join(' ')} onClick={() => onClick(button, currentModalAction, params)} style={button.style || button.styles || {
                                    backgroundColor: '#428bca',
                                    color: '#fff'
                                  }}>
                                    <Icon>{button.icon || 'print'}</Icon>
                                    <span className={classes.label}>{button.label}</span>
                                  </Button>
                                </Tooltip>
                              )
                            }

                            return (
                              <Grid key={actionIndex} item xs={action.size}>
                                <div className={classes.actionRoot}>
                                  {getButton(action)}
                                  {action.withParams ? action.withParams.map((param, paramIndex) => {
                                    switch (param.type) {
                                      case 'select':
                                        const isAsync = typeof param.options === 'string'

                                        if (isAsync) {
                                          return (
                                            <div className={clsx([
                                              classes.param,
                                              classes.paramSelect
                                            ])} key={paramIndex}>
                                              <AsyncSelect field={param} onChange={(name, value) => {
                                                const newParams = _.cloneDeep(params)

                                                newParams[name] = value

                                                return this.setState({
                                                  params: newParams
                                                })
                                              }} />
                                            </div>
                                          )
                                        }

                                        return (
                                          <div className={clsx([
                                            classes.param,
                                            classes.paramSelect
                                          ])} key={paramIndex}>
                                            <Select config={param} onChange={(name, value) => {
                                              const newParams = _.cloneDeep(params)

                                              newParams[name] = value

                                              return this.setState({
                                                params: newParams
                                              })
                                            }} />
                                          </div>
                                        )
                                      case 'checkbox':
                                        return (
                                          <div className={classes.param} key={paramIndex}>
                                            <Checkbox field={param} onChange={(name, value) => {
                                              const newParams = _.cloneDeep(params)

                                              newParams[name] = value

                                              return this.setState({
                                                params: newParams
                                              })
                                            }} />
                                          </div>
                                        )
                                      case 'customize': {
                                        return (
                                          <div className={classes.param} key={paramIndex}>
                                            <Button variant='contained' className={classes.editButton} onClick={() => {
                                              this.setState({
                                                ...this.state,
                                                customizeModalOpened: true,
                                              })
                                            }}>
                                              <Icon>{param.icon || 'pencil'}</Icon>
                                              <span className={classes.label}>{param.label}</span>
                                            </Button>
                                            <CustomizeModal
                                              openModal={this.state.customizeModalOpened}
                                              param={param}
                                              onClose={() => {
                                                return this.setState({
                                                  ...this.state,
                                                  customizeModalOpened: false
                                                })
                                              }}
                                              onClick={(customizedParams) => onClick(
                                                action,
                                                currentModalAction,
                                                { ...params, ...customizedParams }
                                              )} />
                                          </div>
                                        )
                                      }
                                      default:
                                        return ''
                                    }
                                  }) : null}
                                </div>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )
                }
              })}
            </Grid>
          </div>
        )}
      >
      </ModalComponent>
    )
  }
}

ModalActions.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ModalActions)))
