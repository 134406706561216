import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { agendaActions } from '../../../actions'
import _ from 'lodash'

const styles = theme => ({
  root: {
    margin: 8,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    margin: '4px 0'
  },
  match: {
    fontSize: '.9rem',
    opacity: .6,
    marginBottom: 4,
    fontStyle: 'italic'
  },
  matchString: {
    fontSize: '.9rem',
    opacity: .8,
    marginBottom: 4
  },
  color: {
    height: 'auto',
    minWidth: 8,
    marginRight: 8,
    '&.event': {
      backgroundColor: '#1565c0'
    },
    '&.timetable': {
      backgroundColor: '#ffb300'
    },
    '&.monitoringtimetable': {
      backgroundColor: '#854731'
    },
    '&.coursetimetable': {
      backgroundColor: '#515a5e'
    },
    '&.inspectiontimetable': {
      backgroundColor: '#0d8ba0'
    },
    '&.safetyinspectiontimetable': {
      backgroundColor: '#8b52ab'
    }
  },
})

class Event extends React.Component {
  goToEventPage = (event, action) => {
    const { type, selectedDoctors } = this.props

    const [day, month, year] = event.date.split('/')

    this.props.onAgendaDataGet({
      type,
      day: type === 2 ? event.date : null,
      selectedDoctors,
      current: { month, year },
      callback: () => {
        this.props.onUpdateSelectedEvent({
          id: `${event.id}-${day}-${month}-${year}`,
          action
        })
      }
    })
  }

  handleClick = (e, event) => {
    if (!this._delayedClick) {
      this._delayedClick = _.debounce(() => this.doClick(event), 500)
    }

    if (this.clickedOnce) {
      this._delayedClick.cancel()

      this.clickedOnce = false

      this.goToEventPage(event, 'doubleclick')
    } else {
      this._delayedClick(e)

      this.clickedOnce = true
    }
  }

  doClick = (event) => {
    this.clickedOnce = undefined

    this.goToEventPage(event, 'click')
  }

  render() {
    const { event, classes } = this.props

    return (
      <div className={classes.root} onClick={(e) => this.handleClick(e, event)}>
        <div className={[
          classes.color,
          event.type
        ].join(' ')}></div>
        <span>
          <div className={classes.title}>{event.title}</div>
          {!!event.match.length && <div className={classes.match}>Match</div>}
          {(event.match || []).map((matchString, index) => (
            <div className={classes.matchString} key={index}>{matchString}</div>
          ))}
        </span>
      </div>
    )
  }
}

Event.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onAgendaDataGet: params => {
    dispatch(agendaActions.agendaDataGetCall(params))
  },
  onUpdateSelectedEvent: (data) => {
    dispatch(agendaActions.updateSelectedEvent(data))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Event)))
