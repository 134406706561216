import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import {} from '../../actions'

const styles = theme => ({
  root: {
    margin: 0,
  },
  input: {
    padding: '0 .5rem',
    height: '38px',
    fontSize: '.75rem'
  }
})

class TableTextFilter extends React.Component {
  componentDidMount = () => {
    const { filterValue, element } = this.props

    const { value = '' } = element

    this.setState({
      value: filterValue || value
    })
  }

  onChange = (event) => {
    this.setState({
      value: event.target.value
    })
  }

  render() {
    if (!this.state) {
      return ''
    }

    const { classes, element, onChange } = this.props

    const { placeholder } = element.filter

    const { value } = this.state

    let changeTimeout = null

    return (
      <TextField
        placeholder={placeholder}
        className={classes.root}
        inputProps={{
          className: classes.input
        }}
        fullWidth
        margin='normal'
        variant='outlined'
        value={value}
        onChange={(event) => {
          if (changeTimeout) {
            clearTimeout(changeTimeout)
          }

          changeTimeout = setTimeout(element => {
            const { value: newValue, element: newElement } = element

            onChange(newElement, newValue)
          }, 100, {value: event.target.value, element})

          return this.onChange(event)
        }} />
    )
  }
}

TableTextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableTextFilter)))
