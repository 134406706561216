import store from '../stores'
import { ajaxCall } from '../utils'

import {
  messageActions,
  listPageActions,
  listPageAjaxActions
} from './index'

export const inlineFormActions = {
  cacheSelect: (pageType, selectedValue, fieldName) => ({
    type: 'inlineFormActions.cacheSelect',
    pageType,
    selectedValue,
    fieldName
  }),
  resetParams: () => ({
    type: 'inline_form_reset_params',
  }),
  addValueToField: (value, index, fieldName) => ({
    type: 'inline_form_add_value_to_field',
    value,
    index,
    fieldName
  }),
  submitFormPost: (params, url, isAjax = false) => {
    ajaxCall({
      config: {
        url: url,
        method: 'post',
        data: params
      },
      success: (res) => {
        if (res.data.message) {
          store.dispatch(messageActions.showMessage({
            type: 'success',
            message: res.data.message
          }))
        }

        setTimeout(() => {
          return store.dispatch((isAjax ? listPageAjaxActions : listPageActions).reloadList())
        }, 100)
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'inline_form_submit_form_post'
    }
  }
}
