import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HeaderLogin from '../../components/HeaderLogin'
import _ from 'lodash'
import { authActions } from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    minWidth: 350,
    maxWidth: 350
  },
  buttonsGrid: {
    paddingTop: '3rem',
    alignItems: 'center'
  },
  navLink: {
    color: theme.palette.info.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

class Recovery extends React.Component {
  state = {
    username: ''
  }

  componentDidUpdate(prevProps) {
    const { goToLogin, history, appConfig } = this.props

    const { goToLogin: prevGoToLogin } = prevProps

    if (goToLogin && prevGoToLogin !== goToLogin) {
      return history.push(appConfig.appPrefix)
    }
  }

  handleChange = (event, name) => {
    let newState = _.cloneDeep(this.state)

    newState[name] = event.target.value

    return this.setState(newState)
  }

  onPasswordRecovery = () => {
    this.props.onPasswordRecovery(this.state.username)
  }

  render() {
    const { classes, appConfig } = this.props

    const { username } = this.state

    return (
      <div className={classes.root}>
        <form className={classes.form}>
          <HeaderLogin />
          <TextField
            fullWidth
            id='username'
            label='Username'
            className={classes.textField}
            value={username}
            margin='normal'
            onChange={(event) => {
              return this.handleChange(event, 'username')
            }}
          />
          <Grid container spacing={16} className={classes.buttonsGrid}>
            <Grid item xs={6}>
              <NavLink className={classes.navLink} to={appConfig.appPrefix}>Torna alla login</NavLink>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='contained' color='primary' onClick={this.onPasswordRecovery}>Invia</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

Recovery.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.authReducer })

const mapDispatchToProps = dispatch => ({
  onPasswordRecovery: (username) => {
    return dispatch(authActions.passwordRecoveryPost(username))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Recovery)))
