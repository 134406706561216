import _ from 'lodash'

const initialState = {
  updating: false,
  bars: {}
}

// Vars
let newState = null

const statusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'status_action_status_bars':
      newState = _.cloneDeep(state)

      newState.bars[action.url] = action.data

      return newState
    default:
      return state
  }
}

export default statusReducer
