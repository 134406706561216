import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { validators } from './Validators'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import String from './String'
import Select from './Select/Select'
import MIcon from '../Icon'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import {} from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
  },
  gridRoot: {
    alignItems: 'center'
  },
  noResults: {
    padding: '1rem',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    marginBottom: '1rem'
  },
  action: {
    marginBottom: '1rem'
  },
  actionIcon: {
    marginRight: '.5rem'
  },
  divider: {
    margin: '1rem 0'
  },
  inputField: {
    margin: 0
  },
  inputContainer: {
    padding: '0 .5rem',
    flexGrow: 1
  },
  title: {
    fontSize: '1rem',
    textTransform: 'uppercase'
  },
  textRight: {
    textAlign: 'right'
  },
  withAction: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteAction: {
    marginLeft: '.5rem'
  },
  selectContainer: {
    flexGrow: 1,
    marginBottom: -8
  }
})

class Contact extends React.Component {
  state = {
    error: false,
    errorMessage: null
  }

  componentDidMount() {
    return validators.mount(this)
  }

  componentDidUpdate(prevProps) {
    return validators.update(this, prevProps)
  }

  render() {
    const { classes, field, onChange, readonly } = this.props

    const { labels } = field

    return (
      <Paper className={classes.root}>
        <div className={classes.title}>{field.label}</div>
        <Divider light className={classes.divider} />
        {!field.values || field.values.length === 0 ? (
          <div className={classes.noResults}>{labels.noResults}</div>
        ) : field.values.map((contact, index) => {
          return (
            <Grid container key={index} className={classes.gridRoot}>
              <Grid item xs={6} className={classes.withAction}>
                <div className={classes.selectContainer}>
                  <Select field={{
                      name: 'type[' + index + ']',
                      label: 'Tipo',
                      options: field.typeOptions,
                      value: contact.type.value || '',
                      error: contact.type.error
                    }} readonly={readonly} className={classes.inputField} onChange={(action, tmpField, value) => {
                      return onChange({type: 'update', index, property: 'type'}, field, value)
                    }} noOptionsMessage={labels.fields.type} />
                </div>
              </Grid>
              <Grid item xs={6} className={classes.withAction}>
                <div className={classes.inputContainer}>
                  <String field={{
                      name: 'contact[' + index + ']',
                      label: 'Recapito',
                      value: contact.contact.value || '',
                      error: contact.contact.error
                    }} readonly={readonly} className={classes.inputField} onChange={(action, tmpField, value) => {
                      return onChange({type: 'update', index, property: 'contact'}, field, value)
                    }} />
                </div>
                <div className={classes.deleteAction}>
                  {readonly ? null : (
                    <IconButton aria-label={field.actions.delete.label} onClick={(value) => onChange({type: 'remove', index, confirm: field.actions.delete.confirm}, field)} color='secondary'>
                      <Icon fontSize='small'>delete</Icon>
                    </IconButton>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider light className={classes.divider} />
              </Grid>
            </Grid>
          )
        })}
        {readonly ? null : (
          <Button variant='contained' className={classes.action} color={field.actions.add.color} onClick={() => onChange(field.actions.add, field, {
            default: {
              value: !field.values,
            },
            type: {
              value: null,
            },
            contact: {
              value: null,
            },
            location: {
              value: null
            }
          })}>
            <MIcon className={classes.actionIcon}>{field.actions.add.icon}</MIcon>
            {field.actions.add.label}
          </Button>
        )}
      </Paper>
    )
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Contact)))
