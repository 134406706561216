import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import BreadcrumbElement from './BreadcrumbElement'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    padding: '.5rem 1rem',
    backgroundColor: theme.palette.background.title,
    boxShadow: theme.shadows[1],
    position: 'relative',
    zIndex: 1,
    minHeight: theme.appVars.breadcrumbsHeight,
    maxHeight: theme.appVars.breadcrumbsHeight,
  },
  element: {
    display: 'flex',
    alignItems: 'center'
  },
  lastElement: {
    flexGrow: 1
  },
  divider: {
    fontWeight: 100,
    fontSize: '.75rem'
  },
  title: {
    padding: '0 1rem',
    fontSize: '1rem'
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: 0,
    height: 'auto',
    maxHeight: 36,
    transition: 'opacity .3s ease-in-out'
  },
  label: {
    marginLeft: '.5rem'
  },
})

class Breadcrumb extends React.Component {
  render() {
    const { classes, breadcrumbs, titleBlock, appConfig } = this.props

    const { titleAction } = titleBlock

    const breadcrumbsList = [
      {
        icon: 'house',
        action: breadcrumbs && breadcrumbs.length > 0 ? '/' : null
      },
      ...breadcrumbs
    ]

    let titleActionProps = {}

    if (titleAction) {
      switch (titleAction.type) {
        case 'print':
          titleActionProps = {
            onClick: () => {
              return this.props.onPrintPost(titleAction.action, titleAction.params)
            }
          }

          break
        default:
          titleActionProps = {
            component: NavLink,
            to: appConfig.appPrefix + titleAction.action
          }
      }
    }

    return (
      <div className={classes.root}>
        {breadcrumbsList.map((element, index) => {
          const isLast = index === breadcrumbsList.length - 1

          return (
            <div className={classes.element + (isLast ? ' ' + classes.lastElement : '')} key={index}>
              <BreadcrumbElement element={element} isLast={isLast} />
              {!isLast ? (
                <Icon className={classes.divider}>chevron-right</Icon>
              ) : ''}
            </div>
          )
        })}
        <div className={classes.titleBlock}>
          {titleAction ? (
            <Button variant='contained' className={classes.button} style={{
              backgroundColor: titleAction.style.bg,
              color: titleAction.style.color
            }} {...titleActionProps}>
              <Icon>{titleAction.style.icon}</Icon>
              <span className={classes.label}>{titleAction.label}</span>
            </Button>
          ) : ''}
        </div>
      </div>
    )
  }
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Breadcrumb)))
