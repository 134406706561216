import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Select from '../FormField/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import ModalComponent from '../ModalComponent'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import MUIcon from '@material-ui/core/Icon'
import { clsx } from '../../utils'
import logo from '../../assets/img/logo.svg'

import {
  authActions,
  appActions
} from '../../actions'

import './index.css'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    display: 'block',
    height: 48,
    marginRight: '1rem'
  },
  logoFlip1: {
    animation: 'shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip2: {
    animation: 'shake-vertical 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip3: {
    animation: 'shake-lr 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip4: {
    animation: 'shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip5: {
    animation: 'shake-right 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip6: {
    animation: 'shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  logoFlip7: {
    animation: 'shake-left 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  defaultColor: {
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
    boxShadow: theme.shadows[1],
    position: 'fixed',
    top: 0,
    left: theme.appVars.sidebarWidth.main,
    right: 0,
    maxWidth: 'calc(100% - ' + theme.appVars.sidebarWidth.main + 'px)',
    transition: 'left .3s ease-in-out, max-width .3s ease-in-out',
  },
  open: {
    left: theme.appVars.sidebarWidth.open,
    maxWidth: 'calc(100% - ' + theme.appVars.sidebarWidth.open + 'px)',
  },
  bds: {
    flexGrow: 1
  },
  selectRoot: {
    margin: 0,
    textTransform: 'uppercase',
    fontWeight: 700
  },
  userIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem 24px .5rem .5rem',
    marginRight: -24,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[50]
    }
  },
  avatar: {
    marginRight: '.5rem',
    width: 48,
    height: 48,
    overflow: 'hidden',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarImage: {
    objectFit: 'cover',
    width: 48,
    height: 48,
  },
  userName: {
    fontSize: '.75rem',
    textTransform: 'uppercase',
    fontWeight: 700
  },
  liviRoot: {
    display: 'flex',
    fontSize: '2.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    color: theme.palette.grey[900],
    textDecoration: 'none'
  },
  liviIcon: {
    display: 'block',
  },
  updateRoot: {
    marginRight: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[50]
    }
  },
  updateIcon: {
    display: 'block',
  },
  modal: {
    padding: '1rem'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  modalButton: {
    marginTop: '1rem',
    alignSelf: 'flex-end'
  },
  modalProgress: {
    margin: '1rem 0'
  },
  modalText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  modalSubtext: {
    fontSize: '0.85rem',
    color: theme.palette.grey[500],
  },
  divider: {
    margin: '0 0 2rem',
    borderTop: '1px solid #ccc',
    borderBottom: 0
  },
  modalBody: {
    fontSize: '1.25rem',
  },
  modalExpand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  modalExpandOpen: {
    transform: 'rotate(180deg)',
  },
  modalExpandContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tooltipRoot: {
    fontSize: '1rem'
  }
})

class MenuAppBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    expanded: false,
    flippedLogo: {
      counter: 0,
      flipped: 0
    },
  }

  handleChange = (event, checked) => {
    this.setState({ auth: checked })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  renderUpdateStautusSwitch(updater, oldDownloadUrl) {
    switch(updater.status) {
      case 'error':
        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}><Icon>error</Icon>  C'è stato un errore durante l'aggiornamento</span>
            <Button variant='outlined' color='secondary' className={this.props.classes.modalButton} onClick={() => {
              this.props.onCheckSoftwareUpdate({manual: true})
            }}>Riprova</Button>
            {oldDownloadUrl ? (
              <div>
                <div className={this.props.classes.modalExpandContainer}>
                  <IconButton
                    className={clsx([
                      this.props.classes.modalExpand,
                      this.state.expanded && this.props.classes.modalExpandOpen
                    ])}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                    aria-label="Mostra di più"
                  >
                    <MUIcon>expand_more</MUIcon>
                  </IconButton>
                </div>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <Button variant='outlined' className={this.props.classes.modalButton} target='_blank' rel='noopener noreferrer' href={oldDownloadUrl}>
                    Scarica e installa manualmente
                  </Button>
                </Collapse>
              </div>
            ) : ''}
          </div>
        )
      case 'downloading':
        const readableBytes = (bytes) => {
          if (bytes) {
            if (bytes > 1024 * 1024) {
              return (bytes/1024/1024).toFixed(1) + ' Mb'
            } else {
              return (bytes/1024).toFixed(1) + ' Kb'
            }
          }

          return bytes
        }

        const progress = updater.progress ? updater.progress.percent || 0 : 0

        const speed = updater.progress && updater.progress.bytesPerSecond ? readableBytes(updater.progress.bytesPerSecond) : null

        const speedLabel = speed ? `Velocità: ~${speed}/s` : null

        const total = updater.progress && updater.progress.total ? readableBytes(updater.progress.total) : 0

        const transferred = updater.progress && updater.progress.transferred ? readableBytes(updater.progress.transferred) : 0

        const delta = total && transferred ? `Trasferiti ${transferred} di ${total}` : null

        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}>Download in corso...</span>
            <LinearProgress variant='determinate' value={progress} className={this.props.classes.modalProgress}/>
            {speedLabel ?
              <span className={this.props.classes.modalSubtext}>{speedLabel}</span>
              : ''
            }
            {delta ?
              <span className={this.props.classes.modalSubtext}>{delta}</span>
              : ''
            }
          </div>
        )
      case 'checking':
        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}>Verifica della presenza di un aggiornamento in corso...</span>
            <LinearProgress className={this.props.classes.modalProgress}/>
          </div>
        )
      case 'ready':
        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}>
              Aggiornamento pronto per l'installazione.<br></br><br></br>
              Verrà installato automaticamente al prossimo avvio.
            </span>
            <Button variant='outlined' className={this.props.classes.modalButton} onClick={this.props.onInstallSoftware}>Installa ora</Button>
          </div>
        )
      case 'installing':
        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}>Installazione aggiornamento in corso...</span>
            <LinearProgress className={this.props.classes.modalProgress}/>
          </div>
        )
      default:
        return (
          <div className={this.props.classes.modalContent}>
            <span className={this.props.classes.modalText}><Icon>checkmark</Icon>  Aggiornato all'ultima versione disponibile</span>
            <Button variant='outlined' className={this.props.classes.modalButton} onClick={() => {
              this.props.onCheckSoftwareUpdate({manual: true})
            }}>Verifica presenza aggiornamento</Button>
          </div>
        )
    }
  }

  render() {
    const {
      classes,
      sidebarIsOpen,
      dbList,
      appConfig,
      updater,
      isOpenUpdater,
      hasSoftwareUpdates,
      platform
    } = this.props

    const { userConfig = {} } = appConfig

    const {
      auth,
      anchorEl,
      flippedLogo
    } = this.state

    const modalOpen = isOpenUpdater || false

    const open = Boolean(anchorEl)

    const oldDownloadUrl = hasSoftwareUpdates ? ['https://www.achille-medlav.com/achille-4/achille_' + hasSoftwareUpdates, (platform === 'darwin' ? 'dmg' : 'zip')].join('.') + '?from=' + appConfig.selectedDb.slug : null

    const updBadge = updater && updater.status === 'downloading' && (updater.progress || {}).percent ? parseFloat(updater.progress.percent).toFixed(0) + '%' : '!'

    return (
      <AppBar position="static" color="default" classes={{ root: classes.defaultColor + (sidebarIsOpen ? ' ' + classes.open : '')}}>
        <Toolbar>
          <div className={classes.flex}>
            <img className={clsx([
              classes.logo,
              flippedLogo.flipped ? classes['logoFlip' + (Math.floor(Math.random() * 6) + 1)] : ''
            ])} src={logo} alt={'Achille'} onClick={() => {
              const counter = flippedLogo.flipped ? 1 : flippedLogo.counter + 1

              const newFlippedLogo = {
                flipped: counter === 5,
                counter
              }

              this.setState({
                flippedLogo: newFlippedLogo
              })
            }} />
            {appConfig.appEnv !== 'cloud' && dbList.length > 1 ? (
              <div className={classes.bds}>
                <Select
                  field={{ options: dbList, value: appConfig.selectedDb.value || appConfig.selectedDb }}
                  selectClasses={{
                    root: classes.selectRoot
                  }}
                  onChange={(action, field, selectedDb) => {
                    return this.props.onChangeDb(selectedDb)
                  }} />
              </div>
            ) : ''}
          </div>
          {auth && (
            <div>
              <a href={userConfig.assistance_url} className={classes.liviRoot} target='_blank' rel='noopener noreferrer'>
                <Tooltip title='Assistenza' placement='bottom' classes={{ tooltip: classes.tooltipRoot }}>
                  <Icon className={classes.liviIcon}>perm_phone_msg</Icon>
                </Tooltip>
              </a>
            </div>
          )}
          {auth && appConfig.appEnv !== 'cloud' && (
            <Tooltip title='Aggiornamento software' placement='bottom' classes={{ tooltip: classes.tooltipRoot }}>
              <div
                onClick={() => {
                  this.props.onToggleSoftwareUpdateModal(!modalOpen)
                }}
                aria-haspopup='true'
                className={classes.updateRoot}
                ref={div => this.swUpdateDiv = div}>
                <Badge color='secondary' badgeContent={updBadge} invisible={['updated', 'checking'].indexOf(updater.status) !== -1}>
                  <Icon className={classes.updateIcon}>get_app</Icon>
                </Badge>
              </div>
            </Tooltip>
          )}
          {auth && (
            <div>
              <div
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup='true'
                onClick={this.handleMenu}
                color='inherit'
                className={classes.userIcon}>
                {userConfig.user ? (
                  <React.Fragment>
                    <div className={classes.avatar}>
                      {userConfig.user.avatar ? (
                        <img src={userConfig.user.avatar} className={classes.avatarImage} alt={userConfig.user.name} />
                      ) : (
                        <Icon>account_circle</Icon>
                      )}
                    </div>
                    <div className={classes.userName}>{userConfig.user.name}</div>
                  </React.Fragment>
                ) : null}
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={() => {
                  this.props.history.push(appConfig.appPrefix + '/user/profile')

                  this.handleClose()
                }}>
                  <ListItemIcon>
                    <Icon>person</Icon>
                  </ListItemIcon>
                  <ListItemText>Profilo</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  this.props.onLogout()

                  const { location, appConfig, history } = this.props

                  if (location.pathname !== appConfig.appPrefix) {
                    history.push(appConfig.appPrefix)
                  }

                  return this.handleClose
                }}>
                  <ListItemIcon>
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
        <ModalComponent
          open={modalOpen}
          onClose={() => this.props.onToggleSoftwareUpdateModal(false)}
          type='lg'
          modalClasses={{
            body: classes.modalBody
          }}
          body={(
            <div>
              <h2>Aggiornamento software</h2>
              <hr className={classes.divider} />
              {this.renderUpdateStautusSwitch(updater, oldDownloadUrl)}
            </div>
          )} />
      </AppBar>
    )
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onChangeDb: (selectedDb) => {
    dispatch(appActions.changeDb(selectedDb))
  },
  onLogout: () => {
    dispatch(authActions.logout())
  },
  onInstallSoftware: () => {
    dispatch(appActions.installSoftwareUpdate())
  },
  onCheckSoftwareUpdate: (params) => {
    dispatch(appActions.checkSoftwareUpdate(params))
  },
  onToggleSoftwareUpdateModal: (show) => {
    dispatch(appActions.toggleUpdater(show))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MenuAppBar)))
