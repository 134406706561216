import store from '../stores'
import { ajaxCall } from '../utils'

const { appConfig } = store.getState().appReducer

export const blockActions = {
  updateRelated: fields => ({
    fields,
    type: 'block_action_update_values'
  }),
  globalUpdate: () => ({
    type: 'block_action_global_update',
  }),
  addToPathMapping: (fieldName, fieldPath) => ({
    type: 'block_action_add_to_path_mapping',
    fieldName,
    fieldPath
  }),
  blockGet: (params = {id: ''}) => {
    ajaxCall({
      config: {
        url: appConfig.actions.block.blockGet.path + '/' + (params.id || ''),
        method: 'get',
        params: params
      },
      success: (res) => {
        store.dispatch(blockActions.block(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'block_action_block_get'
    })
  },
  blockPost: (params, callback) => {
    const { blockData } = store.getState().blockReducer

    ajaxCall({
      config: {
        url: appConfig.actions.block.blockPost.path,
        method: 'post',
        params: params,
        data: blockData
      },
      success: (res) => {
        store.dispatch(blockActions.blockSave(res.data))

        if (typeof callback === 'function') {
          return callback(res.data.data)
        }
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'block_action_block_post'
    })
  },
  blockSave: (data) => ({
    type: 'block_action_block_save',
    data
  }),
  blockDelete: (data) => ({
    type: 'block_action_block_delete',
    data
  }),
  blockDeletePost: (id) => {
    ajaxCall({
      config: {
        url: appConfig.actions.block.blockDeletePost.path,
        method: 'post',
        data: {
          id
        }
      },
      success: (res) => {
        return store.dispatch(blockActions.blockDelete(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'block_action_block_delete_post'
    })
  },
  block: (data) => ({
    type: 'block_action_block',
    data
  }),
  changeField: (value, fieldPath) => ({
    type: 'block_action_change_field',
    value,
    fieldPath
  }),
  changeFieldProp: (value, prop, fieldPath) => ({
    type: 'block_action_change_field_prop',
    value,
    prop,
    fieldPath
  }),
  commitChanges: () => ({
    type: 'block_action_commit_changes'
  })
}
