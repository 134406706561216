import store from '../stores'
import { ajaxCall } from '../utils'
import _ from 'lodash'

const { appConfig } = store.getState().appReducer

const propsToDelete = [
  'lastViewedReset',
  'selectCache',
  'rowCount',
  'pageCount'
]

export const listPageAjaxActions = {
  updatePagination: (paginationConfig, pageType) => ({
    type: 'list_page_ajax_action_pagination_config',
    paginationConfig,
    pageType
  }),
  loadPage: (pageType, params, callback) => ({
    type: 'list_page_ajax_action_load_page',
    pageType,
    params,
    callback
  }),
  loadPageCallback: callback => {
    callback()

    return {
      type: 'list_page_ajax_action_load_page_callback'
    }
  },
  updateSelectedElements: (selectedElements, selectAllPageElements, pageType) => ({
    type: 'list_page_ajax_action_update_selected_elements',
    selectedElements,
    selectAllPageElements,
    pageType
  }),
  getFilteredItems: (callback, callbackParams, params) => {
    const { listPageParams, lastPageType } = store.getState().listPageAjaxReducer

    const tmpParams = {
      ...listPageParams,
      ...params
    }

    propsToDelete.forEach(item => {
      delete tmpParams[item]
    })

    ajaxCall({
      config: {
        url: appConfig.actions[lastPageType][lastPageType + 'GetFiltered'].path,
        method: 'post',
        data: tmpParams
      },
      success: (res) => {
        if (callbackParams[1].id) {
          callbackParams[1].id = res.data.data
        } else {
          callbackParams.push(res.data.data)
        }

        return callback(...callbackParams)
      },
      error: () => {},
      activeLoading: true
    })

    return {
      type: 'list_page_ajax_action_get_filtered_items'
    }
  },
  listPageAjaxDefaultActionPost: (action, pageType, itemId, params) => {
    const cCAction = _.camelCase([pageType, action, 'post'].join('-'))

    const url = appConfig.actions[pageType][cCAction].path

    ajaxCall({
      config: {
        url,
        method: 'post',
        params,
        data: {
          id: itemId
        }
      },
      success: (res) => {
        store.dispatch(listPageAjaxActions.listPageAjaxChange(res.data, itemId))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_ajax_action_list_page_default_action_post'
    })
  },
  listPageAjaxChange: (data, itemId) => ({
    type: 'list_page_ajax_action_list_page_change',
    data,
    itemId
  }),
  listPageAjaxDeletePost: (pageType, pageTypes, itemId, params) => {
    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'DeletePost'].path,
        method: 'post',
        params,
        data: {
          id: itemId
        }
      },
      success: (res) => {
        store.dispatch(listPageAjaxActions.listPageAjaxDelete(res.data, itemId, pageType, pageTypes, params))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_ajax_action_list_page_delete_post'
    })
  },
  listPageAjaxDelete: (data, itemId, pageType, pageTypes, params) => ({
    type: 'list_page_ajax_action_list_page_delete',
    data,
    itemId,
    pageType,
    pageTypes,
    params
  }),
  listPageAjaxGet: (pageType, params) => {
    propsToDelete.forEach(item => {
      delete params[item]
    })

    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'PageGet'].path,
        params
      },
      success: (res) => {
        store.dispatch(listPageAjaxActions.listPageAjax(res.data, pageType))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'list_page_ajax_action_list_page_page_get',
      pageType
    })
  },
  listPageAjax: (data, pageType) => ({
    type: 'list_page_ajax_action_list_page_page',
    data,
    pageType
  }),
  listPagesAjaxGet: (pageType, pageTypes, params = {}, reload = true, append = false, activeLoading = true, update = false) => {
    const url = (appConfig.actions[pageType][(pageTypes || pageType) + 'sGet'] || {}).path

    propsToDelete.forEach(item => {
      delete params[item]
    })

    if (url) {
      ajaxCall({
        config: {
          url,
          params
        },
        success: (res) => {
          store.dispatch(listPageAjaxActions.listPagesAjax(res.data, append, update, pageType, params))
        },
        error: () => {},
        activeLoading: activeLoading
      })
    }

    return ({
      type: reload ? append ? 'list_page_ajax_action_list_page_pages_append' : 'list_page_ajax_action_list_page_pages_get' : 'list_page_ajax_action_list_page_pages_not_reload',
      update,
      params,
      pageType
    })
  },
  reloadList: () => ({
    type: 'list_page_ajax_action_reload_list'
  }),
  listPagesAjax: (data, append, update, pageType, params) => ({
    type: 'list_page_ajax_action_list_pages',
    data,
    append,
    update,
    pageType,
    params
  })
}
