import { config } from './config'
import { removeDuplicateCharacters } from '../helpers'
import _ from 'lodash'

const calcEar = (chart, part) => {
  const aMin = [null]

  const aMax = [null]

  const aLet = [null]

  const aMisura = []

  let numA = 0

  let numlet = 0

  let vv = 0

  let mpb3000 = ''

  let mpbtrim = ''

  let ear = ''

  const aMerSTR = [
    null,
    '4ab',
    '4ac',
    '4acb',
    '4adc',
    '4adcb',
    '4adb',
    '4acd',
    '3aec',
    '3aed',
    '3afd',
    '3aedc',
    '3afdc',
    '2aec',
    '2aed',
    '2afd',
    '2aedc',
    '2afdc',
    '1aed',
    '1aedc',
    '1aefd',
    'ed',
    'efd',
    'efdc',
    'fd',
    'efcd',
    'efdc',
    'fecd',
    'fedc',
    'feb',
    'efb',
    'fedc'
  ]

  const aMerRES = [
    null,
    'normale',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 1.',
    'ipoacusia da rumore di grado 2.',
    'ipoacusia da rumore di grado 2.',
    'ipoacusia da rumore di grado 2.',
    'ipoacusia da rumore di grado 2.',
    'ipoacusia da rumore di grado 2.',
    'ipoacusia da rumore di grado 3',
    'ipoacusia da rumore di grado 3.',
    'ipoacusia da rumore di grado 3.',
    'ipoacusia da rumore di grado 3.',
    'ipoacusia da rumore di grado 3.',
    'ipoacusia da rumore di grado 4.',
    'ipoacusia da rumore di grado 4.',
    'ipoacusia da rumore di grado 4.',
    'ipoacusia da rumore di grado 5.',
    'ipoacusia da rumore di grado 5.',
    'ipoacusia da rumore di grado 5.',
    'ipoacusia da rumore di grado 5.',
    'tutti i deficit da rumore + altra causa.',
    'tutti i deficit da rumore + altra causa.',
    'tutti i deficit da rumore + altra causa.',
    'tutti i deficit da rumore + altra causa.',
    'tutti i deficit non da rumore.',
    'tutti i deficit non da rumore.',
    'tutti i deficit non da rumore.'
  ]

  const earSerie = chart.series[part === 'destro' ? 0 : 1]

  const rsAUdef = _.cloneDeep(config.merluzzi)

  if (rsAUdef.length) {
    rsAUdef.forEach((item, index) => {
      aMin[index + 1] = rsAUdef[index].min

      aMax[index + 1] = rsAUdef[index].max

      aLet[index + 1] = rsAUdef[index].lettera
    })
  }

  for (let i = 1; i <= 16; i++) {
    aMisura[i] = ''
  }

  for (let i = 11; i <= 13; i++) {
    // fino a 3000 hertz
    vv = earSerie[3]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[1] = aLet[i]
    }

    vv = earSerie[5]

    if ( vv > aMin[i] && vv < aMax[i]) {
      aMisura[2] = aLet[i]
    }

    vv = earSerie[7]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[3] = aLet[i]
    }

    vv = earSerie[8]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[4] = aLet[i]
    }
  }

  for (let i = 14; i <= 16; i++) {
    // da 4000 a 8000 hertz
    vv = earSerie[9]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[5] = aLet[i]
    }

    vv = earSerie[10]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[6] = aLet[i]
    }

    vv = earSerie[11]

    if (vv > aMin[i] && vv < aMax[i]) {
      aMisura[7] = aLet[i]
    }
  }

  for (let i = 1; i <= 7; i++) {
    if (aMisura[i] !== '') {
      mpb3000 += aMisura[i]

      numlet++
    }
  }

  for (let i = 0; i < mpb3000.length; i++) {
    if (mpb3000.charAt(i) === 'a') {
      numA++
    }
  }

  if (numA > 0) {
    mpb3000 = numA + mpb3000
  }

  if (numlet > 1) {
    mpbtrim = removeDuplicateCharacters(mpb3000)
  }

  ear = 'Classificazione Merluzzi \'79 orecchio ' + part + ' non possibile.'

  for (let i = 1; i <= 31; i++) {
    if (mpbtrim === aMerSTR[i]) {
      ear = 'Orecchio ' + part + ', classificazione Merluzzi \'79: ' + aMerRES[i]
    }
  }

  return ear
}

export const merluzzi = (chart) => {
  const orecchioDX = calcEar(chart, 'destro')
  const orecchioSX = calcEar(chart, 'sinistro')

  return orecchioDX + '\n' + orecchioSX
}
