import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import MIcon from '../Icon'
import FormField from './'

import { } from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
    marginTop: 0,
    marginBottom: 0
  },
  gridRoot: {
    alignItems: 'center'
  },
  noResults: {
    padding: '1rem',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    marginBottom: '1rem'
  },
  action: {
    marginBottom: '1rem'
  },
  actionIcon: {
    marginRight: '.5rem'
  },
  divider: {
    margin: '1rem 0'
  },
  inputField: {
    margin: 0
  },
  inputContainer: {
    padding: '0 .5rem',
    flexGrow: 1
  },
  title: {
    fontSize: '1rem',
    textTransform: 'uppercase'
  },
  textRight: {
    textAlign: 'right'
  },
  withAction: {
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    flexGrow: 1,
    marginTop: -8
  },
  dividerContainer: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
    marginRight: -60
  },
  dynamicInputContainer: {
    flexGrow: 1,
    marginTop: -8,
    paddingRight: 60,
  },
  dynamicInputContainerNoDelete: {
    paddingRight: 0,
  },
  fieldClassName: {
    paddingTop: '0!important',
    paddingBottom: '0!important'
  },
  deleteAction: {
    position: 'absolute',
    right: 0,
    top: '50%',
    marginTop: -22
  },
  entryContainer: {
    position: 'relative'
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase'
  }
})

class Dynamic extends React.Component {
  addValue = () => {
    const { field, onChange } = this.props

    const { values = [], fields } = field

    const value = {}

    fields.forEach(item => {
      if (typeof item.value !== 'undefined') {
        return value[item.name] = item.value
      }

      return value[item.name] = null
    })

    onChange({
      type: 'dynamic',
      index: values.length,
    }, field, value)
  }

  hideHeaderDeleteButton = () => {
    const { values = [], disableDeleteButton } = this.props.field

    if (disableDeleteButton) {
      return true
    }

    let toReturn = false

    values.forEach(value => {
      if (value.readonly) {
        toReturn = true
      }
    })

    return toReturn
  }

  render() {
    const { classes, field, onChange, readonly } = this.props

    const { values = [], labels, label, fields, actions, header, disableAddButton, disableDeleteButton } = field

    return (
      <Paper className={classes.root}>
        {label ? (
          <React.Fragment>
            <div className={classes.title}>{label}</div>
            <Divider light className={classes.divider} />
          </React.Fragment>
        ) : ''}
        {header ? (
          <React.Fragment>
            <Grid container spacing={16} className={classes.gridRoot + ' ' + classes.dynamicInputContainer + (this.hideHeaderDeleteButton() ? ' ' + classes.dynamicInputContainerNoDelete : '')}>
              {header.map((headerElement, index) => {
                return (
                  <Grid item xs={headerElement.size || 12} key={index}>
                    <div className={classes.label}>
                      {headerElement.label}
                    </div>
                  </Grid>
                )
              })}
            </Grid>
            <Divider light className={classes.divider} />
          </React.Fragment>
        ) : ''}
        {values.length ? (values.map((fieldValue, valueIndex) => {
          const hideDeleteButton = fieldValue.readonly || disableDeleteButton

          return (
            <div key={valueIndex} className={classes.entryContainer}>
              <Grid container spacing={16} className={classes.gridRoot + ' ' + classes.dynamicInputContainer + (hideDeleteButton ? ' ' + classes.dynamicInputContainerNoDelete : '')}>
                {fields.map((tmpField, fieldIndex) => {
                  const disabled = readonly || (tmpField.canReadonly && fieldValue.readonly)

                  const error = (field.error || [])[valueIndex]?.[tmpField.name]

                  const currentField = {
                    ...tmpField,
                    options: fieldValue[tmpField.name + 'Options'] || tmpField.options,
                    disabled: disabled ? true : false,
                    value: typeof fieldValue[tmpField.name] !== 'undefined' ? fieldValue[tmpField.name] : tmpField.value,
                    parentValues: values,
                    valueIndex,
                    error: error && error.length ? error[0] : null
                  }

                  return (
                    <FormField gridClass={classes.fieldClassName} key={'dynamic-' + valueIndex + '-' + fieldIndex} readonly={disabled} field={currentField} onChange={(action, tmpField, value) => {
                      return onChange({
                        type: 'dynamic',
                        index: valueIndex,
                        fieldIndex: fieldIndex,
                        fromState: action.fromState
                      }, tmpField, value)
                    }} />
                  )
                })}
              </Grid>
              {!hideDeleteButton ? (
                <IconButton className={classes.deleteAction} color='secondary' onClick={() => {
                  return onChange({
                    type: 'remove',
                    index: valueIndex,
                    confirm: actions.delete.confirm
                  }, field)
                }}>
                  <Icon fontSize='small'>delete</Icon>
                </IconButton>
              ) : ''}
              <Grid item xs={12} className={classes.dividerContainer}>
                <Divider light className={classes.divider} />
              </Grid>
            </div>
          )
        })
        ) : (
          <div className={classes.noResults}>{labels.noResults}</div>
        )}
        {!disableAddButton && !readonly ? (
          <Button variant='contained' className={classes.action} color={field.actions.add.color} onClick={this.addValue}>
            <MIcon className={classes.actionIcon}>{field.actions.add.icon}</MIcon>
            {field.actions.add.label}
          </Button>
        ) : null}
      </Paper>
    )
  }
}

Dynamic.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dynamic)))
