import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import moment from 'moment'

import {
  commonActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.appVars.footerHeight,
    maxHeight: theme.appVars.footerHeight,
    padding: '0 1rem',
    fontSize: '.75rem',
    color: theme.palette.placeholder.dark,
    borderTop: '1px solid ' + theme.palette.placeholder.main
  },
  link: {
    textTransform: 'none'
  }
})

class Footer extends React.Component {
  render() {
    const { classes, appConfig } = this.props

    const { userConfig } = appConfig

    if (!userConfig) {
      return null
    }

    const ver = userConfig.ver ? 'v' + userConfig.ver : null

    return (
      <div className={classes.root}>
        {moment().format('YYYY')} &copy; ACHILLE by
        <Button size='small' className={classes.link} href='https://www.medialis.tech' title='Medialis srl' target='_blank' rel='noopener noreferrer'>Medialis srl</Button> - <Button size='small' className={classes.link} onClick={() => {
          return this.props.onCallPost({
            url: '/api/info/release-notes',
            method: 'get'
          })
        }} title={ver} rel='noopener noreferrer'>{ver}</Button>
      </div>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onCallPost: (config) => {
    dispatch(commonActions.callPost(config))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Footer)))
