import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Calendar } from 'react-date-range'
import { it } from 'react-date-range/dist/locale'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import './Date.css'

import {} from '../../../actions'

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    margin: '-12px 0',
  },
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  inputField: {
    padding: '.75rem'
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 11px) !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  labelShrink: {
    transform: 'translate(14px, -7px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  calendarContainer: {
    display: 'none',
    position: 'absolute',
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    top: '100%',
    marginTop: -8,
  },
  'calendar-tl': {
    top: 'auto',
    bottom: '100%',
    left: 0,
  },
  'calendar-tr': {
    top: 'auto',
    bottom: '100%',
    right: 50,
  },
  'calendar-bl': {},
  'calendar-br': {
    right: 50,
  },
  calendarContainerIsOpen: {
    display: 'block'
  },
  input: {
    flexGrow: 1,
  },
  inputReadOnly: {
    backgroundColor: theme.palette.grey[50]
  },
  calendarButton: {
    padding: 8
  }
})

class DateField extends React.Component {
  _isMounted = false

  state = {
    open: false,
    currentDate: new Date(),
    calendarRef: null,
    stringValue: '',
    position: 'bl',
  }

  componentDidMount() {
    const { appConfig, field } = this.props

    this._isMounted = true

    const { value } = field

    const { dateFormat } = appConfig

    const state = {
      stringValue: value || ''
    }

    if (value) {
      state.currentDate = moment(value, dateFormat).toDate()
    }

    this.setState(state)

    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    this._isMounted = false

    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { calendarRef } = this.state

    if (!calendarRef.contains(event.target) && this._isMounted) {
      this.setState({
        open: false
      })
    }
  }

  onChange = (tmpValue) => {
    const { field, onChange, appConfig, index } = this.props

    const { dateFormat } = appConfig

    const formatTestRegex = new RegExp('^([0-9]{0,2})(/){0,1}([0-9]{0,2})(/)*([0-9]{0,4})$', 'ig')

    const formatRegex = new RegExp('^([0-9]{2})((/)([0-9]{2})){0,1}$', 'ig')

    let value = tmpValue

    if (!formatTestRegex.test(value)) {
      value = ''
    }

    value = value.replace(formatRegex, '$1/$2/').replace(/\/\//, '/')

    const state = {
      stringValue: value
    }

    const tmpDate = moment(value, dateFormat)

    if (value && tmpDate.isValid()) {
      state.currentDate = tmpDate.toDate()

      state.open = false

      onChange(value, index, field.name)
    }

    if (!value) {
      onChange('', index, field.name)
    }

    return this.setState(state)
  }

  toggleCalendar = (show = false) => {
    const { readonly, field } = this.props

    const { calendarRef } = this.state

    const { innerWidth: windowWidth, innerHeight: windowHeight } = window

    const { top: refTop, left: refLeft } = calendarRef.getBoundingClientRect()

    const position = ['b', 'l']

    if (windowHeight - refTop < 350) {
      position[0] = 't'
    }

    if (windowWidth - refLeft < 400) {
      position[1] = 'r'
    }

    return this.setState({
      open: readonly ? false : (show || !this.state.open),
      position: field.forcePosition ? field.position : position.join('')
    })
  }

  setRef = (node) => {
    const { calendarRef } = this.state

    if (!calendarRef) {
      this.setState({
        calendarRef: ReactDOM.findDOMNode(node)
      })
    }
  }

  render() {
    const { classes, field, appConfig, readonly } = this.props

    const { classes: fieldClasses = {} } = field

    const { dateFormat } = appConfig

    const { open, currentDate, stringValue, position } = this.state

    if (field.range) {
      return ''
    }

    const minMaxProps = {}

    if (field.minDate) {
      minMaxProps.minDate = moment(field.minDate, dateFormat).toDate()
    }

    if (field.maxDate) {
      minMaxProps.maxDate = moment(field.maxDate, dateFormat).toDate()
    }

    return (
      <div className={[
        classes.root,
        fieldClasses.root
      ].join(' ')} ref={this.setRef}>
        <div className={classes.inputContainer}>
          <TextField
            variant='outlined'
            classes={{
              root: [
                classes.input,
                fieldClasses.input
              ].join(' '),
            }}
            value={stringValue}
            onFocus={() => this.toggleCalendar(true)}
            onBlur={this.onBlur}
            placeholder={field.placeholder}
            InputProps={{
              maxLength: 10,
              classes: {
                input: [
                  classes.inputField,
                  readonly ? classes.inputReadOnly : '',
                  fieldClasses.inputField
                ].join(' '),
              },
              readOnly: readonly
            }}
            InputLabelProps={{
              classes: {
                root: [
                  classes.label,
                  fieldClasses.label
                ].join(' '),
                shrink: [
                  classes.labelShrink,
                  fieldClasses.labelShrink
                ].join(' '),
              },
              shrink: field.placeholder || stringValue ? true : false
            }}
            label={field.label}
            onChange={(event) => this.onChange(event.target.value)}
            error={field.error ? true : false}
            helperText={field.error} />
          <IconButton onClick={() => this.toggleCalendar()} disabled={readonly} className={classes.calendarButton}>
            <Icon className={classes.icon}>event</Icon>
          </IconButton>
        </div>
        <div className={classes.calendarContainer + (open ? ' ' + classes.calendarContainerIsOpen : '') + ' ' + classes['calendar-' + position]}>
          <Calendar
            date={currentDate}
            {...minMaxProps}
            locale={it}
            onChange={(date) => {
              return this.onChange(moment(date).format(dateFormat))
            }} />
        </div>
      </div>
    )
  }
}

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DateField)))
