import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalComponent from '../../../ModalComponent'
import Button from '@material-ui/core/Button'
import Icon from '../../../Icon'
import { withStyles } from '@material-ui/core/styles'
import { } from '../../../../actions'
import RichText from './RichText'
import _ from 'lodash'

const styles = theme => ({
  modalBodyClassName: {
    padding: theme.spacing.unit * 2,
  },
  modalHeaderTitle: {
    fontWeight: 500,
    fontSize: '1.25rem'
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  bodyNoLabel: {
    marginTop: 0
  },
  label: {
    fontSize: '0.9285714285714286rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '.25rem'
  },
  snippet: {
    fontSize: '.75rem',
  },
  confirmButton: {
    backgroundColor: '#428bca',
    color: '#fff',
    marginTop: '.5rem',
    display: 'inline-flex',
    alignSelf: 'flex-end'
  },
  buttonText: {
    marginLeft: '.5rem'
  }
})

const customizeTypes = {
  richtext: RichText
}

class CustomizeModal extends React.Component {
  state = {
    fieldsValues: {}
  }

  componentDidMount = () => {
    const { fieldsValues } = this.state

    const { param = {} } = this.props

    const { fields = [] } = param

    const newParams = _.cloneDeep(fieldsValues)

    fields.forEach(field => {
      newParams[field.name] = field.value
    })

    return this.setState({
      ...this.state,
      fieldsValues: newParams
    })
  }

  render() {
    const { param = {}, classes, openModal, onClose, onClick } = this.props

    const { fields = [] } = param

    const { fieldsValues } = this.state

    return (
      <ModalComponent
        open={openModal}
        type={param.modalSizeType || ''}
        modalClasses={{
          body: classes.modalBodyClassName
        }}
        onClose={() => onClose()}
        header={param.modalTitle ? (
          <div className={classes.modalHeaderTitle}>
            {param.modalTitle}
          </div>
        ) : null}
        body={(
          <div className={classes.body + (!param.bodyLabel ? ' ' + classes.bodyNoLabel : '')}>
            {param.bodyLabel ? (<div className={classes.label}>{param.bodyLabel}</div>) : null}
            {fields.map((field, index) => {
              const TmpType = customizeTypes[field.type] || null

              if (TmpType) {
                //TODO gestire size TmpType
                return (
                  <div key={index}>
                    <TmpType field={field}
                      onChange={(value) => {
                        const newParams = _.cloneDeep(fieldsValues)

                        newParams[field.name] = value

                        return this.setState({
                          fieldsValues: newParams
                        })
                      }} />
                  </div>
                )
              }

              return ''
            })}
            <Button variant='contained' className={classes.confirmButton} onClick={() => {
              const { fieldsValues } = this.state

              onClose()

              return onClick(fieldsValues)
            }}>
              <Icon>{'print'}</Icon>
              <span className={classes.buttonText}>Stampa</span>
            </Button>
          </div>
        )} />
    )
  }
}

CustomizeModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CustomizeModal)))
