import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    margin: '-12px 0',
  },
  inputRoot: {
    marginTop: 16,
    flexGrow: 1,
    marginBottom: 8
  },
})

class Time extends React.Component {
  state = {
    stringValue: '',
  }

  componentDidMount = () => {
    const { field = {} } = this.props

    if (field.value) {
      this.setState({
        stringValue: field.value
      })
    }
  }

  componentDidUpdate = prevProps => {
    const { value } = this.props.field

    const { value: prevValue } = prevProps.field

    if (!prevValue && value) {
      this.setState({
        stringValue: value
      })
    }
  }

  onChange = e => {
    const {
      onChange,
      minTime
    } = this.props.field

    const pattern = new RegExp('^(((0|1)[0-9])|(2[0-3])):([0-5][0-9]){0,1}$', 'ig')

    let value = e.target.value

    if (value.length > 2 && value.charAt(2) !== ':') {
      value = value.substr(0, 2) + ':' + value.substr(2)
    }

    const tmpValue = value + ('00:00'.substr(value.length))

    if (value.length > 5 || !pattern.test(tmpValue)) {
      return
    }

    if (minTime) {
      const valueParts = value.replace(':', '').split('').map(part => parseInt(part))

      const minTimeParts = minTime.replace(':', '').split('').map(part => parseInt(part))

      minTimeParts.forEach((item, index) => {
        if (valueParts.length < (index + 1)) {
          valueParts.push(100)
        }
      })

      const intMinValue = parseInt(minTimeParts.join(''))

      const intCurrentValue = parseInt(valueParts.map(item => {
        return item === 100 ? 9 : item
      }).join(''))

      if (intCurrentValue < intMinValue) {
        return
      }
    }

    this.setState({
      stringValue: value
    })

    if (value === tmpValue || value === '') {
      onChange(value)
    }
  }

  handleClick = open => {
    this.setState({
      open,
    })
  }

  render() {
    const { classes, field } = this.props

    const {
      stringValue
    } = this.state

    return (
      <div className={[
        classes.root,
        (field.classes || {}).root
      ].filter(item => !(!item)).join(' ')}>
        <TextField
          value={stringValue}
          className={[
            classes.inputRoot,
            (field.classes || {}).inputRoot
          ].filter(item => !(!item)).join(' ')}
          inputProps={{
            className: [
              classes.input,
              (field.classes || {}).input
            ].filter(item => !(!item)).join(' ')
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onFocus={() => this.handleClick(true)}
          onChange={this.onChange}
          variant='outlined'
          label='Orario'
          placeholder='--:--' />
      </div>
    )
  }
}

Time.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Time)))
