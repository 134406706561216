import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { validators } from '../Validators'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import { ajaxCall } from '../../../utils'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    transform: 'translate(14px, 18px)',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1,
      backgroundColor: theme.palette.common.white
    }
  },
  labelActive: {
    transform: 'translate(14px, -1px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (config, isColored) => ({
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: state.isFocused ? 2 : 1,
    borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? '#f44336' : (state.isFocused ? '#43425D' : '#c4c4c4'),
    borderRadius: 4,
    backgroundColor: state.isDisabled ? '#fafafa' : (isColored ? '#e6f3ff' : '#fff'),
    margin: 0,
    opacity: 1,
    minHeight: 41,
    padding: '0 14px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? '#f44336' : '#43425D',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: config.dangerIfEmpty && config.isEmpty ? '#f44336' : '#808080',
    fontWeight: config.dangerIfEmpty && config.isEmpty ? 700 : 400
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    color: config.error ? '#f44336' : '#43425D',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    fontSize: '0.9285714285714286rem',
    color: config.error ? '#f44336' : '#43425D',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
  }),
})

class AsyncSelectField extends React.Component {
  state = {
    error: false,
    isEmpty: false,
    errorMessage: null,
    focused: false,
    selectedValue: ''
  }

  filterOptions = (options, inputValue) => {
    return options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  promiseOptions = (inputValue, callback) => {
    const { field } = this.props

    if (!inputValue && field.preventPreloadOptions) {
      return callback([])
    }

    ajaxCall({
      config: {
        url: field.options,
        method: 'get',
        params: {
          query: inputValue
        }
      },
      success: (res) => {
        callback(this.filterOptions(res.data.data, inputValue))
      },
      error: (res) => {
        // TODO
      },
      activeLoading: false
    })
  }

  componentDidMount() {
    const { value, values } = this.props.field

    if (values) {
      const selectedValue = values.length ? values : ''

      this.setState({
        selectedValue,
        isEmpty: !selectedValue
      })
    } else if (value) {
      this.setState({
        selectedValue: value,
        isEmpty: false
      })
    } else {
      this.setState({
        isEmpty: true
      })
    }

    return validators.mount(this)
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps.field

    const { field, onChange } = this.props

    const { value } = field

    const { selectedValue } = this.state

    let changed = false

    if (_.isObject(value) && value.value !== selectedValue.value) {
      changed = !prevValue || (value.value !== prevValue.value)
    } else if (typeof value === 'string' && value !== selectedValue) {
      changed = value !== prevValue
    }

    if (changed) {
      return this.setState({
        selectedValue: value || '',
        isEmpty: !value
      }, () => {
        onChange({type: 'select'}, field, value)
      })
    }

    return validators.update(this, prevProps)
  }

  render() {
    const { field, classes, onChange, noOptionsMessage, readonly } = this.props

    const { focused, selectedValue, isEmpty } = this.state

    const activeLabel = selectedValue ? true : focused

    const labelActive = (activeLabel ? ' ' + classes.labelActive : '')

    const props = {}

    const fieldReadonly = readonly || field.readonly

    if (noOptionsMessage) {
      props.noOptionsMessage = () => noOptionsMessage.noResults
    }

    const selectedErrors = field.dangerIfErrorOption && selectedValue && selectedValue.errors && selectedValue.errors.length ? selectedValue.errors : null

    const selectedErrorMessage = selectedErrors ? field.dangerIfErrorOption + ' ' + selectedErrors.join(', ') : null

    return (
      <FormControl fullWidth={true} error={field.error ? true : false} className={classes.root}>
        <InputLabel variant='outlined' className={classes.label + labelActive}>{field.label}</InputLabel>
        <AsyncSelect
          styles={customStyles({
            error: field.error || selectedErrors ? true : false,
            dangerIfEmpty: field.dangerIfEmpty,
            isEmpty
          }, selectedValue && selectedValue.isColored)}
          defaultOptions
          placeholder={field.placeholder || false}
          isDisabled={fieldReadonly || field.disabled}
          loadOptions={_.debounce(this.promiseOptions, 500)}
          isClearable={field.isClearable ? true : false}
          value={selectedValue || ''}
          onChange={(value) => {
            this.setState({
              selectedValue: value,
              isEmpty: !value
            })

            return onChange({ type: 'select', fromState: false }, field, value)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          {...props} />
        {field.error || selectedErrorMessage ? (
          <FormHelperText className={classes.formHelperText}>{selectedErrorMessage || field.error}</FormHelperText>
        ) : ''}
      </FormControl>
    )
  }
}

AsyncSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AsyncSelectField)))
