import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'

import {} from '../../actions'

const styles = theme => ({
  steps: {
    display: 'flex',
    width: '100%',
    backgroundColor: blueGrey[100],
    color: theme.palette.common.white
  },
  step: {
    padding: '0 1rem',
    height: 50,
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: blueGrey[200],
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -10,
      border: '25px solid transparent',
      borderLeftColor: blueGrey[200],
      borderLeftWidth: 10,
      borderRight: 0,
      zIndex: 1,
    },
    '&::before': {
      border: '27px solid transparent',
      top: -2,
      borderLeftWidth: 12,
      right: -12,
      borderRight: 0,
      borderLeftColor: theme.palette.common.white
    }
  },
  stepActive: {
    backgroundColor: blueGrey[500],
    '&::after': {
      borderLeftColor: blueGrey[500],
    }
  }
})

class Address extends React.Component {
  render() {
    const { classes, field } = this.props

    return (
      <div className={classes.steps}>
        {field.steps.map((step, index) => {
          return (
            <div className={classes.step + (field.current >= index ? ' ' + classes.stepActive : '')} key={index}>{step}</div>
          )
        })}
      </div>
    )
  }
}

Address.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Address)))
