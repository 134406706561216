import store from '../stores'

import {
  appActions
} from '../actions'

const initialState = {
  resetPassword: {}
}

const authReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'auth_action_has_local_dbs':
      return {
        ...state,
        hasLocalDbs: action.data.hasLocalDbs
      }
    case 'auth_action_reset_password_reducer':
      return initialState
    case 'auth_action_update_password':
      return {
        resetPassword: action.data
      }
    case 'auth_action_local_dbs_progress':
      return {
        ...state,
        progress: action.progress
      }
    case 'auth_action_login_post':
      return {
        ...state,
        progress: {
          size: 0,
          progress: 0
        }
      }
    case 'auth_action_check_otp':
      return {
        resetPassword: {
          error: 'otpCode'
        }
      }
    case 'auth_action_login':
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', action.res.token)
      }

      setTimeout(() => {
        return store.dispatch(appActions.initApp())
      }, 100)

      return {
        ...state,
        progress: null
      }
    case 'auth_action_logout':
      localStorage.removeItem('token')

      setTimeout(() => {
        return store.dispatch(appActions.initApp())
      }, 100)

      return state
    case 'auth_action_password_recovery_post':
      return {
        ...state,
        goToLogin: false
      }
    case 'auth_action_password_recovery':
      return {
        ...state,
        goToLogin: true
      }
    default:
      return state
  }
}

export default authReducer
