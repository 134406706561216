import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flexGrow: '1',
  },
  group: {
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  labelRoot: {
    marginLeft: 0
  },
  label: {
    fontWeight: 400,
    fontSize: '.75rem',
    textTransform: 'uppercase'
  },
  checkbox: {
    padding: 0
  }
})

class Boolean extends React.Component {
  render() {
    const { onChange, fieldPath, classes, cell, isActive, readonly = false } = this.props

    const { labels, value, tableControl } = cell

    const disabled = !isActive ? !tableControl ? true : false : false

    return (
      <div className={classes.root}>
        <RadioGroup
          aria-label='Gender'
          name='gender1'
          className={classes.group}
          defaultValue={value ? '1' : '0'}
          onChange={(event) => {
            onChange(event.target.value === '1', fieldPath)
          }} >
          {['0', '1'].map((element, index) => {
            return (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.label
                }}
                key={index}
                value={element}
                control={
                  <Radio
                    disabled={readonly || disabled}
                    classes={{
                      root: classes.checkbox
                    }}
                    color='primary' />}
                label={labels[element === '1' ? 'true' : 'false']} />
            )
          })}
        </RadioGroup>
      </div>
    )
  }
}

Boolean.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Boolean)))
