import store from '../stores'

let timeout = null

export const messageActions = {
  showMessage: (obj) => {
    clearTimeout(timeout)

    if (obj.autoHide) {
      timeout = setTimeout(() => {
        store.dispatch(messageActions.closeMessage())
      }, 5000)
    }

    return {
      type: 'message_show_message',
      obj: obj
    }
  },
  closeMessage: () => {
    clearTimeout(timeout)

    return {
      type: 'message_close_message',
    }
  }
}
